import {AsyncPipe, TitleCasePipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, HostBinding, inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  Subscription,
  take,
  tap,
} from 'rxjs';
import {ListSearchParams} from 'src/app/core/model/list-search-params';
import {TitleService} from 'src/app/core/page/title.service';
import {MessageService} from 'src/app/services/message.service';
import {CreateFirstModule} from 'src/app/shared/design/create-first/create-first.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {OphPaginatorComponent} from 'src/app/shared/design/oph-paginator/oph-paginator.component';
import {OphTableColumn, OphTableComponent} from 'src/app/shared/design/oph-table/oph-table.component';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {Project} from '../core/model/project';
import {Token, TokenData} from '../core/model/token';
import {GetProjectsAction} from '../core/store/projects/projects.action';
import {selectProjectsMap} from '../core/store/projects/projects.selector';
import {DuplicateTokenAction, GetAllTokensAction} from '../core/store/tokens/tokens.action';
import {selectTokenData, selectTokensListParams} from '../core/store/tokens/tokens.selector';
import {TokensDialogComponent} from './dialog/tokens-dialog.component';
import {TokensGridItemComponent} from './grid-item/tokens-grid-item.component';
import {TokensDeleteConfirmationDialogComponent} from './shared/delete-confirmation-dialog/tokens-delete-confirmation-dialog.component';
import {TokenSortNamePipe} from './shared/token-sort-name.pipe';

@Component({
  selector: 'tokens',
  standalone: true,
  imports: [
    OphTableComponent,
    AsyncPipe,
    ListViewsModule,
    OphPaginatorComponent,
    MatMenuModule,
    MatIconModule,
    TitleCasePipe,
    TokenSortNamePipe,
    CreateFirstModule,
    OphLoadingModule,
    TokensGridItemComponent,
  ],
  templateUrl: './tokens.component.html',
  styleUrl: './tokens.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokensComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout oph-feature-padding';

  subscriptions = new Subscription();

  params$: Observable<ListSearchParams> = this.store$.pipe(select(selectTokensListParams));
  tokenData$: Observable<TokenData> = this.store$.pipe(select(selectTokenData));
  formattedTokens$: Observable<Token[]>;
  iconArray$: Observable<string[]> = this.observeTokenData();
  projectsMap$: Observable<Record<string, Project>> = this.store$.pipe(select(selectProjectsMap));

  loading$ = new BehaviorSubject<boolean>(true);
  loadingNewToken$ = new BehaviorSubject<boolean>(false);

  loadingParams: boolean = true;
  initiallyLoaded = false;
  readonly dialog = inject(MatDialog);
  menuOptions = ['edit', 'duplicate', 'delete'];

  columns: OphTableColumn[] = [
    {name: 'name', sortName: 'name', bold: true, width: '30%', type: 'name'},
    {
      name: 'expirationDate',
      displayName: 'due date',
      type: 'shortDate',
      sortName: 'expirationDate',
      width: '20%',
    },
    {name: 'type', type: 'tokenType', sortName: 'type', width: '20%'},
    {name: 'projects', type: 'projects', width: '20%'},
    {name: '', type: 'menu', width: '10%'},
  ];

  constructor(
    private store$: Store,
    private router: Router,
    private messageService: MessageService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.setPageTitle('Tokens');
    this.store$.dispatch(new GetProjectsAction({}));

    this.formattedTokens$ = this.observeTokens();

    this.subscriptions.add(this.subscribeToParams());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToParams(): Subscription {
    if (!this.initiallyLoaded) {
      this.loading$.next(true);
    }
    return this.params$
      .pipe(
        distinctUntilChanged(),
        // This is to prevent the create-first component from showing when there are no tokens
        tap(() => (this.loadingParams = true)),
        debounceTime(200)
      )
      .subscribe(params => {
        this.getTokens(params);
      });
  }

  observeTokenData(): Observable<string[]> {
    return this.tokenData$.pipe(
      map(data =>
        data.tokens.map(token => {
          return token.icon || 'trophy-gold';
        })
      )
    );
  }

  observeTokens(): Observable<Token[]> {
    return combineLatest([this.tokenData$, this.projectsMap$]).pipe(
      map(
        ([data, projectsMap]) => data?.tokens.map(token => ({...token, projects: [projectsMap[token.projectId]]})) || []
      )
    );
  }

  getTokens(params: ListSearchParams) {
    const formattedParams: ListSearchParams = {};
    Object.keys(params).forEach(key => {
      if (params[key]) {
        formattedParams[key] = params[key];
      }
    });
    formattedParams.pageSize = 50;
    formattedParams.sortOrder = 'asc';

    this.store$.dispatch(
      new GetAllTokensAction({
        params: formattedParams,
        onSuccess: () => this.getAllTokensSuccess(),
        onFailure: err => this.getAllTokensFailure(err),
      })
    );
  }

  getAllTokensSuccess() {
    this.loading$.next(false);
    this.loadingParams = false;
  }

  getAllTokensFailure(err: Error) {
    this.loading$.next(false);
    this.messageService.add(err.message || 'There was problem getting Tokens.');
  }

  onToken(index: number) {
    this.tokenData$.pipe(take(1)).subscribe(data => {
      const token = data.tokens[index];
      if (token) {
        this.router.navigate(['tokens', token._id, {returnTo: this.router.url}]);
      }
    });
  }

  openTokenDialog(token: Token) {
    const dialogRef = this.dialog.open(TokensDialogComponent, {
      maxHeight: '735px',
      height: '100%',
      maxWidth: '720px',
      width: '100%',
      data: {token},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.params$.pipe(take(1)).subscribe(params => this.getTokens(params));
      }
    });
  }

  onCreateTokenSuccess(token: Token) {
    if (token?._id) {
      this.router.navigate(['tokens', token._id, {returnTo: this.router.url}]);
    }
    this.loadingNewToken$.next(false);
  }

  onCreateTokenFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem creating the token');
  }

  onSort(sortField: string) {
    this.router.navigate([], {queryParams: {sortField}, queryParamsHandling: 'merge'});
  }

  onMenuAction(data: {action: string; rowIndex: number}) {
    this.tokenData$.pipe(take(1)).subscribe(tokenData => {
      if (data.action === 'edit') {
        this.onEditToken(data.rowIndex);
      }

      if (data.action === 'duplicate') {
        this.store$.dispatch(
          new DuplicateTokenAction({
            tokenId: tokenData.tokens[data.rowIndex]._id,
            onSuccess: () => this.onSuccess(),
            onFailure: err => this.onFailure(err),
          })
        );
      }

      if (data.action === 'delete') {
        this.openDeleteDialog(tokenData.tokens[data.rowIndex]);
      }
    });
  }

  openDeleteDialog(token: Token) {
    const dialogRef = this.dialog.open(TokensDeleteConfirmationDialogComponent, {
      data: {
        token,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.params$.pipe(take(1)).subscribe(params => this.getTokens(params));
      }
    });
  }

  onSuccess() {
    this.params$.pipe(take(1)).subscribe(params => this.getTokens(params));
  }

  onFailure(err: HttpErrorResponse) {
    this.messageService.add(err.message || 'There was a problem with the token');
  }

  onEditToken(rowIndex: number) {
    this.tokenData$.pipe(take(1)).subscribe(data => {
      this.openTokenDialog(data.tokens[rowIndex] || null);
    });
  }
}
