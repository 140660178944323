import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function completionCountValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const typeControl = control.parent?.get('type');

    // If type is not 'routineTask' and completionCount is less than or equal to 0
    if (typeControl && typeControl.value === 'routineTask' && (control.value <= 0 || isNaN(control.value))) {
      return {completionCountInvalid: true};
    }

    return null;
  };
}

export function trackerRequiredIfNotRoutineTask(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const typeControl = control.parent?.get('type');

    if (typeControl && typeControl.value !== 'routineTask') {
      const trackerGroup = control as any;
      // Check if any field within the tracker group is empty or invalid
      const isTrackerValid =
        trackerGroup.get('trackerId')?.value &&
        trackerGroup.get('metricId')?.value &&
        trackerGroup.get('operator')?.value &&
        trackerGroup.get('value')?.value;

      if (!isTrackerValid) {
        return {trackerRequired: true};
      }
    }

    return null;
  };
}
