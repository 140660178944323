<button class="token" (click)="tokenClick.emit()">
  <div class="row">
    <div class="item name">
      <label>NAME</label>
      <span class="bold">{{ token.name }}</span>
    </div>

    <div class="item projects">
      <label>PROJECTS</label>
      <list-views-project-display [projects]="[project]"></list-views-project-display>
    </div>
  </div>

  <div class="row">
    <div class="item on">
      <label>DUE DATE</label>
      <span
        >{{ token.expirationDate | convertUtcToTimezone: 'MMM DD YYYY' }}&nbsp;&nbsp;&nbsp;
        {{ token.expirationDate | convertUtcToTimezone: 'HH:mm' }}</span
      >
    </div>

    <div class="item by">
      <label>TYPE</label>
      <span>{{ token.type }}</span>
    </div>
  </div>
</button>
