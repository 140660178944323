import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';

export interface OphSelectMenuOption {
  name: string;
  value: string;
}

@Component({
  selector: 'oph-select-menu',
  standalone: true,
  imports: [MatMenuModule, CommonModule, MatIconModule],
  templateUrl: './oph-select-menu.component.html',
  styleUrl: './oph-select-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphSelectMenuComponent {
  @Input() buttonStyle: Object;
  @Input() buttonText: string;
  @Input() options: OphSelectMenuOption[];

  @Output() optionClick = new EventEmitter<string>();
}
