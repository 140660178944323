<div
  class="container"
  [class.focus]="inputFocusClass"
  [class.invalid]="invalid"
  [style.width]="width + 'px' || '100%'"
  [style.paddingRight.px]="paddingRight"
  [style.border]="'1px solid' + borderColorHex"
  [style.backgroundColor]="backgroundColorHex"
>
  <label [style.color]="labelColorHex">{{ label }}</label>
  <input
    #input
    [formControl]="internalControl"
    (input)="onInput($event.target.value)"
    (focus)="onFocus()"
    (blur)="inputFocusClass = false"
    [type]="inputType"
    [style.color]="valueColorHex"
    [style.fontSize.px]="fontSize || 16"
    [readOnly]="disabled"
    [ngStyle]="inputStyle"
  />
</div>
