import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {TokensApiService} from '../../api/tokens-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateNewTokenAction,
  CreateNewTokenSuccessAction,
  DeleteTokenAction,
  DuplicateTokenAction,
  DuplicateTokenSuccessAction,
  GetAllTokensAction,
  GetAllTokensSuccessAction,
  GetSingleTokenAction,
  GetSingleTokenSuccessAction,
  TokensActionType,
  UpdateTokenAction,
  UpdateTokenSuccessAction,
} from './tokens.action';

@Injectable()
export class TokensEffects {
  public getAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllTokensAction>(TokensActionType.GET_ALL),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;

        return this.tokensApiService.getAll(params).pipe(
          mergeMap(tokenData => [
            new GetAllTokensSuccessAction({tokenData}),
            ...createCallbackActions(onSuccess, tokenData),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleTokenAction>(TokensActionType.GET_SINGLE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.tokensApiService.getSingle(id).pipe(
          mergeMap(token => [new GetSingleTokenSuccessAction({token}), ...createCallbackActions(onSuccess, token)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createNew$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewTokenAction>(TokensActionType.CREATE_NEW),
      mergeMap(action => {
        const {token, onSuccess, onFailure} = action.payload;

        return this.tokensApiService.createNew(token).pipe(
          mergeMap(token => [new CreateNewTokenSuccessAction({token}), ...createCallbackActions(onSuccess, token)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTokenAction>(TokensActionType.UPDATE),
      mergeMap(action => {
        const {token, onSuccess, onFailure} = action.payload;

        return this.tokensApiService.update(token).pipe(
          mergeMap(updatedToken => [
            new UpdateTokenSuccessAction({token: updatedToken}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public duplicate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateTokenAction>(TokensActionType.DUPLICATE),
      mergeMap(action => {
        const {tokenId, onSuccess, onFailure} = action.payload;

        return this.tokensApiService.duplicate(tokenId).pipe(
          mergeMap(token => [new DuplicateTokenSuccessAction({token}), ...createCallbackActions(onSuccess, token)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTokenAction>(TokensActionType.DELETE),
      mergeMap(action => {
        const {tokenId, onSuccess, onFailure} = action.payload;

        return this.tokensApiService.delete(tokenId).pipe(
          mergeMap(() => [...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private tokensApiService: TokensApiService
  ) {}
}
