<button class="project-selector-button" [matMenuTriggerFor]="projectSelectorMenu" [ngStyle]="buttonStyle">
  <div class="project-color" [style.background-color]="selectedProject?.color || '#E5E3DC'"></div>
  <span class="project-name">{{ selectedProject?.label || 'Select a project' }}</span>
  <mat-icon svgIcon="chevron-down"></mat-icon>
</button>
<mat-menu #projectSelectorMenu="matMenu" class="oph-select-menu">
  <ng-content select=".project-selector"> </ng-content>
</mat-menu>

@if (!hideActionButton) {
  <oph-button
    [matMenuTriggerFor]="projecActiontMenu"
    appearance="solid"
    [color]="buttonColor"
    [colorHex]="colorHex"
    [style]="{width: '125px', justifyContent: 'space-between', color: 'white', height: '30px', borderRadius: '6px'}"
    [loading]="loading"
  >
    {{ buttonDisplay || 'Draft' }}
    <img src="assets/img/icons/chevron-down-white.svg" />
  </oph-button>
  <mat-menu #projecActiontMenu="matMenu" class="oph-select-menu">
    <ng-content select=".project-menu"> </ng-content>
  </mat-menu>
}
