import {Token, TokenData} from '../../model/token';

export interface TokensState {
  tokenData: TokenData;
  token: Token;
}

export const initialTokensState: TokensState = {
  tokenData: null,
  token: null,
};
