import {Token} from 'src/app/core/model/token';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OphDeleteDialogContentComponent} from 'src/app/shared/dialog/oph-delete-dialog-content/oph-delete-dialog-content.component';
import {Store} from '@ngrx/store';
import {MessageService} from 'src/app/services/message.service';
import {DeleteTokenAction} from 'src/app/core/store/tokens/tokens.action';
import {HttpErrorResponse} from '@angular/common/http';

interface DialogData {
  token: Token;
  getTokens: () => void;
}

@Component({
  selector: 'tokens-delete-confirmation-dialog',
  standalone: true,
  imports: [OphDeleteDialogContentComponent],
  templateUrl: './tokens-delete-confirmation-dialog.component.html',
  styleUrl: './tokens-delete-confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokensDeleteConfirmationDialogComponent {
  readonly dialogRef = inject(MatDialogRef<TokensDeleteConfirmationDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  token = this.data.token;
  message = 'Are you sure you want to delete this token? This action cannot be undone.';
  loading = false;

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  onClose(deleteToken: boolean) {
    if (deleteToken) {
      this.deleteToken();
    } else {
      this.dialogRef.close();
    }
  }

  deleteToken() {
    this.loading = true;
    this.store$.dispatch(
      new DeleteTokenAction({
        tokenId: this.token._id,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onSuccess() {
    this.dialogRef.close(true);
  }

  onFailure(err: HttpErrorResponse) {
    this.messageService.add(err.error || 'There was a problem deleting the reward.');
  }
}
