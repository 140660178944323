@if (
  {
    params: params$ | async,
    tokenData: tokenData$ | async,
    loading: loading$ | async,
    projectsMap: projectsMap$ | async
  };
  as state
) {
  <list-view-header
    name="Tokens"
    icon="token"
    iconColor="#F57B4E"
    createButtonText="New Token"
    [searchValue]="state.params?.search || ''"
    [loadingCreateNew]="loadingNewToken$ | async"
    [hideSearch]="state.loading"
    (viewChange)="onViewChange($event)"
    (search)="onSearch($event)"
    (createNew)="openTokenDialog(null)"
  ></list-view-header>

  @if (state.loading) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    @if (!state.tokenData?.tokens?.length && !state.params.search && !loadingParams) {
      <create-first type="token" (buttonClick)="openTokenDialog(null)"></create-first>
    } @else {
      <oph-table
        [columns]="columns"
        [data]="(formattedTokens$ | async) || []"
        [iconArray]="iconArray$ | async"
        [menuOptions]="menuOptions"
        defaultSortField="name"
        (menuAction)="onMenuAction($event)"
        (rowClick)="onEditToken($event)"
      ></oph-table>

      <div class="grid-sort">
        <label>Sort by</label>
        <button [matMenuTriggerFor]="menu">
          {{ (state.params?.sortField | tokenSortName) || 'name' | titlecase }}
          <mat-icon svgIcon="chevron-down"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="oph-select-menu">
          @for (column of columns; track column) {
            @if (column.sortName) {
              <button class="oph-select-menu-item" (click)="onSort(column.sortName)">
                {{ column.name | titlecase }}
              </button>
            }
          }
        </mat-menu>
      </div>

      @if (state.tokenData?.tokens?.length) {
        <div class="mobile-list">
          @for (token of state.tokenData?.tokens; track token; let i = $index) {
            <tokens-grid-item
              [token]="token"
              [project]="state.projectsMap[token.projectId] || null"
              (tokenClick)="onEditToken(i)"
            ></tokens-grid-item>
          }
        </div>
      }

      @if (state.tokenData?.pagination?.totalPages > 1) {
        <oph-paginator
          [page]="state.tokenData?.pagination?.page"
          [totalPages]="state.tokenData?.pagination?.totalPages"
        ></oph-paginator>
      }
    }
  }
}
