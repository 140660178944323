import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {ProjectsApiService} from '../../api/projects-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  AddProjectTaskAction,
  CreateProjectAction,
  CreateProjectStepAction,
  CreateProjectSuccessAction,
  CreateTaskTemplateAction,
  DeleteProjectAction,
  DeleteProjectStepAction,
  DeleteProjectSuccessAction,
  DeleteProjectTaskAction,
  DeleteTaskTemplateAction,
  DuplicateProjectAction,
  DuplicateProjectSuccessAction,
  GetProjectsAction,
  GetProjectsSuccessAction,
  GetProjectViewAction,
  GetProjectViewRoutineDetailAction,
  GetProjectViewRoutineDetailSuccessAction,
  GetProjectViewSuccessAction,
  GetTaskTemplatesAction,
  GetTaskTemplatesSuccessAction,
  MoveProjectTaskAction,
  PatchProjectAction,
  ProjectsActionType,
  PutProjectAction,
  UpdateProjectStepAction,
  UpdateProjectSuccessAction,
  UpdateProjectTaskAction,
  UpdateTaskTemplateAction,
} from './projects.action';

@Injectable()
export class ProjectsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectsAction>(ProjectsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.projectsApiService.get().pipe(
          mergeMap(projects => [new GetProjectsSuccessAction({projects}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProjectAction>(ProjectsActionType.CREATE),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.create(project).pipe(
          mergeMap(project => [
            new CreateProjectSuccessAction({project}),
            ...createCallbackActions(onSuccess, project),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public put$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PutProjectAction>(ProjectsActionType.PUT),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.put(project).pipe(
          mergeMap(p => [new UpdateProjectSuccessAction({project: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public patch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PatchProjectAction>(ProjectsActionType.PATCH),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.patch(project).pipe(
          mergeMap(p => [new UpdateProjectSuccessAction({project: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProjectStepAction>(ProjectsActionType.CREATE_STEP),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.createStep(project).pipe(
          mergeMap(p => [new UpdateProjectSuccessAction({project: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProjectStepAction>(ProjectsActionType.UPDATE_STEP),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.updateStep(project).pipe(
          mergeMap(p => [new UpdateProjectSuccessAction({project: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public deleteStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProjectStepAction>(ProjectsActionType.DELETE_STEP),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.createStep(project).pipe(
          mergeMap(p => [new UpdateProjectSuccessAction({project: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  duplicate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateProjectAction>(ProjectsActionType.DUPLICATE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.duplicate(id).pipe(
          mergeMap(project => [
            new DuplicateProjectSuccessAction({project}),
            ...createCallbackActions(onSuccess, project),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProjectAction>(ProjectsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.delete(id).pipe(
          mergeMap(() => [new DeleteProjectSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public addProjectTask$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AddProjectTaskAction>(ProjectsActionType.ADD_PROJECT_TASK),
      mergeMap(action => {
        const {projectId, stepId, taskIndex, task, onSuccess, onFailure} = action.payload;
        return this.projectsApiService.addOrMoveProjectTask(projectId, stepId, taskIndex, task, true).pipe(
          mergeMap(project => [new UpdateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateProjectTask$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProjectTaskAction>(ProjectsActionType.UPDATE_PROJECT_TASK),
      mergeMap(action => {
        const {projectId, stepId, task, onSuccess, onFailure} = action.payload;
        return this.projectsApiService.updateProjectTask(projectId, stepId, task).pipe(
          mergeMap(project => [new UpdateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public moveProjectTask$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<MoveProjectTaskAction>(ProjectsActionType.MOVE_PROJECT_TASK),
      mergeMap(action => {
        const {projectId, stepId, taskIndex, task, onSuccess, onFailure} = action.payload;
        return this.projectsApiService.addOrMoveProjectTask(projectId, stepId, taskIndex, task, false).pipe(
          mergeMap(project => [new UpdateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public deleteProjectTask$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProjectTaskAction>(ProjectsActionType.DELETE_PROJECT_TASK),
      mergeMap(action => {
        const {projectId, taskInstanceId, onSuccess, onFailure} = action.payload;
        return this.projectsApiService.deleteProjectTask(projectId, taskInstanceId).pipe(
          mergeMap(project => [new UpdateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getProjectView$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectViewAction>(ProjectsActionType.GET_PROJECT_VIEW),
      mergeMap(action => {
        const {projectId, params, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.getProjectView(projectId, params).pipe(
          mergeMap(projectView => [
            new GetProjectViewSuccessAction({projectView}),
            ...createCallbackActions(onSuccess, projectView),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getRoutineDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectViewRoutineDetailAction>(ProjectsActionType.GET_ROUTINE_DETAIL),
      mergeMap(action => {
        const {taskInstanceId, params, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.getRoutineDetail(taskInstanceId, params).pipe(
          mergeMap(routineDetail => [
            new GetProjectViewRoutineDetailSuccessAction({routineDetail, taskInstanceId}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getTaskTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTaskTemplatesAction>(ProjectsActionType.GET_TASK_TEMPLATES),
      mergeMap(action => {
        const {search, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.getTaskTemplates(search).pipe(
          mergeMap(taskTemplates => [
            new GetTaskTemplatesSuccessAction({taskTemplates}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTaskTemplateAction>(ProjectsActionType.CREATE_TASK_TEMPLATE),
      mergeMap(action => {
        const {task, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.createTaskTemplate(task).pipe(
          mergeMap(task => [...createCallbackActions(onSuccess, task)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  updateTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTaskTemplateAction>(ProjectsActionType.UPDATE_TASK_TEMPLATE),
      mergeMap(action => {
        return this.projectsApiService
          .updateTaskTemplate(action.payload.task)
          .pipe(mergeMap(() => [...createCallbackActions(action.payload.onSuccess)]));
      })
    )
  );

  deleteTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTaskTemplateAction>(ProjectsActionType.DELETE_TASK_TEMPLATE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.deleteTaskTemplate(id).pipe(
          mergeMap(() => [...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private projectsApiService: ProjectsApiService
  ) {}
}
