<div class="left-container">
  <h3>{{ text }}</h3>
  @if (color) {
    <div [class]="'color-bar ' + color"></div>
  }
</div>

@if (addNewButton) {
  <oph-button
    color="gray"
    appearance="outline"
    size="small"
    [style]="buttonStyle"
    [loading]="addNewButtonLoading"
    (buttonClick)="addNewButtonClick.emit()"
    >Add new</oph-button
  >
}

@if (taskCount) {
  <div class="task-count">{{ taskCount }} {{ taskCount === 1 ? 'Task' : 'Tasks' }}</div>
}

@if (tokenFilters) {
  <div class="token-filters">
    <oph-select-menu
      [buttonStyle]="{height: '29px', width: '200px'}"
      [buttonText]="timeFilterDisplay$ | async"
      [options]="tokenTimeFilterOptions"
      (optionClick)="onTokenTimeFilterChange($event)"
    ></oph-select-menu>
    <!-- <button class="filter-dialog-button" (click)="openFilterDialog()">
      <span class="text">dialog</span>
      <mat-icon svgIcon="chevron-down"></mat-icon>
    </button> -->
  </div>
}
