<button (click)="buttonClick.emit(!selected)" [style.width]="buttonWidth || '294px'">
  <div class="left-container">
    @if (type === 'teams') {
      <div class="avatar">{{ display | userAvatar | uppercase }}</div>
    }
    @if (type === 'people') {
      <oph-avatar [user]="user" [size]="30"></oph-avatar>
    }
    <span>{{ display }}</span>
  </div>
  @if (!selected) {
    <img src="assets/img/icons/plus-orange.svg" />
  }
  @if (selected) {
    <img src="assets/img/icons/check-green.svg" />
  }
</button>
