import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {OphIconModule} from '../oph-icon/oph-icon.module';
import {CommonModule} from '@angular/common';
import {timer} from 'rxjs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';

export interface OphAutocompleteOption {
  name: string;
  _id: string;
  color?: string;
  index?: number;
  disabled?: boolean;
}

@Component({
  selector: 'oph-autocomplete',
  standalone: true,
  imports: [MatAutocompleteModule, OphIconModule, CommonModule, MatProgressSpinnerModule, MatTooltipModule],
  templateUrl: './oph-autocomplete.component.html',
  styleUrl: './oph-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphAutocompleteComponent implements OnChanges, AfterViewInit {
  @Input() options: OphAutocompleteOption[];
  @Input() preventSelectedOption: boolean; // prevents orange checkmark on the item
  @Input() placeholder: string;
  @Input() style: Object;
  @Input() inputDisabled: boolean;
  @Input() initialValue: string;
  @Input() autoFocus: boolean;
  @Input() showNewButton: boolean;
  @Input() newButtonLoading: boolean;
  @Input() noOptionsText: string;
  // Icons must be in src/assets/img/icons
  @Input() optionIcon: string;

  @Output() optionChange = new EventEmitter<OphAutocompleteOption>();
  @Output() inputBlur = new EventEmitter();
  @Output() newClick = new EventEmitter();

  @ViewChild('input') input: ElementRef;

  filteredOptions: OphAutocompleteOption[];
  menuOpen: boolean = false;
  value: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.options) {
      this.filteredOptions = this.options;
    }

    if (changes.initialValue || this.initialValue) {
      this.value = this.initialValue;
    }
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.input.nativeElement.focus();
    }
  }

  onInput(value: string) {
    this.value = value;
    if (value === '') {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  onOption(option: OphAutocompleteOption, index: number) {
    this.optionChange.emit({...option, index});
    this.input.nativeElement.value = '';
    this.filteredOptions = this.options;
    this.input.nativeElement.blur();
  }

  onBlur() {
    timer(250).subscribe(() => this.inputBlur.emit());
  }
}
