import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'oph-add-button',
  standalone: true,
  imports: [],
  templateUrl: './oph-add-button.component.html',
  styleUrl: './oph-add-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphAddButtonComponent {
  @Input() buttonStyle: Object;

  @Output() buttonClick = new EventEmitter();
}
