import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Project} from 'src/app/core/model/project';
import {ProjectTask} from 'src/app/core/model/task';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  // ---------------------------------------------------------------------------
  // Methods were copy/pasted from project-builder.component.ts (now deleted) to be used globally
  // Originally authored by Stanislav Kromaschenkov and Ian Sear
  private subject = new Subject<void>();

  getProjectPartial(project: Project): Partial<Project> {
    return {
      startDateTime: project.startDateTime,
      endDateTime: project.endDateTime,
      status: project.status,
      private: project.private,
    };
  }

  getProjectFields(project: Project): Partial<Project> {
    return {
      endDateTime: project.endDateTime,
      private: project.private,
      startDateTime: project.startDateTime,
      status: project.status,
    };
  }

  // --------------------------------------------------------------------------

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<unknown> {
    return this.subject.asObservable();
  }

  getAllTasksInProject(project: Project): ProjectTask[] {
    return project.steps?.reduce((tasks, step) => {
      if (step.tasks) {
        tasks.push(...step.tasks);
      }
      return tasks;
    }, []);
  }
}
