import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphSelectMenuComponent} from 'src/app/shared/design/oph-select-menu/oph-select-menu.component';
import {ProjectsViewTokenFilterDialogComponent} from '../token-filter-dialog/projects-view-token-filter-dialog.component';
import {select, Store} from '@ngrx/store';
import {UpdateProjectViewTokenParamsAction} from 'src/app/core/store/projects/projects.action';
import {ProjectViewTokenFilterParams} from 'src/app/core/model/project';
import {selectProjectViewTokenFilterParams} from 'src/app/core/store/projects/projects.selector';
import {map, Observable, take} from 'rxjs';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'project-view-item-header',
  standalone: true,
  imports: [OphButtonModule, OphSelectMenuComponent, MatIconModule, AsyncPipe],
  templateUrl: './project-view-item-header.component.html',
  styleUrl: './project-view-item-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewItemHeaderComponent implements OnInit {
  @Input() text: string;
  @Input() color: string;
  @Input() addNewButton: boolean;
  @Input() addNewButtonLoading: boolean;
  @Input() taskCount: number;
  @Input() tokenFilters: boolean;

  @Output() addNewButtonClick = new EventEmitter();

  tokenParams$: Observable<ProjectViewTokenFilterParams> = this.store$.pipe(select(selectProjectViewTokenFilterParams));
  timeFilterDisplay$: Observable<string>;
  typeFilterDisplay$: Observable<string>;

  constructor(private store$: Store) {}

  ngOnInit() {
    this.timeFilterDisplay$ = this.tokenParams$.pipe(
      map(params => {
        return this.tokenTimeFilterOptions.find(option => option.value === params.time)?.name;
      })
    );
  }

  buttonStyle = {
    borderRadius: '6px',
    height: '29px',
    width: '74px',
    minWidth: '74px',
    fontSize: '14px',
    background: 'var(--light)',
    border: '1px solid var(--border) !important',
  };

  tokenTimeFilterOptions = [
    {name: 'Past one week', value: '1'},
    {name: 'Past two weeks', value: '2'},
    {name: 'Past one month', value: '4'},
    {name: 'All', value: '1000'},
  ];

  readonly dialog = inject(MatDialog);

  onTokenTimeFilterChange(value: string) {
    this.tokenParams$.pipe(take(1)).subscribe(params => {
      this.store$.dispatch(new UpdateProjectViewTokenParamsAction({params: {...params, time: value}}));
    });
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(ProjectsViewTokenFilterDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tokenParams$.pipe(take(1)).subscribe(params => {
          this.store$.dispatch(new UpdateProjectViewTokenParamsAction({params: {...params, type: result}}));
        });
      }
    });
  }
}
