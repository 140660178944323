import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tokenSortName',
  standalone: true,
})
export class TokenSortNamePipe implements PipeTransform {
  transform(name): unknown {
    if (name === 'lastUpdatedOn') {
      return 'Updated On';
    }
    if (name === 'lastUpdatedBy') {
      return 'Updated By';
    }
    return name;
  }
}
