import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

export interface OphButtonGroupOption {
  name: string;
  display?: string;
  disabled?: boolean;
}

@Component({
  selector: 'oph-button-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './oph-button-group.component.html',
  styleUrl: './oph-button-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphButtonGroupComponent {
  @Input() options: OphButtonGroupOption[];
  @Input() selectedOptionName: string;
  @Input() style: Object;
  @Input() buttonStyle: Object;
  @Input() disabled: boolean;

  @Output() optionClick = new EventEmitter<string>();
}
