import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, filter, map, Observable, Subscription, take, timer} from 'rxjs';
import {Project, ProjectView, ProjectViewTokenFilterParams, SelectedRoutine} from '../core/model/project';
import {RewardCoin} from '../core/model/reward';
import {TaskTemplate} from '../core/model/task';
import {User} from '../core/model/user';
import {TitleService} from '../core/page/title.service';
import {GetLocationsAction} from '../core/store/locations/locations.action';
import {GetProgramsAction} from '../core/store/programs/programs.action';
import {selectAllPrograms} from '../core/store/programs/programs.selector';
import {
  DeleteProjectAction,
  DuplicateProjectAction,
  GetProjectsAction,
  GetProjectViewAction,
  GetTaskTemplatesAction,
  PatchProjectAction,
  UpdateProjectViewTaskId,
} from '../core/store/projects/projects.action';
import {
  selectAllProjects,
  selectProjectsMap,
  selectProjectViews,
  selectProjectViewTokenFilterParams,
  selectTaskTemplates,
} from '../core/store/projects/projects.selector';
import {GetCoinTypesAction} from '../core/store/rewards/rewards.action';
import {selectCoinTypes} from '../core/store/rewards/rewards.selector';
import {selectRouterParam} from '../core/store/router/router.selector';
import {GetAutoSkeds} from '../core/store/schedule/schedule.action';
import {selectScheduleAutoSkeds} from '../core/store/schedule/schedule.selector';
import {FetchSkillsAction} from '../core/store/skills/skills.action';
import {GetTeamsAction} from '../core/store/teams/teams.action';
import {GetAllUsersAction} from '../core/store/users/users.action';
import {selectUsersMap} from '../core/store/users/users.selector';
import {Program} from '../programs/shared/model/program';
import {AutoSked} from '../schedule/shared/model/autosked';
import {MessageService} from '../services/message.service';
import {TimezoneService} from '../services/timezone.service';
import {CreateFirstModule} from '../shared/design/create-first/create-first.module';
import {
  OphButtonGroupComponent,
  OphButtonGroupOption,
} from '../shared/design/oph-button-group/oph-button-group.component';
import {OphButtonModule} from '../shared/design/oph-button/oph-button.module';
import {OphLoadingModule} from '../shared/design/oph-loading/oph-loading.module';
import {ListViewWarningDialogComponent} from '../shared/list-views/shared/warning-dialog/list-view-warning-dialog.component';
import {PipesModule} from '../shared/pipes/pipes.module';
import {CreateProjectAction} from './../core/store/projects/projects.action';
import {ProjectWizardDialogComponent} from './dialogs/project-wizard-dialog/project-wizard-dialog.component';
import {ProjectsSkedSelectorDialogComponent} from './dialogs/projects-sked-selector-dialog/projects-sked-selector-dialog.component';
import {ProjectEditComponent} from './edit/project-edit.component';
import {ProjectSelectorMenuActionButtonComponent} from './project-selector/action-button/project-selector-menu-action-button.component';
import {ProjectSelectorMenuProjectButtonComponent} from './project-selector/project-button/project-selector-menu-project-button.component';
import {ProjectSelectorComponent} from './project-selector/project-selector.component';
import {
  EMPTY_PROJECT,
  PROJECT_ACTION_OPTIONS,
  PROJECT_WIZARD_DIALOG_CONFIG,
  PROJECTS_ADD_SKED_DIALOG_CONFIG,
} from './shared/projects-constants';
import {ProjectsSkedSelectorComponent} from './sked-selector/projects-sked-selector.component';
import {ProjectViewComponent} from './view/project-view.component';
import {GetAllTrackersAction} from '../core/store/trackers/trackers.action';

@Component({
  selector: 'projects',
  standalone: true,
  imports: [
    ProjectSelectorComponent,
    AsyncPipe,
    OphLoadingModule,
    CreateFirstModule,
    OphButtonGroupComponent,
    OphButtonModule,
    ProjectSelectorMenuProjectButtonComponent,
    ProjectSelectorMenuActionButtonComponent,
    ProjectsSkedSelectorComponent,
    MatIconModule,
    ProjectViewComponent,
    ProjectEditComponent,
    PipesModule,
  ],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout';

  subscriptions = new Subscription();

  programs$: Observable<Program[]> = this.store$.pipe(select(selectAllPrograms));
  projects$: Observable<Project[]> = this.store$.pipe(select(selectAllProjects));
  projectsMap$: Observable<Record<string, Project>> = this.store$.pipe(select(selectProjectsMap));
  projectViews$: Observable<Record<string, ProjectView>> = this.store$.pipe(select(selectProjectViews));
  view$: Observable<string> = this.store$.pipe(
    select(selectRouterParam('view')),
    map(view => view ?? 'view')
  );
  autoSkeds$: Observable<AutoSked[]> = this.store$.select(selectScheduleAutoSkeds);
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  taskTemplates$: Observable<TaskTemplate[]> = this.store$.pipe(select(selectTaskTemplates));
  coinTypes$: Observable<RewardCoin[]> = this.store$.pipe(select(selectCoinTypes));
  tokenParams$: Observable<ProjectViewTokenFilterParams> = this.store$.pipe(select(selectProjectViewTokenFilterParams));

  loading$ = new BehaviorSubject<Record<string, boolean>>({});
  selectedProject$ = new BehaviorSubject<Project>(null);
  projectView$ = new BehaviorSubject<ProjectView>(null);
  selectedRoutine$ = new BehaviorSubject<SelectedRoutine>(null);
  scrollPositions$ = new BehaviorSubject<Record<string, number>>({});

  projectSkeds$: Observable<string[]> = this.observeSelectedProjectAndSkeds();

  projectActionOptions = PROJECT_ACTION_OPTIONS;
  viewOptions: OphButtonGroupOption[] = [{name: 'view'}, {name: 'build'}];
  readonly dialog = inject(MatDialog);
  isProjectActive: boolean;

  constructor(
    private store$: Store,
    private titleService: TitleService,
    private messageService: MessageService,
    private router: Router,
    private elRef: ElementRef,
    private timezoneService: TimezoneService
  ) {}

  ngOnInit() {
    this.titleService.setPageTitle('Projects');

    this.store$.dispatch(new GetProgramsAction({}));
    this.store$.dispatch(new GetProjectsAction({}));
    this.store$.dispatch(new GetAutoSkeds({}));
    this.store$.dispatch(new GetAllUsersAction({}));
    this.store$.dispatch(new GetTaskTemplatesAction({}));
    this.store$.dispatch(new FetchSkillsAction({}));
    this.store$.dispatch(new GetLocationsAction({}));
    this.store$.dispatch(new GetTeamsAction({}));
    this.store$.dispatch(new GetCoinTypesAction({}));
    // This should change from 1000 to all or something
    this.store$.dispatch(new GetAllTrackersAction({params: {pageSize: 1000}}));

    this.subscriptions.add(this.subscribeToProjectsMap());
    this.subscriptions.add(this.subscribeToSelectedProjectAndTokenParams());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToProjectsMap(): Subscription {
    return this.projectsMap$.pipe(filter(p => !!Object.keys(p)?.length)).subscribe(projectsMap => {
      const storedProjectId = sessionStorage.getItem('ophStoredProjectId');
      if (storedProjectId && projectsMap[storedProjectId]) {
        this.selectedProject$.next(projectsMap[storedProjectId]);
      } else {
        this.selectProject(Object.values(projectsMap)[0]);
      }
    });
  }

  subscribeToSelectedProjectAndTokenParams(): Subscription {
    return combineLatest([this.selectedProject$, this.projectViews$, this.tokenParams$]).subscribe(
      ([project, projectViews, tokenParams]) => {
        if (!project || !projectViews) {
          return;
        }

        if (projectViews[project._id]) {
          this.projectView$.next(projectViews[project._id]);
        } else {
          this.loading$.next({...this.loading$.value, projectView: true});
        }

        const params = {};
        for (const key in tokenParams) {
          if (tokenParams[key]) {
            params[key] = tokenParams[key];
          }
        }
        this.isProjectActive = this.timezoneService.areDatesWithinRange(project.startDateTime, project.endDateTime);
        this.getProjectView(project._id, params);
      }
    );
  }

  getProjectView(projectId: string, params: Record<string, string>) {
    this.store$.dispatch(
      new GetProjectViewAction({
        projectId,
        params,
        onSuccess: projectView => this.onGetProjectViewSuccess(projectView),
        onFailure: err => this.onGetProjectViewFailure(err),
      })
    );
  }

  onGetProjectViewSuccess(projectView: ProjectView) {
    this.loading$.next({...this.loading$.value, projectView: false});
    this.projectView$.next(projectView);
  }

  onGetProjectViewFailure(err: Error) {
    this.loading$.next({...this.loading$.value, projectView: false});
    this.messageService.add(err.message || 'Error loading project view');
  }

  observeSelectedProjectAndSkeds(): Observable<string[]> {
    return combineLatest([this.selectedProject$, this.autoSkeds$]).pipe(
      map(([project, autoSkeds]) => {
        if (!project || !autoSkeds) {
          return [];
        }
        const projectSkeds = [];
        autoSkeds.filter(sked => {
          if (sked.projectIds?.length && sked.projectIds.includes(project._id)) {
            projectSkeds.push(sked.label);
          }
        });
        return projectSkeds;
      })
    );
  }

  onViewChange(view: string) {
    this.router.navigate(['/projects', {view}]);
  }

  selectProject(project: Project) {
    this.selectedProject$.next(project);
    sessionStorage.setItem('ophStoredProjectId', project._id);
    this.selectedRoutine$.next(null);
    this.store$.dispatch(new UpdateProjectViewTaskId({id: null}));
  }

  onProjectAction(action: string) {
    if (action === 'edit') {
      this.onOpenProjectDialog(this.selectedProject$.value);
    }
    if (action === 'duplicate') {
      this.duplicate();
    }
    if (action === 'delete') {
      this.openDeleteConfirmation();
    }
  }

  onOpenProjectDialog(currentProject?: Project) {
    const project = currentProject || {...EMPTY_PROJECT};
    const dialog = this.dialog.open(ProjectWizardDialogComponent, {
      ...PROJECT_WIZARD_DIALOG_CONFIG,
      data: {project, programs$: this.programs$},
    });
    dialog.afterClosed().subscribe(projectData => {
      if (projectData) {
        if (projectData._id) {
          this.store$.dispatch(new PatchProjectAction({project: projectData}));
          return;
        }
        this.store$.dispatch(
          new CreateProjectAction({project: projectData, onSuccess: project => this.onActionSuccess(project)})
        );
      }
    });
  }

  duplicate() {
    this.loading$.next({...this.loading$.value, projectAction: true});
    this.store$.dispatch(
      new DuplicateProjectAction({
        id: this.selectedProject$.value._id,
        onSuccess: project => this.onActionSuccess(project),
        onFailure: err => this.onActionFailure(err),
      })
    );
  }

  openDeleteConfirmation() {
    const dialog = this.dialog.open(ListViewWarningDialogComponent, {
      backdropClass: 'oph-backdrop',
      panelClass: 'list-views-warning-dialog',
      data: {
        type: 'project',
        project: this.selectedProject$.value,
        customClose: true,
        callback: () => this.deleteProject(dialog),
      },
    });
  }

  deleteProject(dialog: MatDialogRef<ListViewWarningDialogComponent>) {
    this.projects$.pipe(take(1)).subscribe(projects => {
      this.loading$.next({...this.loading$.value, projectAction: true});
      this.store$.dispatch(
        new DeleteProjectAction({
          id: this.selectedProject$.value._id,
          onSuccess: () => this.onActionSuccess(projects[0], dialog),
          onFailure: err => this.onActionFailure(err),
        })
      );
    });
  }

  onActionSuccess(project: Project, dialog?: MatDialogRef<ListViewWarningDialogComponent>) {
    this.store$.dispatch(new GetProjectsAction({}));
    if (dialog) {
      dialog.close();
    }
    sessionStorage.setItem('ophStoredProjectId', project._id);
    this.loading$.next({...this.loading$.value, projectAction: false});
  }

  onActionFailure(err: Error) {
    this.messageService.add(err.message || 'There was an error with the Project.');
    this.loading$.next({...this.loading$.value, projectAction: false});
  }

  onAddSked() {
    this.dialog.open(ProjectsSkedSelectorDialogComponent, {
      ...PROJECTS_ADD_SKED_DIALOG_CONFIG,
      data: {projectId: this.selectedProject$.value._id},
    });
  }

  onSelectedRoutineChange(routine: SelectedRoutine) {
    this.selectedRoutine$.next(routine);
    if (routine) {
      this.saveScrollPosition('main');
      this.scrollPositions$.next({...this.scrollPositions$.value, routine: 0});
      this.setScrollPosition('routine');
    } else {
      this.setScrollPosition('main');
    }
  }

  saveScrollPosition(name: string) {
    const position = this.elRef.nativeElement.scrollTop;
    this.scrollPositions$.next({...this.scrollPositions$.value, [name]: position});
  }

  setScrollPosition(name: string) {
    const position = this.scrollPositions$.value[name];
    timer(0).subscribe(() => {
      this.elRef.nativeElement.scrollTop = position;
    });
  }
}
