import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Token, TokenData} from '../model/token';

@Injectable({
  providedIn: 'root',
})
export class TokensApiService {
  private url = `${environment.apiUrl}/tokens`;

  constructor(private http: HttpClient) {}

  public getAll(params): Observable<TokenData> {
    return this.http.get<TokenData>(this.url, {params});
  }

  public getSingle(id: string): Observable<Token> {
    return this.http.get<Token>(`${this.url}/${id}`);
  }

  public createNew(token: Token): Observable<Token> {
    return this.http.post<Token>(`${this.url}`, {...token});
  }

  public update(token: Token): Observable<Token> {
    return this.http.put<Token>(`${this.url}/${token._id}`, {...token});
  }

  public duplicate(tokenId: string): Observable<Token> {
    return this.http.post<Token>(`${this.url}/${tokenId}`, {});
  }

  public delete(tokenId: string) {
    return this.http.delete(`${this.url}/${tokenId}`);
  }
}
