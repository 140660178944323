import {EMPTY_ROUTINE_DETAIL_PARAMS, EMPTY_TOKEN_FILTER_PARAMS} from 'src/app/projects/shared/projects-constants';
import {
  Project,
  ProjectView,
  ProjectViewRoutineDetail,
  ProjectViewRoutineDetailParams,
  ProjectViewTokenFilterParams,
} from '../../model/project';
import {TaskTemplate} from '../../model/task';

export interface ProjectsState {
  projects: Project[];
  projectsMap: Record<string, Project>;
  projectViews: Record<string, ProjectView>;
  projectViewTaskId: string;
  routineDetailMap: Record<string, ProjectViewRoutineDetail>;
  taskTemplates: TaskTemplate[];
  projectViewRoutineDetailParams: ProjectViewRoutineDetailParams;
  projectViewTokenFilterParams: ProjectViewTokenFilterParams;
}

export const initialProjectsState: ProjectsState = {
  projects: null,
  projectsMap: {},
  projectViews: {},
  projectViewTaskId: null,
  routineDetailMap: {},
  taskTemplates: null,
  projectViewRoutineDetailParams: EMPTY_ROUTINE_DETAIL_PARAMS,
  projectViewTokenFilterParams: EMPTY_TOKEN_FILTER_PARAMS,
};
