import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {Project, ProjectStep} from 'src/app/core/model/project';
import {
  CreateProjectStepAction,
  DeleteProjectStepAction,
  UpdateProjectStepAction,
} from 'src/app/core/store/projects/projects.action';
import {GetAllTrackersAction} from 'src/app/core/store/trackers/trackers.action';
import {MessageService} from 'src/app/services/message.service';
import {
  OphButtonGroupComponent,
  OphButtonGroupOption,
} from 'src/app/shared/design/oph-button-group/oph-button-group.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphInputOrangeComponent} from 'src/app/shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {TaskTriggerSelectionComponent} from 'src/app/shared/tasks/trigger-selection/task-trigger-selection.component';

interface DialogData {
  step: ProjectStep;
  project: Project;
  // If stepIndex is null, it means a new step is being created
  stepIndex: number;
}

@Component({
  selector: 'project-edit-step-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    OphButtonModule,
    OphInputOrangeComponent,
    OphButtonGroupComponent,
    TaskTriggerSelectionComponent,
    OphIconModule,
    MatIconModule,
  ],
  templateUrl: './project-edit-step-dialog.component.html',
  styleUrl: './project-edit-step-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditStepDialogComponent implements OnInit {
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<ProjectEditStepDialogComponent>);
  form: FormGroup;
  loadingSave = false;
  booleanOptions: OphButtonGroupOption[] = [{name: 'yes'}, {name: 'no'}];
  formSub: Subscription;
  confirmView: boolean;
  stepHasTasks: boolean;

  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.form = this.getForm(this.data?.step || null);
    this.store$.dispatch(new GetAllTrackersAction({params: {projectId: this.data?.project._id, pageSize: 1000}}));
    this.stepHasTasks = !!this.data.step?.tasks?.length;
  }

  getForm(step: ProjectStep | null): FormGroup {
    return this.fb.group({
      name: [step?.name || '', Validators.required],
      tracker: this.fb.group({
        trackerId: [step?.tracker?.trackerId || null],
        metricId: [step?.tracker?.metricId || null],
        operator: [step?.tracker?.operator || 'eq'],
        value: [String(step?.tracker?.value) || null],
      }),
      previousStepRequired: [step?.previousStepRequired || false],
      tasks: [step?.tasks || []],
    });
  }

  onTypeChange(type: string) {
    this.form.patchValue({type});
    this.form.get('taskId')?.updateValueAndValidity();
    this.form.get('tracker')?.updateValueAndValidity();
  }

  onClearTracker() {
    this.form.patchValue({tracker: {trackerId: null, metricId: null, operator: 'eq', value: null}});
  }

  onBooleanOptionChange(option: string, type: string) {
    const value = option === 'yes';
    this.form.patchValue({previousStepRequired: value});
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (!this.confirmView && !this.getTrackerValid()) {
      this.confirmView = true;
      return;
    }

    this.loadingSave = true;
    const project = this.getUpdatedProject();
    if (this.data.stepIndex === null) {
      // If new
      this.createNewStep(project);
    } else {
      this.updateStep(project);
    }
  }

  getTrackerValid(): boolean {
    const formTracker = this.form.get('tracker');
    return (
      !!formTracker.get('trackerId')?.value &&
      !!formTracker.get('metricId')?.value &&
      !!formTracker.get('operator')?.value &&
      (!!formTracker.get('value')?.value || formTracker.get('value')?.value === '0')
    );
  }

  createNewStep(project: Project) {
    this.store$.dispatch(
      new CreateProjectStepAction({
        project,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  updateStep(project: Project) {
    this.store$.dispatch(
      new UpdateProjectStepAction({
        project,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onSuccess() {
    this.dialogRef.close();
    this.loadingSave = false;
  }

  onFailure(err: HttpErrorResponse) {
    this.loadingSave = false;
    this.messageService.add(err.error || 'There was a problem updating the project');
  }

  getUpdatedProject(): Project {
    const isNewStep = !!this.data.stepIndex || this.data.stepIndex === 0;
    const steps = _.cloneDeep(this.data.project.steps);
    const step = this.getStepFromForm();
    if (!isNewStep) {
      steps.push(step);
    } else {
      steps[this.data.stepIndex] = step;
    }
    const projectPartial = {
      ...this.data.project,
      steps,
    };
    return projectPartial;
  }

  getStepFromForm(): ProjectStep {
    const step = {...this.data.step, ...this.form.value};
    if (!this.getTrackerValid()) {
      delete step.tracker;
    }
    return step;
  }

  onDelete() {
    const steps = _.cloneDeep(this.data.project.steps);
    steps.splice(this.data.stepIndex, 1);
    // If the new first step had previousStepRequired true, it should be changed to false
    steps[0].previousStepRequired = false;
    const project = {
      ...this.data.project,
      steps,
    };
    this.deleteStep(project);
  }

  deleteStep(project: Project) {
    this.store$.dispatch(
      new DeleteProjectStepAction({
        project,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }
}
