export interface TaskIconItem {
  name: string;
  icons: TaskIcon[];
}

export interface TaskIcon {
  name: string;
  keywords: string[];
  categoryName?: string;
  categoryIndex?: number;
}

export const TASK_ICON_PICKER_ICONS: TaskIconItem[] = [
  {
    name: 'miscellaneous',
    icons: [
      {
        name: 'achievement',
        keywords: ['miscellaneous', 'achievement', 'success', 'award', 'goal', 'reward', 'accomplishment'],
      },
      {
        name: 'achivement-business-mission',
        keywords: ['miscellaneous', 'achivement-business-mission', 'goal', 'company', 'strategy', 'target', 'success'],
      },
      {
        name: 'achivement-mission-sport',
        keywords: [
          'miscellaneous',
          'achivement-mission-sport',
          'competition',
          'athlete',
          'challenge',
          'victory',
          'tournament',
        ],
      },
      {
        name: 'admissiontickets',
        keywords: ['miscellaneous', 'admissiontickets', 'entry', 'event', 'ticket', 'pass', 'access'],
      },
      {
        name: 'alarmclock',
        keywords: ['miscellaneous', 'alarmclock', 'time', 'wake', 'morning', 'ring', 'alert'],
      },
      {
        name: 'alchemist-arcanum-magic',
        keywords: ['miscellaneous', 'alchemist-arcanum-magic', 'alchemy', 'potion', 'mystic', 'arcane', 'sorcery'],
      },
      {
        name: 'aquarius',
        keywords: ['miscellaneous', 'aquarius', 'zodiac', 'astrology', 'horoscope', 'water-bearer', 'sign'],
      },
      {
        name: 'arcanum-halloween-poison',
        keywords: ['miscellaneous', 'arcanum-halloween-poison', 'magic', 'spooky', 'toxic', 'brew', 'elixir'],
      },
      {
        name: 'archeology',
        keywords: ['miscellaneous', 'archeology', 'history', 'excavation', 'ancient', 'fossils', 'research'],
      },
      {
        name: 'bagpack',
        keywords: ['miscellaneous', 'bagpack', 'backpack', 'travel', 'storage', 'carry', 'gear'],
      },
      {
        name: 'yarn-ball',
        keywords: ['miscellaneous', 'yarn-ball', 'yarn', 'knitting', 'fiber', 'thread', 'craft'],
      },
      {
        name: 'ban-sign',
        keywords: ['miscellaneous', 'ban-sign', 'prohibit', 'no', 'forbidden', 'stop', 'symbol'],
      },
      {
        name: 'bank',
        keywords: ['miscellaneous', 'bank', 'finance', 'money', 'saving', 'institution', 'economy'],
      },
      {
        name: 'bank-card',
        keywords: ['miscellaneous', 'bank-card', 'credit', 'debit', 'payment', 'transaction', 'finance'],
      },
      {
        name: 'basket',
        keywords: ['miscellaneous', 'basket', 'container', 'shopping', 'carry', 'weave', 'storage'],
      },
      {
        name: 'beermug',
        keywords: ['miscellaneous', 'beermug', 'drink', 'beer', 'pub', 'glass', 'bar'],
      },
      {
        name: 'birthdaycake',
        keywords: ['miscellaneous', 'birthdaycake', 'party', 'celebration', 'dessert', 'candles', 'sweet'],
      },
      {
        name: 'blackboard',
        keywords: ['miscellaneous', 'blackboard', 'school', 'chalk', 'teach', 'classroom', 'lesson'],
      },
      {
        name: 'blueprint',
        keywords: ['miscellaneous', 'blueprint', 'plan', 'design', 'architecture', 'draft', 'project'],
      },
      {
        name: 'bomb',
        keywords: ['miscellaneous', 'bomb', 'explosive', 'danger', 'weapon', 'blast', 'warning'],
      },
      {
        name: 'book',
        keywords: ['miscellaneous', 'book', 'reading', 'literature', 'pages', 'knowledge', 'library'],
      },
      {
        name: 'book-2',
        keywords: ['miscellaneous', 'book', 'reading', 'literature', 'pages', 'knowledge', 'library'],
      },
      {
        name: 'book-green',
        keywords: ['miscellaneous', 'book-green', 'reading', 'literature', 'green', 'pages', 'cover'],
      },
      {
        name: 'books',
        keywords: ['miscellaneous', 'books', 'library', 'reading', 'literature', 'collection', 'stories'],
      },
      {
        name: 'bouquet',
        keywords: ['miscellaneous', 'bouquet', 'flowers', 'gift', 'wedding', 'decoration', 'fragrance'],
      },
      {
        name: 'brain',
        keywords: ['miscellaneous', 'brain', 'mind', 'thinking', 'intelligence', 'anatomy', 'organ'],
      },
      {
        name: 'bullet-bil',
        keywords: ['miscellaneous', 'bullet-bil', 'ammo', 'weapon', 'projectile', 'firearm', 'gun'],
      },
      {
        name: 'bullet-weapons',
        keywords: ['miscellaneous', 'bullet-weapons', 'ammo', 'firearm', 'projectile', 'shooting', 'gun'],
      },
      {
        name: 'business-briefcase',
        keywords: ['miscellaneous', 'business-briefcase', 'work', 'office', 'documents', 'professional', 'bag'],
      },
      {
        name: 'bust',
        keywords: ['miscellaneous', 'bust', 'statue', 'sculpture', 'head', 'art', 'display'],
      },
      {
        name: 'calendar-teeth',
        keywords: ['miscellaneous', 'calendar-teeth', 'dates', 'schedule', 'dental', 'reminder', 'planner'],
      },
      {
        name: 'capricorn',
        keywords: ['miscellaneous', 'capricorn', 'zodiac', 'astrology', 'earth', 'horoscope', 'sign'],
      },
      {
        name: 'cards',
        keywords: ['miscellaneous', 'cards', 'paper', 'message', 'greeting', 'invitation', 'note'],
      },
      {
        name: 'credit-card',
        keywords: ['miscellaneous', 'card-credit', 'payment', 'finance', 'banking', 'transaction', 'plastic'],
      },
      {
        name: 'shopping-cart',
        keywords: ['miscellaneous', 'carts-cart', 'shopping', 'wheels', 'store', 'retail', 'push'],
      },
      {
        name: 'cash-bill',
        keywords: ['miscellaneous', 'cash-bill', 'money', 'currency', 'payment', 'paper', 'finance'],
      },
      {
        name: 'cash-coupons',
        keywords: ['miscellaneous', 'cash-coupons', 'discount', 'money', 'voucher', 'saving', 'redeem'],
      },
      {
        name: 'certificate',
        keywords: ['miscellaneous', 'certificate', 'document', 'award', 'official', 'recognition', 'paper'],
      },
      {
        name: 'certificate-landscape',
        keywords: ['miscellaneous', 'certificate-landscape', 'document', 'award', 'horizontal', 'official', 'paper'],
      },
      {
        name: 'certificate-star',
        keywords: ['miscellaneous', 'certificate-star', 'document', 'award', 'achievement', 'recognition', 'honor'],
      },
      {
        name: 'chemistry',
        keywords: ['miscellaneous', 'chemistry', 'science', 'lab', 'experiment', 'chemicals', 'study'],
      },
      {
        name: 'chemistry-tube',
        keywords: ['miscellaneous', 'chemistry-tube', 'test-tube', 'lab', 'experiment', 'science', 'glassware'],
      },
      {
        name: 'chineseflag',
        keywords: ['miscellaneous', 'chineseflag', 'China', 'nation', 'symbol', 'country', 'banner'],
      },
      {
        name: 'christmas-star',
        keywords: ['miscellaneous', 'christmas-star', 'holiday', 'decoration', 'ornament', 'festive', 'symbol'],
      },
      {
        name: 'clock-time-watch',
        keywords: ['miscellaneous', 'clock-time-watch', 'time', 'hour', 'minute', 'wristwatch', 'timer'],
      },
      {
        name: 'coins-1',
        keywords: ['miscellaneous', 'coins-cash', 'money', 'currency', 'metal', 'finance', 'change'],
      },
      {
        name: 'coins-2',
        keywords: ['miscellaneous', 'coins-money', 'currency', 'finance', 'metal', 'change', 'payment'],
      },
      {
        name: 'coins-3',
        keywords: ['miscellaneous', 'coins-money', 'currency', 'finance', 'metal', 'change', 'payment'],
      },
      {
        name: 'collar',
        keywords: ['miscellaneous', 'collar', 'clothing', 'shirt', 'fashion', 'neck', 'accessory'],
      },
      {
        name: 'coordinate',
        keywords: ['miscellaneous', 'coordinate', 'map', 'position', 'location', 'GPS', 'axis'],
      },
      {
        name: 'crown-gem',
        keywords: ['miscellaneous', 'crown-gem', 'royalty', 'jewel', 'king', 'queen', 'regal'],
      },
      {
        name: 'crystal-shard',
        keywords: ['miscellaneous', 'crystal-shard', 'gem', 'mineral', 'sparkle', 'fragment', 'shiny'],
      },
      {
        name: 'cup',
        keywords: ['miscellaneous', 'cup', 'drink', 'container', 'beverage', 'mug', 'hold'],
      },
      {
        name: 'diagram',
        keywords: ['miscellaneous', 'diagram', 'chart', 'visual', 'data', 'graph', 'explanation'],
      },
      {
        name: 'dialog-box',
        keywords: ['miscellaneous', 'dialog-box', 'window', 'UI', 'message', 'popup', 'interface'],
      },
      {
        name: 'drawing',
        keywords: ['miscellaneous', 'drawing', 'art', 'sketch', 'pencil', 'illustration', 'creative'],
      },
      {
        name: 'e-learning',
        keywords: ['miscellaneous', 'e-learning', 'online', 'education', 'course', 'study', 'digital'],
      },
      {
        name: 'ear',
        keywords: ['miscellaneous', 'ear', 'hearing', 'organ', 'sound', 'anatomy', 'listen'],
      },
      {
        name: 'eraser',
        keywords: ['miscellaneous', 'eraser', 'rubber', 'pencil', 'mistake', 'correction', 'stationery'],
      },
      {
        name: 'film-roll',
        keywords: ['miscellaneous', 'film-roll', 'camera', 'photography', 'analog', 'movie', 'develop'],
      },
      {
        name: 'finish-line',
        keywords: ['miscellaneous', 'finish-line', 'race', 'end', 'goal', 'competition', 'checkpoint'],
      },
      {
        name: 'fire',
        keywords: ['miscellaneous', 'fire', 'flame', 'heat', 'burn', 'danger', 'light'],
      },
      {
        name: 'flag',
        keywords: ['miscellaneous', 'flag', 'symbol', 'country', 'banner', 'nation', 'emblem'],
      },
      {
        name: 'flag-2',
        keywords: ['miscellaneous', 'flag', 'symbol', 'country', 'banner', 'nation', 'emblem'],
      },
      {
        name: 'flag-with-number-one',
        keywords: ['miscellaneous', 'flag-with-number-one', 'ranking', 'first', 'position', 'winner', 'symbol'],
      },
      {
        name: 'folder',
        keywords: ['miscellaneous', 'folder', 'documents', 'files', 'organization', 'storage', 'directory'],
      },
      {
        name: 'fork-and-knife-cooking-kitchen-emoj',
        keywords: [
          'miscellaneous',
          'fork-and-knife-cooking-kitchen-emoj',
          'eating',
          'utensils',
          'dining',
          'food',
          'meal',
        ],
      },
      {
        name: 'franceflag',
        keywords: ['miscellaneous', 'franceflag', 'France', 'country', 'national', 'symbol', 'banner'],
      },
      {
        name: 'fullmoon-night',
        keywords: ['miscellaneous', 'fullmoon-night', 'lunar', 'moonlight', 'evening', 'sky', 'glow'],
      },
      {
        name: 'gear',
        keywords: ['miscellaneous', 'gear', 'cog', 'mechanism', 'machinery', 'tool', 'rotate'],
      },
      {
        name: 'gender',
        keywords: ['miscellaneous', 'gender', 'male', 'female', 'symbol', 'identity', 'sex'],
      },
      {
        name: 'general-caution',
        keywords: ['miscellaneous', 'general-caution', 'warning', 'alert', 'danger', 'notice', 'sign'],
      },
      {
        name: 'globe',
        keywords: ['miscellaneous', 'globe', 'earth', 'world', 'map', 'planet', 'global'],
      },
      {
        name: 'golden-medal',
        keywords: ['miscellaneous', 'golden-medal', 'award', 'prize', 'winner', 'achievement', 'metal'],
      },
      {
        name: 'greatbritainflag',
        keywords: ['miscellaneous', 'greatbritainflag', 'UK', 'nation', 'country', 'symbol', 'banner'],
      },
      {
        name: 'hand-account',
        keywords: ['miscellaneous', 'hand-account', 'user', 'profile', 'login', 'identity', 'access'],
      },
      {
        name: 'have-a-meal',
        keywords: ['miscellaneous', 'have-a-meal', 'eat', 'food', 'dine', 'lunch', 'dinner'],
      },
      {
        name: 'hourglassflowingsand',
        keywords: ['miscellaneous', 'hourglassflowingsand', 'time', 'sand', 'timer', 'countdown', 'waiting'],
      },
      {
        name: 'insecticide',
        keywords: ['miscellaneous', 'insecticide-insecticide', 'pesticide', 'bugs', 'chemical', 'spray', 'kill'],
      },
      {
        name: 'italianflag',
        keywords: ['miscellaneous', 'italianflag', 'Italy', 'country', 'symbol', 'nation', 'banner'],
      },
      {
        name: 'item-bag',
        keywords: ['miscellaneous', 'item-bag', 'container', 'storage', 'carry', 'holding', 'sack'],
      },
      {
        name: 'izakayalantern',
        keywords: ['miscellaneous', 'izakayalantern', 'Japanese', 'lantern', 'light', 'restaurant', 'traditional'],
      },
      {
        name: 'japanflag',
        keywords: ['miscellaneous', 'japanflag', 'Japan', 'country', 'symbol', 'nation', 'banner'],
      },
      {
        name: 'key',
        keywords: ['miscellaneous', 'key', 'lock', 'security', 'unlock', 'metal', 'access'],
      },
      {
        name: 'koreaflag',
        keywords: ['miscellaneous', 'koreaflag', 'Korea', 'country', 'symbol', 'nation', 'banner'],
      },
      {
        name: 'largebluecircle',
        keywords: ['miscellaneous', 'largebluecircle', 'shape', 'blue', 'geometry', 'symbol', 'round'],
      },
      {
        name: 'largebluediamond',
        keywords: ['miscellaneous', 'largebluediamond', 'shape', 'diamond', 'blue', 'geometry', 'symbol'],
      },
      {
        name: 'largeorangediamond',
        keywords: ['miscellaneous', 'largeorangediamond', 'shape', 'diamond', 'orange', 'geometry', 'symbol'],
      },
      {
        name: 'largeredcircle',
        keywords: ['miscellaneous', 'largeredcircle', 'shape', 'circle', 'red', 'geometry', 'symbol'],
      },
      {
        name: 'ledger',
        keywords: ['miscellaneous', 'ledger', 'record', 'finance', 'accounts', 'bookkeeping', 'transactions'],
      },
      {
        name: 'leftspeechbubble',
        keywords: ['miscellaneous', 'leftspeechbubble', 'chat', 'message', 'comment', 'bubble', 'communication'],
      },
      {
        name: 'leftthoughtbubble',
        keywords: ['miscellaneous', 'leftthoughtbubble', 'idea', 'thinking', 'bubble', 'imagination', 'comic'],
      },
      {
        name: 'letter-postcard',
        keywords: ['miscellaneous', 'letter-postcard', 'mail', 'send', 'travel', 'message', 'stamp'],
      },
      {
        name: 'letter-postcard-1',
        keywords: ['miscellaneous', 'letter-postcard-1', 'mail', 'send', 'travel', 'message', 'stamp'],
      },
      {
        name: 'libra',
        keywords: ['miscellaneous', 'libra', 'zodiac', 'balance', 'astrology', 'horoscope', 'sign'],
      },
      {
        name: 'life-buoy',
        keywords: ['miscellaneous', 'life-buoy', 'rescue', 'safety', 'float', 'maritime', 'survival'],
      },
      {
        name: 'lightning',
        keywords: ['miscellaneous', 'lightning', 'storm', 'electric', 'flash', 'weather', 'bolt'],
      },
      {
        name: 'lightningmood',
        keywords: ['miscellaneous', 'lightningmood', 'energy', 'electric', 'intense', 'feeling', 'symbol'],
      },
      {
        name: 'lightningmoodbubble',
        keywords: ['miscellaneous', 'lightningmoodbubble', 'thought', 'energy', 'electric', 'intense', 'feeling'],
      },
      {
        name: 'litter-in-bin-sign',
        keywords: ['miscellaneous', 'litter-in-bin-sign', 'trash', 'waste', 'garbage', 'bin', 'symbol'],
      },
      {
        name: 'lock-directory',
        keywords: ['miscellaneous', 'lock-directory', 'security', 'folder', 'protected', 'privacy', 'files'],
      },
      {
        name: 'loudspeaker',
        keywords: ['miscellaneous', 'loudspeaker', 'sound', 'announce', 'audio', 'amplify', 'broadcast'],
      },
      {
        name: 'loveletter',
        keywords: ['miscellaneous', 'loveletter', 'romance', 'affection', 'heart', 'message', 'sentiment'],
      },
      {
        name: 'lowbrightness',
        keywords: ['miscellaneous', 'lowbrightness', 'dim', 'screen', 'display', 'light', 'adjustment'],
      },
      {
        name: 'male-sign',
        keywords: ['miscellaneous', 'male-sign', 'gender', 'symbol', 'masculine', 'man', 'biology'],
      },
      {
        name: 'maps-and-flags-road-sign',
        keywords: [
          'miscellaneous',
          'maps-and-flags-road-sign',
          'navigation',
          'direction',
          'guide',
          'travel',
          'location',
        ],
      },
      {
        name: 'matemathic',
        keywords: ['miscellaneous', 'matemathic', 'math', 'equation', 'numbers', 'arithmetic', 'formula'],
      },
      {
        name: 'medal',
        keywords: ['miscellaneous', 'medal', 'award', 'prize', 'honor', 'achievement', 'decoration'],
      },
      {
        name: 'medal-of-honor',
        keywords: ['miscellaneous', 'medal-of-honor', 'award', 'bravery', 'military', 'recognition', 'prestige'],
      },
      {
        name: 'medals-reward-price',
        keywords: ['miscellaneous', 'medals-reward-price', 'award', 'prize', 'competition', 'trophy', 'value'],
      },
      {
        name: 'mediumblackcircle',
        keywords: ['miscellaneous', 'mediumblackcircle', 'shape', 'circle', 'black', 'geometry', 'symbol'],
      },
      {
        name: 'memo',
        keywords: ['miscellaneous', 'memo', 'note', 'reminder', 'message', 'written', 'office'],
      },
      {
        name: 'money',
        keywords: ['miscellaneous', 'money', 'currency', 'cash', 'finance', 'wealth', 'payment'],
      },
      {
        name: 'moneybag',
        keywords: ['miscellaneous', 'moneybag', 'wealth', 'cash', 'fortune', 'rich', 'bag'],
      },
      {
        name: 'moneywithwings',
        keywords: ['miscellaneous', 'moneywithwings', 'spending', 'currency', 'flying', 'loss', 'expense'],
      },
      {
        name: 'movie',
        keywords: ['miscellaneous', 'movie', 'film', 'cinema', 'entertainment', 'watch', 'screen'],
      },
      {
        name: 'movie-cinema',
        keywords: ['miscellaneous', 'movie-cinema', 'film', 'theater', 'entertainment', 'screen', 'show'],
      },
      {
        name: 'movie-film',
        keywords: ['miscellaneous', 'movie-film', 'cinema', 'entertainment', 'reel', 'production', 'video'],
      },
      {
        name: 'movie-ticket',
        keywords: ['miscellaneous', 'movie-ticket', 'cinema', 'admission', 'film', 'entry', 'theater'],
      },
      {
        name: 'music',
        keywords: ['miscellaneous', 'music', 'sound', 'song', 'melody', 'audio', 'tune'],
      },
      {
        name: 'music-1',
        keywords: ['miscellaneous', 'music-1', 'sound', 'song', 'track', 'audio', 'melody'],
      },
      {
        name: 'music-play',
        keywords: ['miscellaneous', 'music-play', 'song', 'audio', 'player', 'listen', 'track'],
      },
      {
        name: 'music-stop',
        keywords: ['miscellaneous', 'music-stop', 'pause', 'audio', 'sound', 'control', 'track'],
      },
      {
        name: 'namebadge',
        keywords: ['miscellaneous', 'namebadge', 'ID', 'identification', 'tag', 'label', 'employee'],
      },
      {
        name: 'nail-polish',
        keywords: ['miscellaneous', 'nail-polish', 'cosmetic', 'manicure', 'beauty', 'color', 'fingernails'],
      },
      {
        name: 'nail-polish-1',
        keywords: ['miscellaneous', 'nail-polish-1', 'cosmetic', 'manicure', 'beauty', 'color', 'fingernails'],
      },
      {
        name: 'newspaper',
        keywords: ['miscellaneous', 'newspaper', 'news', 'print', 'media', 'information', 'daily'],
      },
      {
        name: 'noentry',
        keywords: ['miscellaneous', 'noentry', 'forbidden', 'prohibited', 'stop', 'restricted', 'warning'],
      },
      {
        name: 'note',
        keywords: ['miscellaneous', 'note', 'message', 'reminder', 'paper', 'write', 'record'],
      },
      {
        name: 'notebook',
        keywords: ['miscellaneous', 'notebook', 'writing', 'paper', 'journal', 'notes', 'record'],
      },
      {
        name: 'notebookdecorativecover',
        keywords: ['miscellaneous', 'notebookdecorativecover', 'journal', 'writing', 'cover', 'notes', 'stationery'],
      },
      {
        name: 'office-supplies',
        keywords: ['miscellaneous', 'office-supplies', 'stationery', 'work', 'desk', 'tools', 'equipment'],
      },
      {
        name: 'oldkey',
        keywords: ['miscellaneous', 'oldkey', 'antique', 'rusty', 'vintage', 'unlock', 'metal'],
      },
      {
        name: 'onarrowleftright',
        keywords: ['miscellaneous', 'onarrowleftright', 'direction', 'navigation', 'arrow', 'horizontal', 'pointer'],
      },
      {
        name: 'openbook',
        keywords: ['miscellaneous', 'openbook', 'reading', 'pages', 'literature', 'story', 'knowledge'],
      },
      {
        name: 'openfilefolder',
        keywords: ['miscellaneous', 'openfilefolder', 'documents', 'files', 'directory', 'organization', 'browse'],
      },
      {
        name: 'openfolder',
        keywords: ['miscellaneous', 'openfolder', 'directory', 'documents', 'view', 'browse', 'storage'],
      },
      {
        name: 'ophiuchus',
        keywords: ['miscellaneous', 'ophiuchus', 'zodiac', 'astrology', 'constellation', 'horoscope', 'sign'],
      },
      {
        name: 'orangebook',
        keywords: ['miscellaneous', 'orangebook', 'book', 'color', 'reading', 'literature', 'pages'],
      },
      {
        name: 'package',
        keywords: ['miscellaneous', 'package', 'parcel', 'mail', 'box', 'shipping', 'delivery'],
      },
      {
        name: 'pages',
        keywords: ['miscellaneous', 'pages', 'paper', 'document', 'book', 'reading', 'leaflets'],
      },
      {
        name: 'paper-bag',
        keywords: ['miscellaneous', 'paper-bag', 'container', 'carry', 'grocery', 'recyclable', 'brown'],
      },
      {
        name: 'paperclip',
        keywords: ['miscellaneous', 'paperclip', 'stationery', 'hold', 'documents', 'attach', 'metal'],
      },
      {
        name: 'partypopper',
        keywords: ['miscellaneous', 'partypopper', 'celebration', 'confetti', 'festive', 'fun', 'surprise'],
      },
      {
        name: 'passport',
        keywords: ['miscellaneous', 'passport', 'travel', 'document', 'ID', 'international', 'identity'],
      },
      {
        name: 'penoverstampedenvelope',
        keywords: ['miscellaneous', 'penoverstampedenvelope', 'mail', 'write', 'letter', 'post', 'message'],
      },
      {
        name: 'personal-id',
        keywords: ['miscellaneous', 'personal-id', 'identification', 'document', 'card', 'official', 'verify'],
      },
      {
        name: 'pill',
        keywords: ['miscellaneous', 'pill', 'medicine', 'pharmacy', 'health', 'drug', 'treatment'],
      },
      {
        name: 'pin',
        keywords: ['miscellaneous', 'pin', 'attach', 'map', 'marker', 'hold', 'point'],
      },
      {
        name: 'pisces',
        keywords: ['miscellaneous', 'pisces', 'zodiac', 'astrology', 'fish', 'horoscope', 'sign'],
      },
      {
        name: 'playingcardblackjoker',
        keywords: ['miscellaneous', 'playingcardblackjoker', 'cards', 'game', 'joker', 'deck', 'gambling'],
      },
      {
        name: 'polaroid',
        keywords: ['miscellaneous', 'polaroid', 'photo', 'instant', 'camera', 'vintage', 'picture'],
      },
      {
        name: 'policecarsrevolvinglight',
        keywords: ['miscellaneous', 'policecarsrevolvinglight', 'emergency', 'warning', 'police', 'signal', 'alert'],
      },
      {
        name: 'portfolio',
        keywords: ['miscellaneous', 'portfolio', 'work', 'documents', 'investment', 'folder', 'collection'],
      },
      {
        name: 'potion',
        keywords: ['miscellaneous', 'potion', 'brew', 'magic', 'liquid', 'elixir', 'fantasy'],
      },
      {
        name: 'potion-pink',
        keywords: ['miscellaneous', 'potion-pink', 'brew', 'magic', 'liquid', 'elixir', 'colorful'],
      },
      {
        name: 'present',
        keywords: ['miscellaneous', 'present', 'gift', 'surprise', 'wrap', 'holiday', 'celebration'],
      },
      {
        name: 'present-with-star-gift',
        keywords: ['miscellaneous', 'present-with-star-gift', 'gift', 'surprise', 'holiday', 'star', 'package'],
      },
      {
        name: 'pushpin',
        keywords: ['miscellaneous', 'pushpin', 'stationery', 'pin', 'attach', 'corkboard', 'notice'],
      },
      {
        name: 'quiz',
        keywords: ['miscellaneous', 'quiz', 'test', 'questions', 'knowledge', 'exam', 'assessment'],
      },
      {
        name: 'rainbow',
        keywords: ['miscellaneous', 'rainbow', 'colors', 'sky', 'arc', 'nature', 'spectrum'],
      },
      {
        name: 'recycle-5',
        keywords: ['miscellaneous', 'recycle-5', 'reuse', 'eco', 'environment', 'waste', 'green'],
      },
      {
        name: 'red-book',
        keywords: ['miscellaneous', 'red-book', 'book', 'red', 'literature', 'reading', 'pages'],
      },
      {
        name: 'red-heart',
        keywords: ['miscellaneous', 'red-heart', 'love', 'romance', 'affection', 'valentine', 'symbol'],
      },
      {
        name: 'redheart',
        keywords: ['miscellaneous', 'redheart', 'love', 'romance', 'emoji', 'valentine', 'symbol'],
      },
      {
        name: 'reminder',
        keywords: ['miscellaneous', 'reminder', 'note', 'alert', 'memory', 'prompt', 'notification'],
      },
      {
        name: 'revolvinghearts',
        keywords: ['miscellaneous', 'revolvinghearts', 'love', 'emoji', 'affection', 'whirling', 'romance'],
      },
      {
        name: 'ringingbell',
        keywords: ['miscellaneous', 'ringingbell', 'alarm', 'sound', 'chime', 'alert', 'signal'],
      },
      {
        name: 'roundpushpin',
        keywords: ['miscellaneous', 'roundpushpin', 'stationery', 'pin', 'attach', 'board', 'marker'],
      },
      {
        name: 'russianflag',
        keywords: ['miscellaneous', 'russianflag', 'Russia', 'country', 'nation', 'symbol', 'banner'],
      },
      {
        name: 'schoolsatchel',
        keywords: ['miscellaneous', 'schoolsatchel', 'bag', 'school', 'books', 'carry', 'student'],
      },
      {
        name: 'scorpio',
        keywords: ['miscellaneous', 'scorpio', 'zodiac', 'astrology', 'horoscope', 'scorpion', 'sign'],
      },
      {
        name: 'scroll',
        keywords: ['miscellaneous', 'scroll', 'roll', 'paper', 'document', 'ancient', 'text'],
      },
      {
        name: 'shield-teeth',
        keywords: ['miscellaneous', 'shield-teeth', 'protection', 'armor', 'defense', 'emblem', 'symbol'],
      },
      {
        name: 'shootingstar',
        keywords: ['miscellaneous', 'shootingstar', 'night', 'wish', 'celestial', 'meteor', 'sky'],
      },
      {
        name: 'shopping-bag',
        keywords: ['miscellaneous', 'shopping-bag', 'retail', 'store', 'purchase', 'carry', 'consumer'],
      },
      {
        name: 'sign-add',
        keywords: ['miscellaneous', 'sign-add', 'plus', 'symbol', 'addition', 'increase', 'math'],
      },
      {
        name: 'sign-check',
        keywords: ['miscellaneous', 'sign-check', 'mark', 'correct', 'yes', 'verify', 'symbol'],
      },
      {
        name: 'sign-delete',
        keywords: ['miscellaneous', 'sign-delete', 'remove', 'minus', 'no', 'erase', 'symbol'],
      },
      {
        name: 'sign-down',
        keywords: ['miscellaneous', 'sign-down', 'arrow', 'direction', 'downward', 'symbol', 'navigate'],
      },
      {
        name: 'sign-error',
        keywords: ['miscellaneous', 'sign-error', 'warning', 'mistake', 'alert', 'caution', 'symbol'],
      },
      {
        name: 'sign-info',
        keywords: ['miscellaneous', 'sign-info', 'information', 'details', 'help', 'symbol', 'notice'],
      },
      {
        name: 'sign-left',
        keywords: ['miscellaneous', 'sign-left', 'arrow', 'direction', 'left', 'symbol', 'navigate'],
      },
      {
        name: 'sign-question',
        keywords: ['miscellaneous', 'sign-question', 'help', 'uncertain', 'query', 'symbol', 'inquire'],
      },
      {
        name: 'sign-right',
        keywords: ['miscellaneous', 'sign-right', 'arrow', 'direction', 'right', 'symbol', 'navigate'],
      },
      {
        name: 'sign-sync',
        keywords: ['miscellaneous', 'sign-sync', 'update', 'refresh', 'cycle', 'symbol', 'arrows'],
      },
      {
        name: 'sign-up',
        keywords: ['miscellaneous', 'sign-up', 'arrow', 'direction', 'up', 'symbol', 'navigate'],
      },
      {
        name: 'smokingsymbol',
        keywords: ['miscellaneous', 'smokingsymbol', 'cigarette', 'tobacco', 'warning', 'health', 'hazard'],
      },
      {
        name: 'snowmanwithoutsnow',
        keywords: ['miscellaneous', 'snowmanwithoutsnow', 'winter', 'cold', 'frost', 'frozen', 'figure'],
      },
      {
        name: 'spanishflag',
        keywords: ['miscellaneous', 'spanishflag', 'Spain', 'country', 'nation', 'symbol', 'banner'],
      },
      {
        name: 'sparkle',
        keywords: ['miscellaneous', 'sparkle', 'shine', 'glitter', 'bright', 'twinkle', 'light'],
      },
      {
        name: 'spell-book',
        keywords: ['miscellaneous', 'spell-book', 'magic', 'grimoire', 'pages', 'spells', 'witchcraft'],
      },
      {
        name: 'spell-scroll',
        keywords: ['miscellaneous', 'spell-scroll', 'magic', 'ancient', 'document', 'spells', 'fantasy'],
      },
      {
        name: 'spiralcalendarpad',
        keywords: ['miscellaneous', 'spiralcalendarpad', 'dates', 'schedule', 'planner', 'organize', 'notes'],
      },
      {
        name: 'spiralnotepad',
        keywords: ['miscellaneous', 'spiralnotepad', 'writing', 'notes', 'paper', 'journal', 'record'],
      },
      {
        name: 'spray-bottle-sprayer',
        keywords: ['miscellaneous', 'spray-bottle-sprayer', 'cleaning', 'spray', 'liquid', 'bottle', 'household'],
      },
      {
        name: 'star',
        keywords: ['miscellaneous', 'star', 'celestial', 'symbol', 'night', 'shine', 'shape'],
      },
      {
        name: 'start-line',
        keywords: ['miscellaneous', 'start-line', 'beginning', 'race', 'competition', 'track', 'marker'],
      },
      {
        name: 'start-line-yellow',
        keywords: ['miscellaneous', 'start-line-yellow', 'beginning', 'race', 'competition', 'track', 'marker'],
      },
      {
        name: 'statueofliberty',
        keywords: ['miscellaneous', 'statueofliberty', 'landmark', 'NYC', 'symbol', 'freedom', 'America'],
      },
      {
        name: 'stockchart',
        keywords: ['miscellaneous', 'stockchart', 'finance', 'investment', 'graph', 'trends', 'market'],
      },
      {
        name: 'street-map-gps',
        keywords: ['miscellaneous', 'street-map-gps', 'navigation', 'location', 'directions', 'travel', 'map'],
      },
      {
        name: 'street-sign',
        keywords: ['miscellaneous', 'street-sign', 'road', 'direction', 'traffic', 'guide', 'marker'],
      },
      {
        name: 'suitcase',
        keywords: ['miscellaneous', 'suitcase', 'travel', 'luggage', 'packing', 'trip', 'container'],
      },
      {
        name: 'suitcase-travel',
        keywords: ['miscellaneous', 'suitcase-travel', 'luggage', 'trip', 'journey', 'packing', 'travel'],
      },
      {
        name: 'suitcase-wide',
        keywords: ['miscellaneous', 'suitcase-wide', 'luggage', 'wide', 'travel', 'packing', 'container'],
      },
      {
        name: 'supermarket-gift',
        keywords: ['miscellaneous', 'supermarket-gift', 'store', 'present', 'retail', 'shopping', 'surprise'],
      },
      {
        name: 'surfboard',
        keywords: ['miscellaneous', 'surfboard', 'ocean', 'waves', 'sport', 'beach', 'riding'],
      },
      {
        name: 'table-of-contents',
        keywords: ['miscellaneous', 'table-of-contents', 'index', 'book', 'chapters', 'guide', 'listing'],
      },
      {
        name: 'tank',
        keywords: ['miscellaneous', 'tank', 'military', 'armored', 'vehicle', 'combat', 'weapon'],
      },
      {
        name: 'target-arrow',
        keywords: ['miscellaneous', 'target-arrow', 'aim', 'goal', 'bullseye', 'focus', 'direction'],
      },
      {
        name: 'test-tube-chemical',
        keywords: ['miscellaneous', 'test-tube-chemical', 'experiment', 'lab', 'chemistry', 'glass', 'liquid'],
      },
      {
        name: 'thoughtbaloon',
        keywords: ['miscellaneous', 'thoughtbaloon', 'idea', 'thinking', 'comic', 'bubble', 'imagination'],
      },
      {
        name: 'thunder',
        keywords: ['miscellaneous', 'thunder', 'storm', 'sound', 'lightning', 'weather', 'rumble'],
      },
      {
        name: 'ticket',
        keywords: ['miscellaneous', 'ticket', 'entry', 'admission', 'event', 'pass', 'coupon'],
      },
      {
        name: 'ticket-purchase',
        keywords: ['miscellaneous', 'ticket-purchase', 'buy', 'admission', 'event', 'transaction', 'pass'],
      },
      {
        name: 'tissues-paper',
        keywords: ['miscellaneous', 'tissues-paper', 'hygiene', 'soft', 'face', 'clean', 'absorbent'],
      },
      {
        name: 'toilet-paper',
        keywords: ['miscellaneous', 'toilet-paper', 'bathroom', 'hygiene', 'roll', 'cleaning', 'absorbent'],
      },
      {
        name: 'tooth-brush',
        keywords: ['miscellaneous', 'tooth-brush', 'dental', 'hygiene', 'cleaning', 'teeth', 'oral'],
      },
      {
        name: 'torch',
        keywords: ['miscellaneous', 'torch', 'light', 'fire', 'flame', 'illumination', 'handheld'],
      },
      {
        name: 'train-ticket',
        keywords: ['miscellaneous', 'train-ticket', 'travel', 'rail', 'admission', 'transport', 'journey'],
      },
      {
        name: 'treasure',
        keywords: ['miscellaneous', 'treasure', 'gold', 'chest', 'valuable', 'hidden', 'pirate'],
      },
      {
        name: 'tridentemblem',
        keywords: ['miscellaneous', 'tridentemblem', 'symbol', 'mythology', 'sea', 'poseidon', 'weapon'],
      },
      {
        name: 'trophy',
        keywords: ['miscellaneous', 'trophy', 'award', 'win', 'achievement', 'prize', 'cup'],
      },
      {
        name: 'trophy-2',
        keywords: ['miscellaneous', 'trophy', 'award', 'win', 'achievement', 'prize', 'cup'],
      },
      {
        name: 'trophy-first',
        keywords: ['miscellaneous', 'trophy-first', 'winner', 'award', 'victory', 'achievement', 'prize'],
      },
      {
        name: 'twospeechbubbles',
        keywords: [
          'miscellaneous',
          'twospeechbubbles',
          'chat',
          'conversation',
          'message',
          'communication',
          'discussion',
        ],
      },
      {
        name: 'umbrella',
        keywords: ['miscellaneous', 'umbrella', 'rain', 'protection', 'weather', 'cover', 'shade'],
      },
      {
        name: 'umbrellawithraindrops',
        keywords: ['miscellaneous', 'umbrellawithraindrops', 'rain', 'protection', 'wet', 'weather', 'cover'],
      },
      {
        name: 'video-course',
        keywords: ['miscellaneous', 'video-course', 'online', 'learning', 'tutorial', 'education', 'lessons'],
      },
      {
        name: 'wallet',
        keywords: ['miscellaneous', 'wallet', 'money', 'cash', 'cards', 'pocket', 'leather'],
      },
      {
        name: 'warningsign',
        keywords: ['miscellaneous', 'warningsign', 'alert', 'danger', 'caution', 'notice', 'symbol'],
      },
      {
        name: 'water-bottle',
        keywords: ['miscellaneous', 'water-bottle', 'drink', 'container', 'hydration', 'plastic', 'liquid'],
      },
      {
        name: 'water-fee',
        keywords: ['miscellaneous', 'water-fee', 'charge', 'bill', 'utilities', 'payment', 'service'],
      },
      {
        name: 'water-gun',
        keywords: ['miscellaneous', 'water-gun', 'toy', 'squirt', 'summer', 'fun', 'soak'],
      },
      {
        name: 'water-tank',
        keywords: ['miscellaneous', 'water-tank', 'storage', 'reservoir', 'liquid', 'container', 'supply'],
      },
      {
        name: 'whitepennant',
        keywords: ['miscellaneous', 'whitepennant', 'flag', 'white', 'symbol', 'surrender', 'banner'],
      },
      {
        name: 'window-layout',
        keywords: ['miscellaneous', 'window-layout', 'design', 'interface', 'arrangement', 'screen', 'GUI'],
      },
      {
        name: 'world-map',
        keywords: ['miscellaneous', 'world-map', 'globe', 'countries', 'atlas', 'geography', 'navigation'],
      },
      {
        name: 'zzz',
        keywords: ['miscellaneous', 'zzz', 'sleep', 'tired', 'rest', 'cartoon', 'nap'],
      },
    ],
  },
  {
    name: 'urban',
    icons: [
      {
        name: '4-star-hotel',
        keywords: ['urban', '4', 'star', 'hotel', 'accommodation', 'travel', 'luxury', 'rooms', 'booking'],
      },
      {
        name: 'airport',
        keywords: ['urban', 'airport', 'travel', 'airplanes', 'terminal', 'runway', 'departures', 'arrivals'],
      },
      {
        name: 'antenna',
        keywords: ['urban', 'antenna', 'signal', 'broadcast', 'television', 'radio', 'communication', 'wireless'],
      },
      {
        name: 'arena',
        keywords: ['urban', 'arena', 'stadium', 'sports', 'events', 'concerts', 'crowd', 'seating'],
      },
      {
        name: 'bank-1',
        keywords: ['urban', 'bank', 'finance', 'money', 'loans', 'savings', 'building', 'transaction'],
      },
      {
        name: 'bar',
        keywords: ['urban', 'bar', 'pub', 'drinks', 'alcohol', 'nightlife', 'social', 'counter'],
      },
      {
        name: 'basilica',
        keywords: ['urban', 'basilica', 'church', 'religious', 'historic', 'architecture', 'worship', 'tourism'],
      },
      {
        name: 'bench',
        keywords: ['urban', 'bench', 'seating', 'park', 'rest', 'wood', 'outdoor', 'street'],
      },
      {
        name: 'big-ben',
        keywords: ['urban', 'big', 'ben', 'landmark', 'london', 'clock', 'tourist', 'historic', 'tower'],
      },
      {
        name: 'billboard',
        keywords: ['urban', 'billboard', 'advertisement', 'promotion', 'large', 'sign', 'outdoor', 'marketing'],
      },
      {
        name: 'bowling-alley',
        keywords: ['urban', 'bowling', 'alley', 'pins', 'lanes', 'recreation', 'strike', 'ball', 'game'],
      },
      {
        name: 'bridge-suspension',
        keywords: ['urban', 'bridge', 'suspension', 'infrastructure', 'river', 'crossing', 'engineering', 'landmark'],
      },
      {
        name: 'bridge',
        keywords: ['urban', 'bridge', 'structure', 'span', 'river', 'connect', 'transport', 'path'],
      },
      {
        name: 'building-dome',
        keywords: ['urban', 'building', 'dome', 'architecture', 'curved', 'roof', 'monument', 'historic', 'urban'],
      },
      {
        name: 'building-salmon',
        keywords: [
          'urban',
          'building',
          'salmon',
          'architecture',
          'colorful',
          'facade',
          'offices',
          'residential',
          'urban',
        ],
      },
      {
        name: 'building-small',
        keywords: [
          'urban',
          'building',
          'small',
          'architecture',
          'structure',
          'offices',
          'residential',
          'compact',
          'urban',
        ],
      },
      {
        name: 'building',
        keywords: [
          'urban',
          'building',
          'structure',
          'architecture',
          'offices',
          'residential',
          'facade',
          'construction',
        ],
      },
      {
        name: 'bulletin-board',
        keywords: ['urban', 'bulletin', 'board', 'notices', 'postings', 'announcements', 'info', 'community', 'wall'],
      },
      {
        name: 'bus-stop',
        keywords: ['urban', 'bus', 'stop', 'transit', 'shelter', 'route', 'passengers', 'public', 'transport'],
      },
      {
        name: 'cafe',
        keywords: ['urban', 'cafe', 'coffee', 'beverage', 'snacks', 'social', 'relax', 'tables', 'menu'],
      },
      {
        name: 'castle-dracula-halloween',
        keywords: ['urban', 'castle', 'dracula', 'halloween', 'spooky', 'gothic', 'historic', 'tourism', 'night'],
      },
      {
        name: 'castle-grey',
        keywords: ['urban', 'castle', 'grey', 'fortress', 'stone', 'historic', 'medieval', 'tourism', 'walls'],
      },
      {
        name: 'castle-horror',
        keywords: ['urban', 'castle', 'horror', 'scary', 'haunted', 'medieval', 'spooky', 'stories', 'walls'],
      },
      {
        name: 'castle-tower',
        keywords: ['urban', 'castle', 'tower', 'fortification', 'stone', 'high', 'lookout', 'historic', 'walls'],
      },
      {
        name: 'castle',
        keywords: ['urban', 'castle', 'fortress', 'medieval', 'stone', 'historic', 'turrets', 'tourism', 'walls'],
      },
      {
        name: 'cemetery-grave-halloween',
        keywords: ['urban', 'cemetery', 'grave', 'halloween', 'tomb', 'spooky', 'memorial', 'quiet', 'stones'],
      },
      {
        name: 'chapel',
        keywords: ['urban', 'chapel', 'small', 'church', 'religious', 'worship', 'spiritual', 'intimate', 'historic'],
      },
      {
        name: 'cinema',
        keywords: ['urban', 'cinema', 'movies', 'theater', 'films', 'screen', 'entertainment', 'tickets', 'popcorn'],
      },
      {
        name: 'classicalbuilding',
        keywords: [
          'urban',
          'classicalbuilding',
          'classical',
          'building',
          'columns',
          'architecture',
          'historic',
          'cultural',
          'monument',
        ],
      },
      {
        name: 'cross-grave-halloween',
        keywords: ['urban', 'cross', 'grave', 'halloween', 'tomb', 'marker', 'cemetery', 'religious', 'spooky'],
      },
      {
        name: 'factory',
        keywords: ['urban', 'factory', 'industrial', 'manufacturing', 'production', 'machinery', 'smokestack', 'work'],
      },
      {
        name: 'fire-hydrant',
        keywords: ['urban', 'fire', 'hydrant', 'water', 'emergency', 'safety', 'street', 'red', 'infrastructure'],
      },
      {
        name: 'fountain',
        keywords: ['urban', 'fountain', 'water', 'feature', 'decorative', 'public', 'park', 'splash', 'relaxation'],
      },
      {
        name: 'garden-2',
        keywords: ['urban', 'garden', 'green', 'flowers', 'plants', 'relax', 'outdoors', 'nature', 'landscape'],
      },
      {
        name: 'gas-station',
        keywords: ['urban', 'gas', 'station', 'fuel', 'cars', 'pump', 'energy', 'roadside', 'convenience'],
      },
      {
        name: 'horizontaltrafficlight',
        keywords: [
          'urban',
          'horizontaltrafficlight',
          'horizontal',
          'trafficlight',
          'signal',
          'lights',
          'road',
          'stop',
          'go',
          'intersection',
        ],
      },
      {
        name: 'hospital',
        keywords: [
          'urban',
          'hospital',
          'medical',
          'healthcare',
          'treatment',
          'emergency',
          'doctors',
          'patient',
          'facility',
        ],
      },
      {
        name: 'hotel-beige',
        keywords: ['urban', 'hotel', 'beige', 'accommodation', 'rooms', 'guests', 'lobby', 'travel', 'overnight'],
      },
      {
        name: 'hotel',
        keywords: ['urban', 'hotel', 'accommodation', 'lobby', 'rooms', 'travel', 'stay', 'guests', 'tourism'],
      },
      {
        name: 'house-big',
        keywords: ['urban', 'house', 'big', 'residential', 'large', 'family', 'home', 'property', 'dwelling'],
      },
      {
        name: 'house',
        keywords: ['urban', 'house', 'residential', 'home', 'property', 'living', 'family', 'roof', 'dwelling'],
      },
      {
        name: 'housebuilding',
        keywords: [
          'urban',
          'housebuilding',
          'house',
          'building',
          'construction',
          'residential',
          'home',
          'property',
          'develop',
        ],
      },
      {
        name: 'houses',
        keywords: [
          'urban',
          'houses',
          'residential',
          'neighborhood',
          'homes',
          'street',
          'community',
          'suburb',
          'multiple',
        ],
      },
      {
        name: 'japanesecastle',
        keywords: [
          'urban',
          'japanesecastle',
          'japanese',
          'castle',
          'historic',
          'feudal',
          'fortress',
          'cultural',
          'tourism',
        ],
      },
      {
        name: 'japanesepostoffice',
        keywords: [
          'urban',
          'japanesepostoffice',
          'japanese',
          'postoffice',
          'mail',
          'letters',
          'service',
          'red',
          'local',
        ],
      },
      {
        name: 'lovehotel',
        keywords: ['urban', 'lovehotel', 'love', 'hotel', 'couples', 'privacy', 'accommodation', 'theme', 'romantic'],
      },
      {
        name: 'mail',
        keywords: ['urban', 'mail', 'letters', 'postal', 'envelope', 'delivery', 'communication', 'box', 'service'],
      },
      {
        name: 'nightwithstars',
        keywords: [
          'urban',
          'nightwithstars',
          'night',
          'with',
          'stars',
          'sky',
          'dark',
          'twinkle',
          'celestial',
          'peaceful',
        ],
      },
      {
        name: 'nuclear-power-plant',
        keywords: ['urban', 'nuclear', 'power', 'plant', 'energy', 'reactor', 'cooling', 'industrial', 'electricity'],
      },
      {
        name: 'officebuilding',
        keywords: [
          'urban',
          'officebuilding',
          'office',
          'building',
          'workplace',
          'company',
          'corporate',
          'highrise',
          'business',
        ],
      },
      {
        name: 'oil-drum',
        keywords: ['urban', 'oil', 'drum', 'barrel', 'fuel', 'storage', 'industrial', 'resource', 'metal'],
      },
      {
        name: 'openmailboxlowered',
        keywords: [
          'urban',
          'openmailboxlowered',
          'open',
          'mailbox',
          'lowered',
          'mail',
          'letters',
          'receive',
          'box',
          'post',
        ],
      },
      {
        name: 'openmailboxraised',
        keywords: [
          'urban',
          'openmailboxraised',
          'open',
          'mailbox',
          'raised',
          'mail',
          'letters',
          'incoming',
          'post',
          'box',
        ],
      },
      {
        name: 'pedestrian-crossing',
        keywords: ['urban', 'pedestrian', 'crossing', 'walk', 'road', 'safety', 'zebra', 'traffic', 'people'],
      },
      {
        name: 'pizza-shop',
        keywords: ['urban', 'pizza', 'shop', 'restaurant', 'food', 'slice', 'fast', 'delivery', 'dine'],
      },
      {
        name: 'rolledupnewspaper',
        keywords: [
          'urban',
          'rolledupnewspaper',
          'rolled',
          'up',
          'newspaper',
          'news',
          'print',
          'media',
          'delivery',
          'read',
        ],
      },
      {
        name: 'rollercoaster',
        keywords: ['urban', 'rollercoaster', 'amusement', 'ride', 'thrill', 'track', 'fun', 'loop', 'park'],
      },
      {
        name: 'school-beige',
        keywords: ['urban', 'school', 'beige', 'education', 'learning', 'students', 'building', 'classes', 'teachers'],
      },
      {
        name: 'school-bell',
        keywords: ['urban', 'school', 'bell', 'ring', 'class', 'time', 'students', 'alarm', 'signal'],
      },
      {
        name: 'school-flag',
        keywords: ['urban', 'school', 'flag', 'education', 'institution', 'symbol', 'pole', 'identity', 'campus'],
      },
      {
        name: 'school',
        keywords: ['urban', 'school', 'education', 'learning', 'students', 'teachers', 'building', 'classes', 'study'],
      },
      {
        name: 'slotmachine',
        keywords: ['urban', 'slotmachine', 'casino', 'gamble', 'coins', 'jackpot', 'levers', 'luck', 'entertainment'],
      },
      {
        name: 'stadium',
        keywords: ['urban', 'stadium', 'sports', 'events', 'seating', 'crowd', 'field', 'match', 'arena'],
      },
      {
        name: 'stop',
        keywords: ['urban', 'stop', 'halt', 'sign', 'traffic', 'warning', 'red', 'order', 'safety'],
      },
      {
        name: 'sunsetoverbuildings',
        keywords: [
          'urban',
          'sunsetoverbuildings',
          'sunset',
          'over',
          'buildings',
          'skyline',
          'evening',
          'silhouette',
          'beauty',
        ],
      },
      {
        name: 'tombstone-rip',
        keywords: ['urban', 'tombstone', 'rip', 'grave', 'marker', 'memorial', 'death', 'cemetery', 'stone'],
      },
      {
        name: 'toykotower',
        keywords: ['urban', 'toykotower', 'tokyo', 'tower', 'landmark', 'japan', 'broadcast', 'tourism', 'red'],
      },
      {
        name: 'traffic-light',
        keywords: ['urban', 'traffic', 'light', 'signal', 'red', 'green', 'yellow', 'intersection', 'cars'],
      },
      {
        name: 'us-capitol',
        keywords: ['urban', 'us', 'capitol', 'government', 'washington', 'dome', 'politics', 'historic', 'monument'],
      },
      {
        name: 'verticaltrafficlight',
        keywords: [
          'urban',
          'verticaltrafficlight',
          'vertical',
          'trafficlight',
          'signal',
          'lights',
          'road',
          'stop',
          'go',
          'intersection',
        ],
      },
      {
        name: 'walking-bridge',
        keywords: ['urban', 'walking', 'bridge', 'pedestrian', 'footpath', 'overpass', 'safe', 'crossing', 'elevated'],
      },
      {
        name: 'wastebasket',
        keywords: ['urban', 'wastebasket', 'trash', 'garbage', 'bin', 'waste', 'disposal', 'can', 'rubbish'],
      },
      {
        name: 'water-tank',
        keywords: ['urban', 'water', 'tank', 'storage', 'supply', 'tower', 'utility', 'liquid', 'container'],
      },
      {
        name: 'wedding',
        keywords: ['urban', 'wedding', 'ceremony', 'marriage', 'celebration', 'bride', 'groom', 'venue', 'reception'],
      },
    ],
  },
  {
    name: 'emojis',
    icons: [
      {
        name: 'agent',
        keywords: ['emoji', 'agent', 'spy', 'secret', 'mystery', 'undercover', 'detective'],
      },
      {
        name: 'angel',
        keywords: ['emoji', 'angel', 'halo', 'wings', 'heaven', 'goodness', 'spirit'],
      },
      {
        name: 'angry',
        keywords: ['emoji', 'angry', 'mad', 'upset', 'furious', 'annoyed', 'rage'],
      },
      {
        name: 'armor',
        keywords: ['emoji', 'armor', 'protection', 'knight', 'metal', 'shield', 'suit'],
      },
      {
        name: 'astonished-emoji',
        keywords: ['emoji', 'astonished', 'emoji', 'surprised', 'shocked', 'face', 'expression', 'wow'],
      },
      {
        name: 'astonished-emoji-1',
        keywords: ['emoji', 'astonished', 'emoji', 'surprised', 'shocked', 'face', 'expression', 'wow'],
      },
      {
        name: 'baby',
        keywords: ['emoji', 'baby', 'infant', 'child', 'cute', 'newborn', 'little'],
      },
      {
        name: 'backhand-index-pointing-down',
        keywords: ['emoji', 'backhand-index-pointing-down', 'finger', 'down', 'direction', 'point', 'hand'],
      },
      {
        name: 'backhand-index-pointing-left',
        keywords: ['emoji', 'backhand-index-pointing-left', 'finger', 'left', 'direction', 'point', 'hand'],
      },
      {
        name: 'backhand-index-pointing-right',
        keywords: ['emoji', 'backhand-index-pointing-right', 'finger', 'right', 'direction', 'point', 'hand'],
      },
      {
        name: 'backhand-index-pointing-up',
        keywords: ['emoji', 'backhand-index-pointing-up', 'finger', 'up', 'direction', 'point', 'hand'],
      },
      {
        name: 'basketball-winner',
        keywords: ['emoji', 'basketball-winner', 'sport', 'hoops', 'victory', 'champion', 'trophy'],
      },
      {
        name: 'businessman',
        keywords: ['emoji', 'businessman', 'suit', 'office', 'corporate', 'professional', 'work'],
      },
      {
        name: 'call-me-hand',
        keywords: ['emoji', 'call-me-hand', 'gesture', 'phone', 'hand', 'signal', 'communication'],
      },
      {
        name: 'casper-evil-ghost',
        keywords: ['emoji', 'casper-evil-ghost', 'spirit', 'halloween', 'haunt', 'spooky', 'phantom'],
      },
      {
        name: 'casper-ghost-halloween',
        keywords: ['emoji', 'casper-ghost-halloween', 'spirit', 'scary', 'costume', 'holiday', 'haunt'],
      },
      {
        name: 'centaur',
        keywords: ['emoji', 'centaur', 'mythology', 'horse-man', 'fantasy', 'creature', 'legend'],
      },
      {
        name: 'chef',
        keywords: ['emoji', 'chef', 'cook', 'kitchen', 'food', 'restaurant', 'culinary'],
      },
      {
        name: 'clapping-hands',
        keywords: ['emoji', 'clapping-hands', 'applause', 'congratulations', 'praise', 'appreciation', 'celebrate'],
      },
      {
        name: 'clown-halloween-it',
        keywords: ['emoji', 'clown-halloween-it', 'jester', 'scary', 'costume', 'circus', 'makeup'],
      },
      {
        name: 'confused',
        keywords: ['emoji', 'confused', 'perplexed', 'uncertain', 'puzzled', 'baffled', 'unsure'],
      },
      {
        name: 'cool',
        keywords: ['emoji', 'cool', 'sunglasses', 'chill', 'relaxed', 'trendy', 'confident'],
      },
      {
        name: 'crossed-fingers',
        keywords: ['emoji', 'crossed-fingers', 'hope', 'luck', 'wish', 'optimism', 'prayer'],
      },
      {
        name: 'cry(1)',
        keywords: ['emoji', 'cry(1)', 'sad', 'tears', 'emotion', 'sorrow', 'upset'],
      },
      {
        name: 'cry-a-river',
        keywords: ['emoji', 'cry-a-river', 'tears', 'sadness', 'flood', 'emotional', 'weeping'],
      },
      {
        name: 'dead-halloween-zombi',
        keywords: ['emoji', 'dead-halloween-zombi', 'undead', 'scary', 'horror', 'monster', 'creepy'],
      },
      {
        name: 'denist-male',
        keywords: ['emoji', 'denist-male', 'dentist', 'teeth', 'doctor', 'male', 'health'],
      },
      {
        name: 'dentist-female',
        keywords: ['emoji', 'dentist-female', 'teeth', 'doctor', 'female', 'oral', 'health'],
      },
      {
        name: 'destructive-magic',
        keywords: ['emoji', 'destructive-magic', 'spell', 'power', 'energy', 'fantasy', 'chaos'],
      },
      {
        name: 'devil',
        keywords: ['emoji', 'devil', 'demon', 'evil', 'horns', 'sinister', 'bad'],
      },
      {
        name: 'digital-nomad-man-dark',
        keywords: ['emoji', 'digital-nomad-man-dark', 'remote', 'travel', 'laptop', 'freelance', 'dark-skin'],
      },
      {
        name: 'digital-nomad-man-light',
        keywords: ['emoji', 'digital-nomad-man-light', 'remote', 'travel', 'laptop', 'freelance', 'light-skin'],
      },
      {
        name: 'digital-nomad-woman-dark',
        keywords: ['emoji', 'digital-nomad-woman-dark', 'remote', 'travel', 'laptop', 'freelance', 'dark-skin'],
      },
      {
        name: 'digital-nomad-woman-light',
        keywords: ['emoji', 'digital-nomad-woman-light', 'remote', 'travel', 'laptop', 'freelance', 'light-skin'],
      },
      {
        name: 'dracula-ghoul-helloween',
        keywords: ['emoji', 'dracula-ghoul-helloween', 'vampire', 'scary', 'blood', 'fangs', 'night'],
      },
      {
        name: 'dragon-egg',
        keywords: ['emoji', 'dragon-egg', 'fantasy', 'mythical', 'creature', 'hatch', 'scales'],
      },
      {
        name: 'ear-medium-light-skin-tone',
        keywords: ['emoji', 'ear-medium-light-skin-tone', 'hear', 'listening', 'body', 'organ', 'medium-light'],
      },
      {
        name: 'elf',
        keywords: ['emoji', 'elf', 'mythology', 'fantasy', 'pointy-ears', 'magic', 'forest'],
      },
      {
        name: 'expressionless',
        keywords: ['emoji', 'expressionless', 'blank', 'neutral', 'unemotional', 'flat', 'stoic'],
      },
      {
        name: 'eye',
        keywords: ['emoji', 'eye', 'vision', 'see', 'look', 'organ', 'watch'],
      },
      {
        name: 'fairy',
        keywords: ['emoji', 'fairy', 'magic', 'wings', 'fantasy', 'pixie', 'enchanted'],
      },
      {
        name: 'flag-holding-hand',
        keywords: ['emoji', 'flag-holding-hand', 'banner', 'country', 'symbol', 'patriotism', 'grip'],
      },
      {
        name: 'flexed-biceps',
        keywords: ['emoji', 'flexed-biceps', 'muscle', 'strength', 'arm', 'power', 'fit'],
      },
      {
        name: 'folded-hands',
        keywords: ['emoji', 'folded-hands', 'prayer', 'thanks', 'gratitude', 'hope', 'respect'],
      },
      {
        name: 'football-winner',
        keywords: ['emoji', 'football-winner', 'soccer', 'champion', 'goal', 'trophy', 'victory'],
      },
      {
        name: 'frankenstein-halloween-monster',
        keywords: ['emoji', 'frankenstein-halloween-monster', 'creature', 'green', 'scary', 'bolts', 'classic'],
      },
      {
        name: 'frankentein-monster',
        keywords: ['emoji', 'frankentein-monster', 'creature', 'green', 'stitched', 'halloween', 'scary'],
      },
      {
        name: 'friday-halloween-mask',
        keywords: ['emoji', 'friday-halloween-mask', 'hockey', 'scary', 'costume', 'horror', 'slasher'],
      },
      {
        name: 'girl-graduation-student',
        keywords: ['emoji', 'girl-graduation-student', 'female', 'education', 'cap', 'diploma', 'achievement'],
      },
      {
        name: 'girl-next-door',
        keywords: ['emoji', 'girl-next-door', 'neighbor', 'friendly', 'familiar', 'ordinary', 'charming'],
      },
      {
        name: 'girl-student-graduation-dance',
        keywords: ['emoji', 'girl-student-graduation-dance', 'female', 'celebration', 'cap', 'diploma', 'joy'],
      },
      {
        name: 'goblin',
        keywords: ['emoji', 'goblin', 'mythical', 'green', 'mischief', 'fantasy', 'creature'],
      },
      {
        name: 'graduation-student',
        keywords: ['emoji', 'graduation-student', 'education', 'cap', 'diploma', 'success', 'achievement'],
      },
      {
        name: 'graduation-student-hand-raised',
        keywords: ['emoji', 'graduation-student-hand-raised', 'education', 'question', 'learn', 'class', 'achievement'],
      },
      {
        name: 'grandfather',
        keywords: ['emoji', 'grandfather', 'elderly', 'family', 'old', 'relative', 'grandparent'],
      },
      {
        name: 'grim-reaper',
        keywords: ['emoji', 'grim-reaper', 'death', 'scythe', 'dark', 'soul', 'fear'],
      },
      {
        name: 'halloween-horror-pumpkin',
        keywords: [
          'emoji',
          'halloween-horror-pumpkin',
          'pumpkin',
          "jack-o'-lantern",
          'spooky',
          'fall',
          'scary',
          'candle',
        ],
      },
      {
        name: 'halloween-horror-skeleton-2',
        keywords: ['emoji', 'halloween-horror-skeleton-2', 'bones', 'spooky', 'scary', 'undead', 'costume'],
      },
      {
        name: 'halloween-pirate-skull',
        keywords: ['emoji', 'halloween-pirate-skull', 'bones', 'scary', 'pirate', 'flag', 'horror'],
      },
      {
        name: 'hand-with-fingers-splayed',
        keywords: ['emoji', 'hand-with-fingers-splayed', 'palm', 'gesture', 'open', 'spread', 'wave'],
      },
      {
        name: 'handshake',
        keywords: ['emoji', 'handshake', 'agreement', 'deal', 'greeting', 'partnership', 'meeting'],
      },
      {
        name: 'happy',
        keywords: ['emoji', 'happy', 'smile', 'joy', 'glad', 'cheerful', 'positive'],
      },
      {
        name: 'helmet',
        keywords: ['emoji', 'helmet', 'protection', 'headgear', 'safety', 'hardhat', 'armor'],
      },
      {
        name: 'hip-hop-boy',
        keywords: ['emoji', 'hip-hop-boy', 'dance', 'music', 'urban', 'youth', 'style'],
      },
      {
        name: 'hundredpointssymbol',
        keywords: ['emoji', 'hundredpointssymbol', '100', 'score', 'perfect', 'complete', 'full'],
      },
      {
        name: 'index-pointing-up',
        keywords: ['emoji', 'index-pointing-up', 'finger', 'up', 'direction', 'point', 'indicate'],
      },
      {
        name: 'indian',
        keywords: ['emoji', 'indian', 'culture', 'Asia', 'heritage', 'ethnicity', 'tradition'],
      },
      {
        name: 'injury',
        keywords: ['emoji', 'injury', 'hurt', 'wound', 'pain', 'accident', 'bandage'],
      },
      {
        name: 'jackolantern',
        keywords: ['emoji', 'jackolantern', 'halloween', 'pumpkin', 'spooky', 'candle', 'carve'],
      },
      {
        name: 'japaneseangrymask',
        keywords: ['emoji', 'japaneseangrymask', 'oni', 'demon', 'traditional', 'red', 'fierce'],
      },
      {
        name: 'japanesedolls',
        keywords: ['emoji', 'japanesedolls', 'hina', 'festival', 'culture', 'ornament', 'display'],
      },
      {
        name: 'joy',
        keywords: ['emoji', 'joy', 'laughter', 'smile', 'happiness', 'tears', 'delight'],
      },
      {
        name: 'karate-winner',
        keywords: ['emoji', 'karate-winner', 'martial-arts', 'fight', 'trophy', 'champion', 'combat'],
      },
      {
        name: 'king',
        keywords: ['emoji', 'king', 'royalty', 'crown', 'monarch', 'leader', 'regal'],
      },
      {
        name: 'kiss',
        keywords: ['emoji', 'kiss', 'love', 'affection', 'lips', 'romance', 'smooch'],
      },
      {
        name: 'kiss-heart',
        keywords: ['emoji', 'kiss-heart', 'love', 'affection', 'emoji', 'blow', 'romantic'],
      },
      {
        name: 'kiss-wink',
        keywords: ['emoji', 'kiss-wink', 'flirt', 'love', 'playful', 'tease', 'smirk'],
      },
      {
        name: 'knight',
        keywords: ['emoji', 'knight', 'armor', 'sword', 'chivalry', 'medieval', 'protection'],
      },
      {
        name: 'knight-medieval',
        keywords: ['emoji', 'knight-medieval', 'armor', 'feudal', 'horse', 'castle', 'honor'],
      },
      {
        name: 'landlady',
        keywords: ['emoji', 'landlady', 'property', 'owner', 'female', 'manager', 'rental'],
      },
      {
        name: 'lastquartermoonface',
        keywords: ['emoji', 'lastquartermoonface', 'moon', 'night', 'phase', 'celestial', 'face'],
      },
      {
        name: 'left-facing-fist',
        keywords: ['emoji', 'left-facing-fist', 'hand', 'punch', 'gesture', 'fistbump', 'left'],
      },
      {
        name: 'leg',
        keywords: ['emoji', 'leg', 'body', 'limb', 'walk', 'anatomy', 'support'],
      },
      {
        name: 'lipstick',
        keywords: ['emoji', 'lipstick', 'makeup', 'beauty', 'cosmetics', 'red', 'lips'],
      },
      {
        name: 'literary-uncle',
        keywords: ['emoji', 'literary-uncle', 'relative', 'family', 'bookish', 'wise', 'stories'],
      },
      {
        name: 'loli',
        keywords: ['emoji', 'loli', 'cute', 'young', 'anime', 'girl', 'innocent'],
      },
      {
        name: 'love-you-gesture',
        keywords: ['emoji', 'love-you-gesture', 'handsign', 'heart', 'affection', 'ASL', 'friendly'],
      },
      {
        name: 'man',
        keywords: ['emoji', 'man', 'male', 'adult', 'person', 'human', 'gentleman'],
      },
      {
        name: 'man-business',
        keywords: ['emoji', 'man-business', 'suit', 'office', 'work', 'corporate', 'professional'],
      },
      {
        name: 'manicure',
        keywords: ['emoji', 'manicure', 'nails', 'beauty', 'spa', 'grooming', 'hands'],
      },
      {
        name: 'mask',
        keywords: ['emoji', 'mask', 'face', 'cover', 'sick', 'illness', 'protection'],
      },
      {
        name: 'monster',
        keywords: ['emoji', 'monster', 'creature', 'scary', 'beast', 'mythical', 'fright'],
      },
      {
        name: 'motoracer-winner',
        keywords: ['emoji', 'motoracer-winner', 'bike', 'race', 'speed', 'champion', 'victory'],
      },
      {
        name: 'mouth',
        keywords: ['emoji', 'mouth', 'body', 'face', 'speak', 'taste', 'voice'],
      },
      {
        name: 'mouth(1)',
        keywords: ['emoji', 'mouth(1)', 'body', 'face', 'talk', 'eat', 'expression'],
      },
      {
        name: 'moyai',
        keywords: ['emoji', 'moyai', 'statue', 'Easter Island', 'stone', 'carving', 'monument'],
      },
      {
        name: 'mute',
        keywords: ['emoji', 'mute', 'silent', 'no-sound', 'quiet', 'audio-off', 'hush'],
      },
      {
        name: 'neutral',
        keywords: ['emoji', 'neutral', 'calm', 'blank', 'indifferent', 'unemotional', 'flat'],
      },
      {
        name: 'nogesture',
        keywords: ['emoji', 'nogesture', 'deny', 'no', 'stop', 'forbid', 'refuse'],
      },
      {
        name: 'nose',
        keywords: ['emoji', 'nose', 'smell', 'face', 'anatomy', 'breathe', 'organ'],
      },
      {
        name: 'og-nomad',
        keywords: ['emoji', 'og-nomad', 'original', 'travel', 'remote', 'wanderer', 'adventurer'],
      },
      {
        name: 'ogre',
        keywords: ['emoji', 'ogre', 'monster', 'green', 'mythology', 'shrek', 'ugly'],
      },
      {
        name: 'ok-hand',
        keywords: ['emoji', 'ok-hand', 'gesture', 'fine', 'approval', 'agree', 'sign'],
      },
      {
        name: 'okgesture',
        keywords: ['emoji', 'okgesture', 'agree', 'approval', 'fine', 'signal', 'hand'],
      },
      {
        name: 'oncoming-fist',
        keywords: ['emoji', 'oncoming-fist', 'punch', 'attack', 'fistbump', 'gesture', 'incoming'],
      },
      {
        name: 'open-hands',
        keywords: ['emoji', 'open-hands', 'welcome', 'hug', 'gesture', 'receptive', 'embrace'],
      },
      {
        name: 'orc',
        keywords: ['emoji', 'orc', 'fantasy', 'green', 'warrior', 'mythical', 'creature'],
      },
      {
        name: 'palms-up-together',
        keywords: ['emoji', 'palms-up-together', 'begging', 'asking', 'plea', 'hope', 'prayer'],
      },
      {
        name: 'performingarts',
        keywords: ['emoji', 'performingarts', 'theater', 'drama', 'stage', 'show', 'culture'],
      },
      {
        name: 'pinch-hand',
        keywords: ['emoji', 'pinch-hand', 'small', 'tiny', 'gesture', 'fingers', 'little'],
      },
      {
        name: 'pinched-fingers',
        keywords: ['emoji', 'pinched-fingers', 'Italian', 'gesture', 'frustration', 'question', 'hand'],
      },
      {
        name: 'podium-3-winners',
        keywords: ['emoji', 'podium-3-winners', 'competition', 'standings', 'places', 'ranking', 'awards'],
      },
      {
        name: 'poison-spider',
        keywords: ['emoji', 'poison-spider', 'venom', 'arachnid', 'danger', 'web', 'toxic'],
      },
      {
        name: 'poo',
        keywords: ['emoji', 'poo', 'excrement', 'funny', 'stinky', 'brown', 'smile'],
      },
      {
        name: 'pout',
        keywords: ['emoji', 'pout', 'upset', 'angry', 'sulk', 'frown', 'displeased'],
      },
      {
        name: 'pray',
        keywords: ['emoji', 'pray', 'worship', 'faith', 'religion', 'hope', 'thanks'],
      },
      {
        name: 'princess',
        keywords: ['emoji', 'princess', 'royalty', 'tiara', 'fairytale', 'regal', 'feminine'],
      },
      {
        name: 'princess-crown',
        keywords: ['emoji', 'princess-crown', 'royalty', 'tiara', 'regal', 'jewels', 'feminine'],
      },
      {
        name: 'quack',
        keywords: ['emoji', 'quack', 'duck', 'sound', 'bird', 'animal', 'farm'],
      },
      {
        name: 'raised-back-of-hand',
        keywords: ['emoji', 'raised-back-of-hand', 'gesture', 'hello', 'wave', 'hand', 'sign'],
      },
      {
        name: 'raised-fist',
        keywords: ['emoji', 'raised-fist', 'power', 'solidarity', 'protest', 'resist', 'strength'],
      },
      {
        name: 'raised-hand',
        keywords: ['emoji', 'raised-hand', 'stop', 'hi', 'greeting', 'halt', 'signal'],
      },
      {
        name: 'raising-hands',
        keywords: ['emoji', 'raising-hands', 'celebration', 'praise', 'excitement', 'joy', 'victory'],
      },
      {
        name: 'right-facing-fist',
        keywords: ['emoji', 'right-facing-fist', 'punch', 'fistbump', 'hand', 'gesture', 'right'],
      },
      {
        name: 'sad',
        keywords: ['emoji', 'sad', 'unhappy', 'blue', 'depressed', 'tearful', 'low'],
      },
      {
        name: 'sad-regret',
        keywords: ['emoji', 'sad-regret', 'apology', 'sorry', 'remorse', 'blue', 'mistake'],
      },
      {
        name: 'santa-claus',
        keywords: ['emoji', 'santa-claus', 'christmas', 'holiday', 'gifts', 'jolly', 'beard'],
      },
      {
        name: 'scared',
        keywords: ['emoji', 'scared', 'fear', 'frightened', 'shock', 'alarm', 'nervous'],
      },
      {
        name: 'scared-blue',
        keywords: ['emoji', 'scared-blue', 'fear', 'cold', 'shocked', 'frozen', 'surprised'],
      },
      {
        name: 'school-girl',
        keywords: ['emoji', 'school-girl', 'student', 'uniform', 'education', 'youth', 'class'],
      },
      {
        name: 'secret',
        keywords: ['emoji', 'secret', 'hush', 'whisper', 'private', 'confidential', 'quiet'],
      },
      {
        name: 'sick',
        keywords: ['emoji', 'sick', 'ill', 'nausea', 'unwell', 'green', 'virus'],
      },
      {
        name: 'sign-of-the-horns',
        keywords: ['emoji', 'sign-of-the-horns', 'rock', 'music', 'metal', 'concert', 'gesture'],
      },
      {
        name: 'skull',
        keywords: ['emoji', 'skull', 'death', 'bones', 'halloween', 'danger', 'gothic'],
      },
      {
        name: 'skull-front',
        keywords: ['emoji', 'skull-front', 'death', 'face', 'bones', 'danger', 'front-view'],
      },
      {
        name: 'skull-horror',
        keywords: ['emoji', 'skull-horror', 'death', 'scary', 'bones', 'fear', 'dark'],
      },
      {
        name: 'sleuthspy',
        keywords: ['emoji', 'sleuthspy', 'detective', 'investigate', 'mystery', 'spy', 'clue'],
      },
      {
        name: 'smile',
        keywords: ['emoji', 'smile', 'happy', 'cheerful', 'friendly', 'joyful', 'positive'],
      },
      {
        name: 'smile-closed-eye',
        keywords: ['emoji', 'smile-closed-eye', 'happy', 'content', 'pleased', 'relaxed', 'friendly'],
      },
      {
        name: 'sorceress-witch',
        keywords: ['emoji', 'sorceress-witch', 'magic', 'female', 'broomstick', 'halloween', 'spells'],
      },
      {
        name: 'sparkles',
        keywords: ['emoji', 'sparkles', 'glitter', 'shine', 'magic', 'twinkle', 'bright'],
      },
      {
        name: 'speaker3soundwaves',
        keywords: ['emoji', 'speaker3soundwaves', 'volume', 'sound', 'audio', 'loud', 'waves'],
      },
      {
        name: 'student',
        keywords: ['emoji', 'student', 'education', 'learning', 'school', 'books', 'class'],
      },
      {
        name: 'student-graduation-dance',
        keywords: ['emoji', 'student-graduation-dance', 'celebration', 'diploma', 'party', 'achievement', 'school'],
      },
      {
        name: 'student-graduating',
        keywords: ['emoji', 'student-graduating', 'education', 'diploma', 'cap', 'achievement', 'proud'],
      },
      {
        name: 'sunwithface',
        keywords: ['emoji', 'sunwithface', 'weather', 'bright', 'day', 'celestial', 'light'],
      },
      {
        name: 'sweat',
        keywords: ['emoji', 'sweat', 'nervous', 'hot', 'tired', 'anxious', 'exertion'],
      },
      {
        name: 'swimmer-winner',
        keywords: ['emoji', 'swimmer-winner', 'swim', 'pool', 'trophy', 'champion', 'sport'],
      },
      {
        name: 'thief',
        keywords: ['emoji', 'thief', 'robber', 'criminal', 'steal', 'bandit', 'mask'],
      },
      {
        name: 'thumbs-down',
        keywords: ['emoji', 'thumbs-down', 'disapproval', 'no', 'bad', 'reject', 'negative'],
      },
      {
        name: 'thumbs-up',
        keywords: ['emoji', 'thumbs-up', 'approval', 'good', 'yes', 'agree', 'positive'],
      },
      {
        name: 'tired',
        keywords: ['emoji', 'tired', 'exhausted', 'sleepy', 'weary', 'fatigue', 'drained'],
      },
      {
        name: 'tongue',
        keywords: ['emoji', 'tongue', 'taste', 'mouth', 'silly', 'playful', 'flavor'],
      },
      {
        name: 'tongue-closed-eye',
        keywords: ['emoji', 'tongue-closed-eye', 'silly', 'funny', 'tease', 'wacky', 'joke'],
      },
      {
        name: 'tongue-open-eye',
        keywords: ['emoji', 'tongue-open-eye', 'playful', 'goofy', 'tease', 'fun', 'wacky'],
      },
      {
        name: 'tourist',
        keywords: ['emoji', 'tourist', 'travel', 'camera', 'visit', 'vacation', 'sightseeing'],
      },
      {
        name: 'turban',
        keywords: ['emoji', 'turban', 'headwear', 'culture', 'traditional', 'clothing', 'ethnic'],
      },
      {
        name: 'two-winners-medal',
        keywords: ['emoji', 'two-winners-medal', 'double', 'victory', 'competition', 'awards', 'prize'],
      },
      {
        name: 'uncle',
        keywords: ['emoji', 'uncle', 'family', 'relative', 'male', 'older', 'kin'],
      },
      {
        name: 'unicorn',
        keywords: ['emoji', 'unicorn', 'fantasy', 'mythical', 'horn', 'magical', 'rainbow'],
      },
      {
        name: 'victory-hand',
        keywords: ['emoji', 'victory-hand', 'peace', 'V-sign', 'win', 'triumph', 'two'],
      },
      {
        name: 'viking',
        keywords: ['emoji', 'viking', 'warrior', 'Norse', 'helmet', 'beard', 'raider'],
      },
      {
        name: 'villager',
        keywords: ['emoji', 'villager', 'rural', 'community', 'peasant', 'settlement', 'commoner'],
      },
      {
        name: 'vomiting',
        keywords: ['emoji', 'vomiting', 'nausea', 'sick', 'gross', 'ill', 'disgust'],
      },
      {
        name: 'vulcan-salute',
        keywords: ['emoji', 'vulcan-salute', 'Spock', 'Star Trek', 'live long', 'prosper', 'hand'],
      },
      {
        name: 'waitress',
        keywords: ['emoji', 'waitress', 'server', 'restaurant', 'food', 'female', 'service'],
      },
      {
        name: 'walking',
        keywords: ['emoji', 'walking', 'stroll', 'move', 'step', 'exercise', 'go'],
      },
      {
        name: 'waving-hand',
        keywords: ['emoji', 'waving-hand', 'hello', 'greeting', 'wave', 'farewell', 'gesture'],
      },
      {
        name: 'weightlifter',
        keywords: ['emoji', 'weightlifter', 'gym', 'strong', 'fitness', 'muscle', 'barbell'],
      },
      {
        name: 'werewolf',
        keywords: ['emoji', 'werewolf', 'moon', 'monster', 'wolf', 'horror', 'transform'],
      },
      {
        name: 'wink',
        keywords: ['emoji', 'wink', 'flirt', 'playful', 'tease', 'friendly', 'secret'],
      },
      {
        name: 'woman',
        keywords: ['emoji', 'woman', 'female', 'person', 'adult', 'human', 'lady'],
      },
      {
        name: 'worker-construction',
        keywords: ['emoji', 'worker-construction', 'helmet', 'build', 'construction', 'labor', 'safety'],
      },
      {
        name: 'writing-hand',
        keywords: ['emoji', 'writing-hand', 'pen', 'note', 'letter', 'record', 'journal'],
      },
      {
        name: 'zombie',
        keywords: ['emoji', 'zombie', 'undead', 'horror', 'apocalypse', 'scary', 'creature'],
      },
      {
        name: 'zombie-horror',
        keywords: ['emoji', 'zombie-horror', 'undead', 'scary', 'infected', 'monster', 'fear'],
      },
    ],
  },
  {
    name: 'animals',
    icons: [
      {
        name: 'alligator',
        keywords: ['animals', 'alligator', 'reptile', 'carnivore', 'swamp', 'green', 'scales'],
      },
      {
        name: 'baby-chicken',
        keywords: ['animals', 'bird', 'chick', 'farm', 'yellow', 'cute'],
      },
      {
        name: 'bald-eagle-face',
        keywords: ['animals', 'bird', 'eagle', 'raptor', 'patriotic', 'soaring'],
      },
      {
        name: 'bat-blood-halloween',
        keywords: ['animals', 'mammal', 'bat', 'halloween', 'nocturnal', 'vampire'],
      },
      {
        name: 'bear-face',
        keywords: ['animals', 'mammal', 'bear', 'forest', 'wild', 'predator'],
      },
      {
        name: 'bear-kodiak',
        keywords: ['animals', 'mammal', 'bear', 'kodiak', 'brown', 'giant'],
      },
      {
        name: 'bird',
        keywords: ['animals', 'bird', 'feather', 'flying', 'tweet', 'sky'],
      },
      {
        name: 'boar-face',
        keywords: ['animals', 'mammal', 'boar', 'wild', 'tusk', 'forest'],
      },
      {
        name: 'bull',
        keywords: ['animals', 'mammal', 'bull', 'horns', 'farm', 'strong'],
      },
      {
        name: 'bull-face',
        keywords: ['animals', 'mammal', 'bull', 'anger', 'horns', 'male'],
      },
      {
        name: 'bunny-face',
        keywords: ['animals', 'mammal', 'rabbit', 'cute', 'ears', 'hop'],
      },
      {
        name: 'camel',
        keywords: ['animals', 'mammal', 'camel', 'desert', 'hump', 'dromedary'],
      },
      {
        name: 'cat',
        keywords: ['animals', 'mammal', 'cat', 'pet', 'feline', 'whiskers'],
      },
      {
        name: 'cat-face',
        keywords: ['animals', 'mammal', 'cat', 'pet', 'feline', 'whiskers'],
      },
      {
        name: 'cat-face-2',
        keywords: ['animals', 'mammal', 'cat', 'pet', 'feline', 'whiskers'],
      },
      {
        name: 'cat-halloween',
        keywords: ['animals', 'mammal', 'cat', 'halloween', 'black', 'spooky'],
      },
      {
        name: 'caterpillar-worm',
        keywords: ['animals', 'insect', 'caterpillar', 'worm', 'green', 'crawl'],
      },
      {
        name: 'chicken',
        keywords: ['animals', 'bird', 'chicken', 'farm', 'hen', 'eggs'],
      },
      {
        name: 'cow',
        keywords: ['animals', 'mammal', 'cow', 'farm', 'milk', 'cattle'],
      },
      {
        name: 'cow-face',
        keywords: ['animals', 'mammal', 'cow', 'face', 'farm', 'milk'],
      },
      {
        name: 'cow-face-2',
        keywords: ['animals', 'mammal', 'cow', 'face', 'farm', 'pasture'],
      },
      {
        name: 'crab',
        keywords: ['animals', 'crustacean', 'crab', 'ocean', 'claws', 'beach'],
      },
      {
        name: 'deer-face-antlers',
        keywords: ['animals', 'mammal', 'deer', 'antlers', 'forest', 'graceful'],
      },
      {
        name: 'deer-spots-antlers',
        keywords: ['animals', 'mammal', 'deer', 'antlers', 'spotted', 'forest'],
      },
      {
        name: 'dinosaur',
        keywords: ['animals', 'reptile', 'dinosaur', 'extinct', 'giant', 'fossil'],
      },
      {
        name: 'dinosaur-1',
        keywords: ['animals', 'reptile', 'dinosaur', 'extinct', 'ancient', 'jurassic'],
      },
      {
        name: 'dog-breed',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(1)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(10)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(11)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(12)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(13)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(14)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(15)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(16)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(17)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(18)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(19)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(2)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(20)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(21)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(22)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(23)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(24)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(25)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(26)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(27)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(28)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(29)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(3)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(30)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(4)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(5)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(6)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(7)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(8)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-breed(9)',
        keywords: ['animals', 'mammal', 'dog', 'breed', 'pet', 'canine'],
      },
      {
        name: 'dog-face',
        keywords: ['animals', 'mammal', 'dog', 'face', 'pet', 'loyal'],
      },
      {
        name: 'dolphin',
        keywords: ['animals', 'mammal', 'dolphin', 'ocean', 'intelligent', 'whale-family'],
      },
      {
        name: 'elephant-face',
        keywords: ['animals', 'mammal', 'elephant', 'face', 'trunk', 'huge'],
      },
      {
        name: 'fish-1',
        keywords: ['animals', 'fish', 'swim', 'aquatic', 'ocean', 'river'],
      },
      {
        name: 'fish-2',
        keywords: ['animals', 'fish', 'swim', 'aquatic', 'sea', 'marine'],
      },
      {
        name: 'fish-tropical',
        keywords: ['animals', 'fish', 'tropical', 'colorful', 'reef', 'exotic'],
      },
      {
        name: 'blowfish',
        keywords: ['animals', 'fish', 'puffer', 'ocean', 'spiky', 'poisonous'],
      },
      {
        name: 'small-fish',
        keywords: ['animals', 'fish', 'small', 'tiny', 'aquatic', 'school'],
      },
      {
        name: 'fox-face',
        keywords: ['animals', 'mammal', 'fox', 'face', 'wild', 'clever'],
      },
      {
        name: 'fox-face-2',
        keywords: ['animals', 'mammal', 'fox', 'face', 'sly', 'red'],
      },
      {
        name: 'giraffe',
        keywords: ['animals', 'mammal', 'giraffe', 'tall', 'savanna', 'spots'],
      },
      {
        name: 'goat',
        keywords: ['animals', 'mammal', 'goat', 'farm', 'horns', 'milk'],
      },
      {
        name: 'goat',
        keywords: ['animals', 'mammal', 'goat', 'farm', 'horns', 'beard'],
      },
      {
        name: 'goat-face',
        keywords: ['animals', 'mammal', 'goat', 'face', 'farm', 'bleat'],
      },
      {
        name: 'gorilla',
        keywords: ['animals', 'mammal', 'gorilla', 'ape', 'jungle', 'strong'],
      },
      {
        name: 'gorilla-face',
        keywords: ['animals', 'mammal', 'gorilla', 'face', 'primate', 'forest'],
      },
      {
        name: 'hampster-face',
        keywords: ['animals', 'mammal', 'hamster', 'face', 'rodent', 'cute'],
      },
      {
        name: 'hippo-face',
        keywords: ['animals', 'mammal', 'hippo', 'face', 'water', 'africa'],
      },
      {
        name: 'honeybee',
        keywords: ['animals', 'insect', 'bee', 'honey', 'pollinator', 'wings'],
      },
      {
        name: 'horse',
        keywords: ['animals', 'mammal', 'horse', 'farm', 'ride', 'mane'],
      },
      {
        name: 'horse',
        keywords: ['animals', 'mammal', 'horse', 'stallion', 'ranch', 'hoofed'],
      },
      {
        name: 'horse-face',
        keywords: ['animals', 'mammal', 'horse', 'face', 'equine', 'graceful'],
      },
      {
        name: 'horse-face-2',
        keywords: ['animals', 'mammal', 'horse', 'face', 'steed', 'brown'],
      },
      {
        name: 'koala-face',
        keywords: ['animals', 'mammal', 'koala', 'face', 'australia', 'eucalyptus'],
      },
      {
        name: 'koala-face-2',
        keywords: ['animals', 'mammal', 'koala', 'face', 'cuddly', 'marsupial'],
      },
      {
        name: 'lady-bug',
        keywords: ['animals', 'insect', 'ladybug', 'red', 'spots', 'garden'],
      },
      {
        name: 'leopard',
        keywords: ['animals', 'mammal', 'leopard', 'spots', 'predator', 'jungle'],
      },
      {
        name: 'lion-face',
        keywords: ['animals', 'mammal', 'lion', 'face', 'king', 'pride'],
      },
      {
        name: 'lion-face-2',
        keywords: ['animals', 'mammal', 'lion', 'face', 'africa', 'mane'],
      },
      {
        name: 'lizard',
        keywords: ['animals', 'reptile', 'lizard', 'scales', 'sun', 'crawl'],
      },
      {
        name: 'monkey',
        keywords: ['animals', 'mammal', 'monkey', 'jungle', 'banana', 'climb'],
      },
      {
        name: 'monkey-face',
        keywords: ['animals', 'mammal', 'monkey', 'face', 'primate', 'playful'],
      },
      {
        name: 'monkey-face-2',
        keywords: ['animals', 'mammal', 'monkey', 'face', 'tree', 'curious'],
      },
      {
        name: 'monkeyface',
        keywords: ['animals', 'mammal', 'monkey', 'face', 'emoji', 'funny'],
      },
      {
        name: 'monkeyhear',
        keywords: ['animals', 'mammal', 'monkey', 'hear', 'emoji', 'covering-ears'],
      },
      {
        name: 'monkeysee',
        keywords: ['animals', 'mammal', 'monkey', 'see', 'emoji', 'covering-eyes'],
      },
      {
        name: 'monkeyspeak',
        keywords: ['animals', 'mammal', 'monkey', 'speak', 'emoji', 'covering-mouth'],
      },
      {
        name: 'mouse-face',
        keywords: ['animals', 'mammal', 'mouse', 'face', 'rodent', 'small'],
      },
      {
        name: 'mouseside',
        keywords: ['animals', 'mammal', 'mouse', 'side', 'rodent', 'profile'],
      },
      {
        name: 'octopus',
        keywords: ['animals', 'marine', 'octopus', 'tentacles', 'ocean', 'intelligent'],
      },
      {
        name: 'octopus-yellow',
        keywords: ['animals', 'marine', 'octopus', 'yellow', 'tentacles', 'sea'],
      },
      {
        name: 'owl-bird',
        keywords: ['animals', 'bird', 'owl', 'nocturnal', 'wise', 'forest'],
      },
      {
        name: 'owl-halloween',
        keywords: ['animals', 'bird', 'owl', 'halloween', 'spooky', 'night'],
      },
      {
        name: 'ox',
        keywords: ['animals', 'mammal', 'ox', 'farm', 'strong', 'draft'],
      },
      {
        name: 'panda',
        keywords: ['animals', 'mammal', 'panda', 'bear', 'bamboo', 'china'],
      },
      {
        name: 'panda-face',
        keywords: ['animals', 'mammal', 'panda', 'face', 'black-white', 'cute'],
      },
      {
        name: 'panda-face-2',
        keywords: ['animals', 'mammal', 'panda', 'face', 'bamboo', 'endearing'],
      },
      {
        name: 'paw-prints',
        keywords: ['animals', 'tracks', 'paw', 'prints', 'footprint', 'pet'],
      },
      {
        name: 'penguin',
        keywords: ['animals', 'bird', 'penguin', 'antarctic', 'waddle', 'black-white'],
      },
      {
        name: 'pig',
        keywords: ['animals', 'mammal', 'pig', 'farm', 'oink', 'pink'],
      },
      {
        name: 'pig',
        keywords: ['animals', 'mammal', 'pig', 'swine', 'farm', 'hog'],
      },
      {
        name: 'pig-face',
        keywords: ['animals', 'mammal', 'pig', 'face', 'snout', 'pink'],
      },
      {
        name: 'pig-face-2',
        keywords: ['animals', 'mammal', 'pig', 'face', 'farm', 'cute'],
      },
      {
        name: 'pig-snout',
        keywords: ['animals', 'mammal', 'pig', 'snout', 'farm', 'oink'],
      },
      {
        name: 'poodle',
        keywords: ['animals', 'mammal', 'dog', 'poodle', 'pet', 'fancy'],
      },
      {
        name: 'ram',
        keywords: ['animals', 'mammal', 'ram', 'sheep', 'horns', 'mountain'],
      },
      {
        name: 'rat',
        keywords: ['animals', 'mammal', 'rat', 'rodent', 'urban', 'pest'],
      },
      {
        name: 'raven-bird',
        keywords: ['animals', 'bird', 'raven', 'black', 'intelligent', 'ominous'],
      },
      {
        name: 'reindeer-rudolph',
        keywords: ['animals', 'mammal', 'reindeer', 'rudolph', 'red-nose', 'christmas'],
      },
      {
        name: 'rhino-face',
        keywords: ['animals', 'mammal', 'rhino', 'face', 'horn', 'africa'],
      },
      {
        name: 'rooster',
        keywords: ['animals', 'bird', 'rooster', 'farm', 'cock-a-doodle', 'comb'],
      },
      {
        name: 'shell-conch',
        keywords: ['animals', 'marine', 'conch', 'shell', 'beach', 'spiral'],
      },
      {
        name: 'sheep',
        keywords: ['animals', 'mammal', 'sheep', 'wool', 'farm', 'pasture'],
      },
      {
        name: 'sheep-3',
        keywords: ['animals', 'mammal', 'sheep', 'wool', 'farm', 'bleat'],
      },
      {
        name: 'sheep-1',
        keywords: ['animals', 'mammal', 'sheep', 'wool', 'lamb', 'docile'],
      },
      {
        name: 'sheep-2',
        keywords: ['animals', 'mammal', 'sheep', 'wool', 'graze', 'herd'],
      },

      {
        name: 'snail',
        keywords: ['animals', 'mollusk', 'snail', 'shell', 'slow', 'garden'],
      },
      {
        name: 'snake',
        keywords: ['animals', 'reptile', 'snake', 'slither', 'venom', 'jungle'],
      },
      {
        name: 'snake-face',
        keywords: ['animals', 'reptile', 'snake', 'face', 'scales', 'forked-tongue'],
      },
      {
        name: 'spider',
        keywords: ['animals', 'arachnid', 'spider', 'web', 'eight-legs', 'creepy'],
      },
      {
        name: 'spider-danger',
        keywords: ['animals', 'arachnid', 'spider', 'danger', 'venom', 'scary'],
      },
      {
        name: 'spiderweb',
        keywords: ['animals', 'arachnid', 'spider', 'web', 'silk', 'trap'],
      },
      {
        name: 'tiger',
        keywords: ['animals', 'mammal', 'tiger', 'stripes', 'predator', 'asia'],
      },
      {
        name: 'tiger',
        keywords: ['animals', 'mammal', 'tiger', 'stripe', 'big-cat', 'fierce'],
      },
      {
        name: 'tiger-face',
        keywords: ['animals', 'mammal', 'tiger', 'face', 'striped', 'wildcat'],
      },
      {
        name: 'tigerside',
        keywords: ['animals', 'mammal', 'tiger', 'side', 'stripe', 'profile'],
      },

      {
        name: 'turtle',
        keywords: ['animals', 'reptile', 'turtle', 'shell', 'slow', 'aquatic'],
      },
      {
        name: 'walrus',
        keywords: ['animals', 'mammal', 'walrus', 'tusk', 'arctic', 'blubber'],
      },
      {
        name: 'walrus-face',
        keywords: ['animals', 'mammal', 'walrus', 'face', 'tusk', 'cold'],
      },
      {
        name: 'waterbuffalo',
        keywords: ['animals', 'mammal', 'buffalo', 'water', 'horns', 'asia'],
      },
      {
        name: 'whale',
        keywords: ['animals', 'mammal', 'whale', 'ocean', 'giant', 'marine'],
      },
      {
        name: 'whale-2',
        keywords: ['animals', 'mammal', 'whale', 'ocean', 'large', 'blue'],
      },
      {
        name: 'wolf-face',
        keywords: ['animals', 'mammal', 'wolf', 'face', 'pack', 'howl'],
      },
      {
        name: 'wolf-face-2',
        keywords: ['animals', 'mammal', 'wolf', 'face', 'wild', 'fierce'],
      },
    ],
  },
  {
    name: 'technology',
    icons: [
      {
        name: 'arcade',
        keywords: ['technology', 'arcade', 'gaming', 'retro', 'machine', 'coins', 'entertainment'],
      },
      {
        name: 'bank-banking-budget',
        keywords: ['technology', 'bank', 'banking', 'budget', 'finance', 'money', 'transactions', 'economy'],
      },
      {
        name: 'battery',
        keywords: ['technology', 'battery', 'power', 'energy', 'portable', 'charge', 'device'],
      },
      {
        name: 'blackhardshellfloppy',
        keywords: [
          'technology',
          'blackhardshellfloppy',
          'black',
          'hard',
          'shell',
          'floppy',
          'disk',
          'storage',
          'old',
          'data',
        ],
      },
      {
        name: 'board',
        keywords: ['technology', 'board', 'circuit', 'panel', 'electronics', 'component', 'hardware'],
      },
      {
        name: 'cam-camera-device',
        keywords: ['technology', 'cam', 'camera', 'device', 'record', 'video', 'photo', 'lens', 'capture'],
      },
      {
        name: 'camera',
        keywords: ['technology', 'camera', 'photography', 'lens', 'capture', 'video', 'record'],
      },
      {
        name: 'cassette',
        keywords: ['technology', 'cassette', 'tape', 'audio', 'music', 'retro', 'record'],
      },
      {
        name: 'cd',
        keywords: ['technology', 'cd', 'compact', 'disc', 'audio', 'data', 'storage'],
      },
      {
        name: 'cellphone',
        keywords: ['technology', 'cellphone', 'mobile', 'phone', 'call', 'smartphone', 'portable'],
      },
      {
        name: 'computer',
        keywords: ['technology', 'computer', 'pc', 'device', 'processing', 'electronic', 'machine'],
      },
      {
        name: 'computer-mouse',
        keywords: ['technology', 'computer', 'mouse', 'input', 'pointer', 'click', 'navigation'],
      },
      {
        name: 'computer-speaker-device',
        keywords: ['technology', 'computer', 'speaker', 'device', 'audio', 'sound', 'music', 'volume', 'output'],
      },
      {
        name: 'dark-disk-drive',
        keywords: ['technology', 'dark', 'disk', 'drive', 'storage', 'computer', 'data', 'magnetic', 'device'],
      },
      {
        name: 'device-business-computer',
        keywords: [
          'technology',
          'device',
          'business',
          'computer',
          'office',
          'tool',
          'digital',
          'workstation',
          'electronics',
        ],
      },
      {
        name: 'digital-tablet',
        keywords: ['technology', 'digital', 'tablet', 'touchscreen', 'portable', 'app', 'reading', 'web'],
      },
      {
        name: 'earphone',
        keywords: ['technology', 'earphone', 'audio', 'sound', 'headphone', 'music', 'earbuds'],
      },
      {
        name: 'floppydisk',
        keywords: ['technology', 'floppydisk', 'floppy', 'disk', 'storage', 'old', 'data', 'legacy'],
      },
      {
        name: 'game-console',
        keywords: ['technology', 'game', 'console', 'video', 'gaming', 'play', 'entertainment', 'controller'],
      },
      {
        name: 'game-controller',
        keywords: ['technology', 'game', 'controller', 'video', 'gaming', 'joystick', 'buttons', 'play'],
      },
      {
        name: 'headset',
        keywords: ['technology', 'headset', 'audio', 'microphone', 'communication', 'sound', 'gaming'],
      },
      {
        name: 'ipod-nano',
        keywords: ['technology', 'ipod', 'nano', 'music', 'audio', 'portable', 'mp3', 'player'],
      },
      {
        name: 'keyboard',
        keywords: ['technology', 'keyboard', 'typing', 'input', 'keys', 'computer', 'hardware'],
      },
      {
        name: 'keyboardandmouse',
        keywords: [
          'technology',
          'keyboardandmouse',
          'keyboard',
          'and',
          'mouse',
          'input',
          'device',
          'pointer',
          'typing',
        ],
      },
      {
        name: 'laptop',
        keywords: ['technology', 'laptop', 'portable', 'notebook', 'computer', 'mobile', 'work'],
      },
      {
        name: 'levelslider',
        keywords: ['technology', 'levelslider', 'level', 'slider', 'control', 'volume', 'adjust', 'interface'],
      },
      {
        name: 'lightbulb',
        keywords: ['technology', 'lightbulb', 'light', 'bulb', 'idea', 'lamp', 'bright', 'electric'],
      },
      {
        name: 'meridianglobe',
        keywords: ['technology', 'meridianglobe', 'meridian', 'globe', 'earth', 'map', 'axis', 'navigation'],
      },
      {
        name: 'microphone',
        keywords: ['technology', 'microphone', 'audio', 'record', 'sound', 'voice', 'speech'],
      },
      {
        name: 'minidisc',
        keywords: ['technology', 'minidisc', 'mini', 'disc', 'storage', 'audio', 'optical', 'media'],
      },
      {
        name: 'moviecamera',
        keywords: ['technology', 'moviecamera', 'movie', 'camera', 'film', 'record', 'video', 'lens'],
      },
      {
        name: 'oldpersonalcomputer',
        keywords: ['technology', 'old', 'personal', 'computer', 'vintage', 'pc', 'retro', 'machine', 'desk'],
      },
      {
        name: 'opticaldiscicon',
        keywords: ['technology', 'optical', 'disc', 'icon', 'cd', 'dvd', 'data', 'media', 'storage'],
      },
      {
        name: 'overlap',
        keywords: ['technology', 'overlap', 'layers', 'intersect', 'graphics', 'design', 'combine'],
      },
      {
        name: 'pager',
        keywords: ['technology', 'pager', 'message', 'alert', 'wireless', 'beeper', 'communication'],
      },
      {
        name: 'personal-console',
        keywords: ['technology', 'personal', 'console', 'gaming', 'portable', 'video', 'play', 'entertainment'],
      },
      {
        name: 'personalcomputer',
        keywords: ['technology', 'personal', 'computer', 'pc', 'desktop', 'home', 'device', 'machine'],
      },
      {
        name: 'phone',
        keywords: ['technology', 'phone', 'call', 'mobile', 'dial', 'handset', 'communication'],
      },
      {
        name: 'portablestereo',
        keywords: ['technology', 'portable', 'stereo', 'music', 'sound', 'radio', 'speakers', 'boombox'],
      },
      {
        name: 'power-outlet-plug',
        keywords: ['technology', 'power', 'outlet', 'plug', 'electric', 'socket', 'energy', 'adapter', 'wall'],
      },
      {
        name: 'printer',
        keywords: ['technology', 'printer', 'print', 'paper', 'ink', 'document', 'office'],
      },
      {
        name: 'printericon',
        keywords: ['technology', 'printer', 'icon', 'symbol', 'print', 'document', 'computer', 'ui'],
      },
      {
        name: 'radio',
        keywords: ['technology', 'radio', 'broadcast', 'fm', 'am', 'audio', 'signal'],
      },
      {
        name: 'recording',
        keywords: ['technology', 'recording', 'record', 'audio', 'video', 'capture', 'media', 'save'],
      },
      {
        name: 'remote-control-settings',
        keywords: ['technology', 'remote', 'control', 'settings', 'tv', 'buttons', 'wireless', 'adjust', 'device'],
      },
      {
        name: 'satelliteantenna',
        keywords: ['technology', 'satellite', 'antenna', 'signal', 'broadcast', 'space', 'reception', 'communication'],
      },
      {
        name: 'softshellfloppy',
        keywords: ['technology', 'softshell', 'floppy', 'disk', 'storage', 'old', 'magnetic', 'legacy'],
      },
      {
        name: 'studiomic',
        keywords: ['technology', 'studio', 'mic', 'microphone', 'audio', 'record', 'broadcast', 'sound', 'music'],
      },
      {
        name: 'tapecartridge',
        keywords: ['technology', 'tape', 'cartridge', 'storage', 'audio', 'cassette', 'old', 'data'],
      },
      {
        name: 'telephoneonmodem',
        keywords: ['technology', 'telephone', 'on', 'modem', 'dialup', 'internet', 'connection', 'old', 'line'],
      },
      {
        name: 'television',
        keywords: ['technology', 'television', 'tv', 'screen', 'shows', 'video', 'broadcast'],
      },
      {
        name: 'think-creative-thinking',
        keywords: [
          'technology',
          'think',
          'creative',
          'thinking',
          'idea',
          'innovate',
          'brainstorm',
          'concept',
          'imagine',
        ],
      },
      {
        name: 'threebuttonmouse',
        keywords: ['technology', 'three', 'button', 'mouse', 'input', 'device', 'click', 'computer', 'pointer'],
      },
      {
        name: 'twobuttonmouse',
        keywords: ['technology', 'two', 'button', 'mouse', 'input', 'device', 'click', 'computer', 'pointer'],
      },
      {
        name: 'usb-memory',
        keywords: ['technology', 'usb', 'memory', 'flash', 'drive', 'storage', 'portable', 'data'],
      },
      {
        name: 'vhs-tape',
        keywords: ['technology', 'vhs', 'tape', 'video', 'cassette', 'record', 'playback', 'old'],
      },
      {
        name: 'videocasette',
        keywords: ['technology', 'video', 'casette', 'tape', 'vhs', 'record', 'playback', 'old'],
      },
      {
        name: 'videogame',
        keywords: ['technology', 'video', 'game', 'console', 'fun', 'interactive', 'digital', 'entertainment'],
      },
      {
        name: 'wiredkeyboard',
        keywords: ['technology', 'wired', 'keyboard', 'typing', 'input', 'usb', 'hardware', 'keys'],
      },
    ],
  },
  {
    name: 'furniture',
    icons: [
      {
        name: 'adornment-furniture',
        keywords: ['furniture', 'adornment', 'furniture', 'decor', 'home', 'ornament', 'style', 'interior'],
      },
      {
        name: 'adornment-rug',
        keywords: ['furniture', 'adornment', 'rug', 'decor', 'floor', 'carpet', 'style', 'interior'],
      },
      {
        name: 'antique-furniture',
        keywords: ['furniture', 'antique', 'furniture', 'vintage', 'old', 'collectible', 'classic', 'decor'],
      },
      {
        name: 'antique-seat',
        keywords: ['furniture', 'antique', 'seat', 'vintage', 'old', 'classic', 'chair', 'decor'],
      },
      {
        name: 'armchair',
        keywords: ['furniture', 'armchair', 'chair', 'comfortable', 'cushioned', 'seating', 'livingroom', 'rest'],
      },
      {
        name: 'armchair-chair',
        keywords: ['furniture', 'armchair', 'chair', 'comfortable', 'cushioned', 'seating', 'livingroom', 'rest'],
      },
      {
        name: 'armchair-chair-1',
        keywords: ['furniture', 'armchair', 'chair', '1', 'comfortable', 'cushion', 'seating', 'livingroom', 'rest'],
      },
      {
        name: 'bath-tub',
        keywords: ['furniture', 'bath', 'tub', 'bathroom', 'water', 'soak', 'relax', 'cleaning'],
      },
      {
        name: 'bed-double',
        keywords: ['furniture', 'bed', 'double', 'sleep', 'bedroom', 'mattress', 'rest', 'furniture'],
      },
      {
        name: 'bed-side-desk',
        keywords: ['furniture', 'bed', 'side', 'desk', 'bedroom', 'nightstand', 'surface', 'lamp', 'storage'],
      },
      {
        name: 'bed-single',
        keywords: ['furniture', 'bed', 'single', 'sleep', 'bedroom', 'mattress', 'rest', 'compact'],
      },
      {
        name: 'bedroom-drawer',
        keywords: ['furniture', 'bedroom', 'drawer', 'storage', 'clothes', 'chest', 'organize', 'furniture'],
      },
      {
        name: 'bedroom-stage',
        keywords: ['furniture', 'bedroom', 'stage', 'platform', 'interior', 'decor', 'floor', 'structure'],
      },
      {
        name: 'bedroom-table',
        keywords: ['furniture', 'bedroom', 'table', 'desk', 'surface', 'lamp', 'bedside', 'furniture'],
      },
      {
        name: 'bedroom-table-1',
        keywords: ['furniture', 'bedroom', 'table', '1', 'desk', 'surface', 'lamp', 'bedside', 'furniture'],
      },
      {
        name: 'bedside-table-drawer',
        keywords: ['furniture', 'bedside', 'table', 'drawer', 'nightstand', 'storage', 'lamp', 'bedroom', 'furniture'],
      },
      {
        name: 'bookcase-bookshelf',
        keywords: ['furniture', 'bookcase', 'bookshelf', 'storage', 'books', 'shelves', 'library', 'organize', 'wood'],
      },
      {
        name: 'chair-dining',
        keywords: ['furniture', 'chair', 'dining', 'seat', 'table', 'meals', 'kitchen', 'wood', 'comfortable'],
      },
      {
        name: 'chair-office',
        keywords: ['furniture', 'chair', 'office', 'seat', 'work', 'desk', 'computer', 'wheels', 'ergonomic'],
      },
      {
        name: 'chairs-room',
        keywords: [
          'furniture',
          'chairs',
          'room',
          'seating',
          'multiple',
          'gathering',
          'furniture',
          'interior',
          'arrange',
        ],
      },
      {
        name: 'cinema-seat',
        keywords: ['furniture', 'cinema', 'seat', 'theater', 'comfortable', 'movie', 'audience', 'cushioned', 'rows'],
      },
      {
        name: 'drawer-and-document',
        keywords: ['furniture', 'drawer', 'and', 'document', 'storage', 'files', 'organize', 'papers', 'cabinet'],
      },
      {
        name: 'dressing-table-dresser',
        keywords: ['furniture', 'dressing', 'table', 'dresser', 'bedroom', 'mirror', 'vanity', 'storage', 'beauty'],
      },
      {
        name: 'dressing-table-dresser-1',
        keywords: [
          'furniture',
          'dressing',
          'table',
          'dresser',
          '1',
          'bedroom',
          'mirror',
          'vanity',
          'storage',
          'beauty',
        ],
      },
      {
        name: 'icon',
        keywords: ['furniture', 'icon', 'symbol', 'graphic', 'emblem', 'ui', 'design', 'small', 'represent'],
      },
      {
        name: 'lamp',
        keywords: ['furniture', 'lamp', 'light', 'fixture', 'table', 'illuminate', 'decor', 'bulb'],
      },
      {
        name: 'lamp-1',
        keywords: ['furniture', 'lamp', '1', 'light', 'fixture', 'table', 'illuminate', 'decor', 'bulb'],
      },
      {
        name: 'light-office-room',
        keywords: ['furniture', 'light', 'office', 'room', 'lighting', 'bright', 'ceiling', 'workspace', 'lamp'],
      },
      {
        name: 'livingroom',
        keywords: ['furniture', 'livingroom', 'lounge', 'interior', 'sofa', 'relax', 'gathering', 'home'],
      },
      {
        name: 'livingroom-chair',
        keywords: [
          'furniture',
          'livingroom',
          'chair',
          'seating',
          'comfortable',
          'sofa',
          'armchair',
          'home',
          'interior',
        ],
      },
      {
        name: 'livingroom-furniture',
        keywords: ['furniture', 'livingroom', 'furniture', 'sofa', 'chairs', 'table', 'decor', 'home', 'set'],
      },
      {
        name: 'manltepiececlock',
        keywords: [
          'furniture',
          'manltepiececlock',
          'mantlepiece',
          'clock',
          'time',
          'fireplace',
          'decor',
          'antique',
          'display',
        ],
      },
      {
        name: 'nightstand-2',
        keywords: ['furniture', 'nightstand', 'bedside', 'table', 'lamp', 'storage', 'bedroom', 'furniture'],
      },
      {
        name: 'nightstand-1',
        keywords: ['furniture', 'nightstand', '1', 'bedside', 'table', 'lamp', 'storage', 'bedroom', 'furniture'],
      },
      {
        name: 'office-chair',
        keywords: ['furniture', 'office', 'chair', 'work', 'desk', 'ergonomic', 'wheels', 'computer', 'seating'],
      },
      {
        name: 'office-chair-1',
        keywords: ['furniture', 'office', 'chair', '1', 'work', 'desk', 'ergonomic', 'wheels', 'computer', 'seating'],
      },
      {
        name: 'red-paper-lantern',
        keywords: ['furniture', 'red', 'paper', 'lantern', 'light', 'decor', 'hang', 'festival', 'oriental'],
      },
      {
        name: 'sakebottleandcup',
        keywords: ['furniture', 'sakebottleandcup', 'sake', 'bottle', 'cup', 'drink', 'japanese', 'ceramic', 'set'],
      },
      {
        name: 'seat',
        keywords: ['furniture', 'seat', 'chair', 'sit', 'furniture', 'cushion', 'support', 'rest', 'bench'],
      },
      {
        name: 'shower',
        keywords: ['furniture', 'shower', 'bathroom', 'water', 'clean', 'wash', 'fixture', 'plumbing'],
      },
      {
        name: 'shower-drops',
        keywords: ['furniture', 'shower', 'drops', 'water', 'bathroom', 'clean', 'wash', 'fixture', 'plumbing'],
      },
      {
        name: 'snow-globe',
        keywords: ['furniture', 'snow', 'globe', 'decor', 'ornament', 'winter', 'glass', 'display', 'collectible'],
      },
      {
        name: 'sofa',
        keywords: ['furniture', 'sofa', 'couch', 'seating', 'livingroom', 'comfortable', 'upholstered', 'rest'],
      },
      {
        name: 'stamp',
        keywords: ['furniture', 'stamp', 'mark', 'ink', 'stationery', 'office', 'document', 'tool', 'imprint'],
      },
      {
        name: 'street-light',
        keywords: ['furniture', 'street', 'light', 'outdoor', 'lamp', 'public', 'illumination', 'pole', 'city'],
      },
      {
        name: 'table-desk',
        keywords: ['furniture', 'table', 'desk', 'surface', 'work', 'writing', 'wood', 'office', 'flat'],
      },
      {
        name: 'table-lamp-simple',
        keywords: ['furniture', 'table', 'lamp', 'simple', 'light', 'fixture', 'desk', 'illuminate', 'decor'],
      },
      {
        name: 'television-furniture',
        keywords: ['furniture', 'television', 'furniture', 'tv', 'stand', 'media', 'console', 'livingroom', 'wood'],
      },
      {
        name: 'toilet',
        keywords: ['furniture', 'toilet', 'bathroom', 'plumbing', 'seat', 'ceramic', 'flush', 'sanitary'],
      },
      {
        name: 'toilet-side',
        keywords: ['furniture', 'toilet', 'side', 'adjacent', 'bathroom', 'area', 'fixture', 'accessory', 'space'],
      },
      {
        name: 'tv-set',
        keywords: ['furniture', 'tv', 'set', 'television', 'livingroom', 'entertainment', 'screen', 'stand', 'media'],
      },
      {
        name: 'vase',
        keywords: ['furniture', 'vase', 'decor', 'flowers', 'ceramic', 'display', 'ornament', 'home'],
      },
      {
        name: 'vase-1',
        keywords: ['furniture', 'vase', '1', 'decor', 'flowers', 'ceramic', 'display', 'ornament', 'home'],
      },
      {
        name: 'wardrobe-locker',
        keywords: ['furniture', 'wardrobe', 'locker', 'clothes', 'storage', 'closet', 'organize', 'bedroom', 'doors'],
      },
      {
        name: 'washbasin',
        keywords: ['furniture', 'washbasin', 'sink', 'bathroom', 'water', 'clean', 'ceramic', 'plumbing', 'basin'],
      },
      {
        name: 'window',
        keywords: ['furniture', 'window', 'opening', 'glass', 'view', 'light', 'frame', 'house', 'ventilation'],
      },
    ],
  },
  {
    name: 'tools',
    icons: [
      {
        name: 'adventure-map',
        keywords: ['tools', 'adventure', 'map', 'navigation', 'exploration', 'terrain', 'direction', 'journey'],
      },
      {
        name: 'alram-bell-office',
        keywords: ['tools', 'alram', 'bell', 'office', 'alert', 'notification', 'ring', 'reminder', 'signal'],
      },
      {
        name: 'anchor',
        keywords: ['tools', 'anchor', 'boat', 'marine', 'stability', 'metal', 'mooring', 'secure'],
      },
      {
        name: 'anchor-1',
        keywords: ['tools', 'anchor', 'boat', 'marine', 'stability', 'metal', 'mooring', 'secure'],
      },
      {
        name: 'blade-tool',
        keywords: ['tools', 'blade', 'tool', 'cutting', 'sharp', 'metal', 'craft', 'repair', 'utility'],
      },
      {
        name: 'bolt-screw-building-construction-nails-repair-tool',
        keywords: [
          'tools',
          'bolt',
          'screw',
          'building',
          'construction',
          'nails',
          'repair',
          'tool',
          'fastener',
          'hardware',
          'secure',
          'metal',
          'fitting',
        ],
      },
      {
        name: 'bolt-tool',
        keywords: ['tools', 'bolt', 'tool', 'fastener', 'metal', 'hardware', 'secure', 'assemble'],
      },
      {
        name: 'bow-arrow',
        keywords: ['tools', 'bow', 'arrow', 'archery', 'hunt', 'aim', 'string', 'target', 'projectile'],
      },
      {
        name: 'brick-wall',
        keywords: ['tools', 'brick', 'wall', 'masonry', 'construction', 'barrier', 'structure', 'building', 'solid'],
      },
      {
        name: 'broom-halloween-magic',
        keywords: ['tools', 'broom', 'halloween', 'magic', 'cleaning', 'sweeping', 'witch', 'handle', 'bristles'],
      },
      {
        name: 'brush-1',
        keywords: ['tools', 'brush', '1', 'cleaning', 'painting', 'bristles', 'application', 'art', 'coating'],
      },
      {
        name: 'brush-paint-wide-brush-paint-brush-repair-painting',
        keywords: [
          'tools',
          'brush',
          'paint',
          'wide',
          'brush',
          'paint',
          'brush',
          'repair',
          'painting',
          'coating',
          'surface',
          'color',
          'decor',
          'stroke',
        ],
      },
      {
        name: 'brush-shave',
        keywords: ['tools', 'brush', 'shave', 'grooming', 'barber', 'facial', 'foam', 'beard', 'styling'],
      },
      {
        name: 'brush-tool',
        keywords: ['tools', 'brush', 'tool', 'bristles', 'apply', 'clean', 'paint', 'surface', 'coating'],
      },
      {
        name: 'brush',
        keywords: ['tools', 'brush', 'paintbrush', 'art', 'application', 'bristles', 'decorate', 'stroke'],
      },
      {
        name: 'candle-halloween-rite',
        keywords: ['tools', 'candle', 'halloween', 'rite', 'wax', 'light', 'spooky', 'decor', 'flame'],
      },
      {
        name: 'candlelabra-light',
        keywords: ['tools', 'candlelabra', 'light', 'candles', 'holder', 'decor', 'elegant', 'wax', 'illumination'],
      },
      {
        name: 'cellophane-tape',
        keywords: ['tools', 'cellophane', 'tape', 'adhesive', 'seal', 'office', 'sticky', 'transparent'],
      },
      {
        name: 'chainsaw',
        keywords: ['tools', 'chainsaw', 'cutting', 'wood', 'motorized', 'timber', 'forestry', 'blade'],
      },
      {
        name: 'cleaning-mop',
        keywords: ['tools', 'cleaning', 'mop', 'floor', 'wet', 'housekeeping', 'maintenance', 'absorb', 'hygiene'],
      },
      {
        name: 'compass',
        keywords: ['tools', 'compass', 'direction', 'navigation', 'magnetic', 'orienteering', 'map', 'travel'],
      },
      {
        name: 'dentist-tool',
        keywords: ['tools', 'dentist', 'tool', 'dental', 'exam', 'mouth', 'instrument', 'hygiene', 'clean'],
      },
      {
        name: 'design-education-learning',
        keywords: [
          'tools',
          'design',
          'education',
          'learning',
          'creativity',
          'instruction',
          'knowledge',
          'development',
          'skill',
        ],
      },
      {
        name: 'drill-drill-machine-instrument-power-drill-tool-tools-repair',
        keywords: [
          'tools',
          'drill',
          'drill',
          'machine',
          'instrument',
          'power',
          'drill',
          'tool',
          'tools',
          'repair',
          'bore',
          'hole',
          'electric',
          'construction',
          'hardware',
        ],
      },
      {
        name: 'drill-technology',
        keywords: ['tools', 'drill', 'technology(1)', 'power', 'hardware', 'bore', 'machinery', 'innovation', 'device'],
      },
      {
        name: 'drill-tool-drill-machine-instrument-power-drill-tools',
        keywords: [
          'tools',
          'drill',
          'tool',
          'drill',
          'machine',
          'instrument',
          'power',
          'drill',
          'tools',
          'bore',
          'hole',
          'construction',
          'electric',
          'bit',
        ],
      },
      {
        name: 'dumbbell-gym-1',
        keywords: ['tools', 'dumbbell', 'gym', '1', 'fitness', 'weight', 'exercise', 'muscle', 'lift', 'training'],
      },
      {
        name: 'dumbbell-gym',
        keywords: ['tools', 'dumbbell', 'gym', 'weight', 'training', 'strength', 'metal', 'exercise', 'lift'],
      },
      {
        name: 'education-laboratory-school-2',
        keywords: [
          'tools',
          'education',
          'laboratory',
          'school',
          '2',
          'learning',
          'experiment',
          'science',
          'classroom',
          'study',
        ],
      },
      {
        name: 'education-laboratory-school',
        keywords: [
          'tools',
          'education',
          'laboratory',
          'school',
          'learning',
          'experiment',
          'science',
          'classroom',
          'study',
        ],
      },
      {
        name: 'education-learn-learning',
        keywords: ['tools', 'education', 'learn', 'learning', 'knowledge', 'study', 'improve', 'teaching', 'skill'],
      },
      {
        name: 'electricplug',
        keywords: ['tools', 'electricplug', 'electric', 'plug', 'power', 'energy', 'socket', 'wire', 'current'],
      },
      {
        name: 'energy-factory-gasoline',
        keywords: ['tools', 'energy', 'factory', 'gasoline', 'fuel', 'power', 'production', 'industrial', 'resource'],
      },
      {
        name: 'energy-factory-gauge',
        keywords: ['tools', 'energy', 'factory', 'gauge', 'measure', 'pressure', 'industrial', 'level', 'indicator'],
      },
      {
        name: 'eyeglasses-eyeglasess-construction-equipment-safety-eyewear',
        keywords: [
          'tools',
          'eyeglasses',
          'eyeglasess',
          'construction',
          'equipment',
          'safety',
          'eyewear',
          'protection',
          'vision',
          'work',
        ],
      },
      {
        name: 'faucet',
        keywords: ['tools', 'faucet', 'water', 'tap', 'plumbing', 'sink', 'flow', 'metal', 'kitchen'],
      },
      {
        name: 'fence',
        keywords: ['tools', 'fence', 'barrier', 'boundary', 'property', 'security', 'outdoor', 'wood'],
      },
      {
        name: 'fire-extinguisher',
        keywords: ['tools', 'fire', 'extinguisher', 'safety', 'emergency', 'flame', 'protection', 'spray', 'rescue'],
      },
      {
        name: 'garden-work-shovel',
        keywords: ['tools', 'garden', 'work', 'shovel', 'digging', 'soil', 'outdoor', 'planting', 'spade', 'landscape'],
      },
      {
        name: 'gardener-shovel',
        keywords: ['tools', 'gardener', 'shovel', 'digging', 'planting', 'soil', 'spade', 'horticulture', 'yard'],
      },
      {
        name: 'gardening-tools-rake',
        keywords: ['tools', 'gardening', 'tools', 'rake', 'yard', 'leaves', 'cleanup', 'pull', 'landscape', 'maintain'],
      },
      {
        name: 'globe',
        keywords: ['tools', 'globe', 'earth', 'atlas', 'map', 'geography', 'world', 'round', 'study'],
      },
      {
        name: 'globe-1',
        keywords: ['tools', 'globe', 'earth', 'atlas', 'map', 'geography', 'world', 'round', 'study'],
      },
      {
        name: 'grinder-equipment-hardware-repair-service-machine-tools',
        keywords: [
          'tools',
          'grinder',
          'equipment',
          'hardware',
          'repair',
          'service',
          'machine',
          'tools',
          'abrasive',
          'metal',
          'smooth',
          'workshop',
          'electric',
        ],
      },
      {
        name: 'gun',
        keywords: ['tools', 'gun', 'firearm', 'shoot', 'weapon', 'metal', 'danger', 'trigger'],
      },
      {
        name: 'hair-clip-tool',
        keywords: ['tools', 'hair', 'clip', 'tool', 'salon', 'styling', 'accessory', 'hold', 'groom'],
      },
      {
        name: 'hammer-tools-repair-tool-equipment-construction-work',
        keywords: [
          'tools',
          'hammer',
          'tools',
          'repair',
          'tool',
          'equipment',
          'construction',
          'work',
          'nail',
          'pound',
          'carpentry',
          'handle',
          'metal',
        ],
      },
      {
        name: 'hammer',
        keywords: ['tools', 'hammer', 'nail', 'carpentry', 'woodwork', 'pound', 'metal', 'construction'],
      },
      {
        name: 'hammer-1',
        keywords: ['tools', 'hammer', 'nail', 'carpentry', 'woodwork', 'pound', 'metal', 'construction'],
      },
      {
        name: 'helmet-labor',
        keywords: ['tools', 'helmet', 'labor', 'safety', 'hardhat', 'protection', 'work', 'construction', 'head'],
      },
      {
        name: 'knife-cutter-cut-cutlery-cutting-tools',
        keywords: [
          'tools',
          'knife',
          'cutter',
          'cut',
          'cutlery',
          'cutting',
          'tools',
          'blade',
          'sharp',
          'kitchen',
          'slice',
          'chop',
        ],
      },
      {
        name: 'ladder-staircase-stairs-work-tools-construction',
        keywords: [
          'tools',
          'ladder',
          'staircase',
          'stairs',
          'work',
          'tools',
          'construction',
          'climb',
          'elevate',
          'step',
          'access',
          'height',
        ],
      },
      {
        name: 'ladder-tool',
        keywords: ['tools', 'ladder', 'tool', 'climb', 'height', 'steps', 'reach', 'access', 'vertical'],
      },
      {
        name: 'lamp-oil-lamp',
        keywords: ['tools', 'lamp', 'oil', 'lamp', 'light', 'fuel', 'vintage', 'burn', 'illumination', 'glass'],
      },
      {
        name: 'lawn-mower',
        keywords: ['tools', 'lawn', 'mower', 'grass', 'cut', 'garden', 'outdoor', 'machine', 'maintenance'],
      },
      {
        name: 'link-chain',
        keywords: ['tools', 'link', 'chain', 'metal', 'connect', 'secure', 'strong', 'binding', 'fence'],
      },
      {
        name: 'linkedpaperclips',
        keywords: ['tools', 'linkedpaperclips', 'linked', 'paperclips', 'office', 'fasten', 'papers', 'chain', 'hold'],
      },
      {
        name: 'linksymbol',
        keywords: ['tools', 'linksymbol', 'link', 'symbol', 'connect', 'icon', 'join', 'unity'],
      },
      {
        name: 'lock',
        keywords: ['tools', 'lock', 'security', 'key', 'mechanism', 'safe', 'metal', 'protection'],
      },
      {
        name: 'lockandkey',
        keywords: ['tools', 'lockandkey', 'lock', 'and', 'key', 'security', 'metal', 'open', 'close', 'protection'],
      },
      {
        name: 'lowerleftcrayon',
        keywords: ['tools', 'lowerleftcrayon', 'lowerleft', 'crayon', 'color', 'draw', 'wax', 'art', 'craft'],
      },
      {
        name: 'lowerleftfountainpen',
        keywords: [
          'tools',
          'lowerleftfountainpen',
          'lowerleft',
          'fountainpen',
          'writing',
          'ink',
          'pen',
          'calligraphy',
          'smooth',
        ],
      },
      {
        name: 'lowerleftpaintbrush',
        keywords: [
          'tools',
          'lowerleftpaintbrush',
          'lowerleft',
          'paintbrush',
          'art',
          'paint',
          'color',
          'bristles',
          'canvas',
        ],
      },
      {
        name: 'lowerleftpen',
        keywords: ['tools', 'lowerleftpen', 'lowerleft', 'pen', 'write', 'ink', 'office', 'notes', 'stationery'],
      },
      {
        name: 'lowerleftpencil',
        keywords: [
          'tools',
          'lowerleftpencil',
          'lowerleft',
          'pencil',
          'graphite',
          'draw',
          'sketch',
          'write',
          'erasable',
        ],
      },
      {
        name: 'measure-measuring',
        keywords: ['tools', 'measure', 'measuring', 'length', 'units', 'precision', 'ruler', 'scale', 'dimension'],
      },
      {
        name: 'measuring-tape',
        keywords: ['tools', 'measuring', 'tape', 'length', 'construction', 'distance', 'precision', 'units', 'roll'],
      },
      {
        name: 'mic',
        keywords: ['tools', 'mic', 'microphone', 'audio', 'sound', 'record', 'speak', 'broadcast', 'amplify'],
      },
      {
        name: 'microscope',
        keywords: ['tools', 'microscope', 'science', 'magnify', 'lab', 'research', 'biology', 'inspect', 'analyze'],
      },
      {
        name: 'metronome',
        keywords: ['tools', 'metronome', 'music', 'tempo', 'beat', 'timing', 'practice', 'rhythm', 'ticks'],
      },
      {
        name: 'nutandbolt',
        keywords: ['tools', 'nutandbolt', 'nut', 'and', 'bolt', 'fastener', 'metal', 'secure', 'tighten'],
      },
      {
        name: 'openlock',
        keywords: ['tools', 'openlock', 'open', 'lock', 'unlocked', 'security', 'access', 'key', 'freedom'],
      },
      {
        name: 'oxygen-tube',
        keywords: ['tools', 'oxygen', 'tube', 'medical', 'breathing', 'hospital', 'airflow', 'respiratory', 'life'],
      },
      {
        name: 'packs-cart',
        keywords: ['tools', 'packs', 'cart', 'transport', 'load', 'wheels', 'haul', 'warehouse', 'storage'],
      },
      {
        name: 'paint-painter',
        keywords: ['tools', 'paint', 'painter', 'brush', 'color', 'wall', 'decoration', 'renovation', 'coat'],
      },
      {
        name: 'paint-spatula',
        keywords: ['tools', 'paint', 'spatula', 'mix', 'scrape', 'apply', 'art', 'canvas', 'flat'],
      },
      {
        name: 'pen',
        keywords: ['tools', 'pen', 'write', 'ink', 'office', 'note', 'paper', 'stationery'],
      },
      {
        name: 'pencil',
        keywords: ['tools', 'pencil', 'draw', 'sketch', 'graphite', 'write', 'eraser', 'draft'],
      },
      {
        name: 'pickaxe-tool',
        keywords: ['tools', 'pickaxe', 'tool', 'mining', 'digging', 'stone', 'ore', 'heavy', 'hard'],
      },
      {
        name: 'pistol',
        keywords: ['tools', 'pistol', 'gun', 'weapon', 'firearm', 'shoot', 'metal', 'danger'],
      },
      {
        name: 'pliers-tools-equipment-pincers-work-construction-repair',
        keywords: [
          'tools',
          'pliers',
          'tools',
          'equipment',
          'pincers',
          'work',
          'construction',
          'repair',
          'grip',
          'metal',
          'wire',
          'pull',
          'adjust',
        ],
      },
      {
        name: 'pruners-garden',
        keywords: ['tools', 'pruners', 'garden', 'trim', 'plants', 'shears', 'horticulture', 'cut', 'branches'],
      },
      {
        name: 'rake-gardening',
        keywords: ['tools', 'rake', 'gardening', 'leaves', 'yard', 'cleanup', 'pull', 'soil', 'landscape'],
      },
      {
        name: 'rightmagnifyingglass',
        keywords: [
          'tools',
          'rightmagnifyingglass',
          'right',
          'magnifyingglass',
          'magnify',
          'zoom',
          'inspect',
          'lens',
          'search',
        ],
      },
      {
        name: 'ruler-measure',
        keywords: ['tools', 'ruler', 'measure', 'length', 'straight', 'units', 'drafting', 'precision', 'line'],
      },
      {
        name: 'ruler-rulers-scale-precision-measure-geometry-weight',
        keywords: [
          'tools',
          'ruler',
          'rulers',
          'scale',
          'precision',
          'measure',
          'geometry',
          'weight',
          'length',
          'units',
          'exact',
          'draft',
        ],
      },
      {
        name: 'ruler',
        keywords: ['tools', 'ruler', 'measure', 'straight', 'units', 'length', 'drafting', 'drawing', 'mark'],
      },
      {
        name: 'safety-glasses',
        keywords: ['tools', 'safety', 'glasses', 'eye', 'protection', 'work', 'gear', 'industrial', 'vision'],
      },
      {
        name: 'safety-pin',
        keywords: ['tools', 'safety', 'pin', 'secure', 'fabric', 'temporary', 'fasten', 'metal', 'clothing'],
      },
      {
        name: 'safety-vest',
        keywords: ['tools', 'safety', 'vest', 'high-visibility', 'protective', 'reflective', 'work', 'warning'],
      },
      {
        name: 'saw-circular-saw',
        keywords: ['tools', 'saw', 'circular', 'saw', 'electric', 'blade', 'woodcutting', 'fast', 'round'],
      },
      {
        name: 'saw-construction-and-tools',
        keywords: ['tools', 'saw', 'construction', 'and', 'tools', 'building', 'cutting', 'wood', 'manual', 'work'],
      },
      {
        name: 'saw-equipment-industrial-tools-work-construction',
        keywords: [
          'tools',
          'saw',
          'equipment',
          'industrial',
          'tools',
          'work',
          'construction',
          'machine',
          'cut',
          'metal',
          'power',
        ],
      },
      {
        name: 'saw',
        keywords: ['tools', 'saw', 'cut', 'wood', 'teeth', 'manual', 'carpentry', 'handle', 'timber'],
      },
      {
        name: 'scissors-part-2',
        keywords: ['tools', 'scissors', 'part', '2', 'cut', 'paper', 'sharp', 'blades', 'office', 'craft'],
      },
      {
        name: 'screwdriver-tools-construction-flathead-repair-tool-equipment',
        keywords: [
          'tools',
          'screwdriver',
          'tools',
          'construction',
          'flathead',
          'repair',
          'tool',
          'equipment',
          'turn',
          'screw',
          'fasten',
          'handle',
          'metal',
        ],
      },
      {
        name: 'screwdriver',
        keywords: ['tools', 'screwdriver', 'turn', 'screw', 'fasten', 'flathead', 'philips', 'grip', 'metal'],
      },
      {
        name: 'search-research',
        keywords: ['tools', 'search', 'research', 'inquiry', 'find', 'information', 'examine', 'discovery'],
      },
      {
        name: 'secateurs-scissors',
        keywords: ['tools', 'secateurs', 'scissors', 'prune', 'cut', 'garden', 'branches', 'shears', 'horticulture'],
      },
      {
        name: 'shovel-dig-digging-tool-gardening-tool-spade-tool',
        keywords: [
          'tools',
          'shovel',
          'dig',
          'digging',
          'tool',
          'gardening',
          'tool',
          'spade',
          'tool',
          'soil',
          'earth',
          'outdoor',
          'move',
          'landscaping',
        ],
      },
      {
        name: 'sign-roadblock',
        keywords: ['tools', 'sign', 'roadblock', 'warning', 'traffic', 'barrier', 'caution', 'construction', 'block'],
      },
      {
        name: 'spray-bottle-sprayer',
        keywords: [
          'tools',
          'spray',
          'bottle',
          'sprayer',
          'liquid',
          'mist',
          'cleaning',
          'gardening',
          'dispense',
          'nozzle',
        ],
      },
      {
        name: 'sprinkler',
        keywords: ['tools', 'sprinkler', 'water', 'garden', 'irrigation', 'lawn', 'rotate', 'spray', 'moisture'],
      },
      {
        name: 'straightruler',
        keywords: ['tools', 'straightruler', 'straight', 'ruler', 'measure', 'line', 'draw', 'units', 'geometry'],
      },
      {
        name: 'sword',
        keywords: ['tools', 'sword', 'blade', 'weapon', 'metal', 'knight', 'cut', 'historical', 'combat'],
      },
      {
        name: 'syringe',
        keywords: ['tools', 'syringe', 'medical', 'inject', 'needle', 'liquid', 'health', 'dose', 'sterile'],
      },
      {
        name: 'tape-line',
        keywords: ['tools', 'tape', 'line', 'marker', 'boundary', 'straight', 'floor', 'guideline', 'visibility'],
      },
      {
        name: 'tape',
        keywords: ['tools', 'tape', 'adhesive', 'strip', 'binding', 'repair', 'seal', 'roll', 'sticky'],
      },
      {
        name: 'telescope',
        keywords: ['tools', 'telescope', 'astronomy', 'stars', 'space', 'lens', 'observe', 'night', 'far'],
      },
      {
        name: 'thermometer',
        keywords: ['tools', 'thermometer', 'temperature', 'measure', 'degrees', 'heat', 'cold', 'medical', 'weather'],
      },
      {
        name: 'toolbox-container-repair-box-tool-box-toolboxes-toolkit-tools',
        keywords: [
          'tools',
          'toolbox',
          'container',
          'repair',
          'box',
          'tool',
          'box',
          'toolboxes',
          'toolkit',
          'tools',
          'storage',
          'carry',
          'organize',
          'equipment',
          'portable',
        ],
      },
      {
        name: 'toolbox-repair-box-tool-box-toolboxes-toolkit-tools-equipment',
        keywords: [
          'tools',
          'toolbox',
          'repair',
          'box',
          'tool',
          'box',
          'toolboxes',
          'toolkit',
          'tools',
          'equipment',
          'storage',
          'organize',
          'portable',
          'hardware',
          'handy',
        ],
      },
      {
        name: 'trackball',
        keywords: ['tools', 'trackball', 'input', 'device', 'cursor', 'computer', 'roll', 'navigation', 'ergonomic'],
      },
      {
        name: 'traffic-cone',
        keywords: ['tools', 'traffic', 'cone', 'warning', 'road', 'safety', 'barrier', 'bright', 'caution'],
      },
      {
        name: 'triangularruler',
        keywords: [
          'tools',
          'triangularruler',
          'triangular',
          'ruler',
          'measure',
          'draft',
          'angles',
          'geometry',
          'shape',
        ],
      },
      {
        name: 'umbrella-1',
        keywords: ['tools', 'umbrella', '1', 'weather', 'protection', 'rain', 'shade', 'portable', 'cover'],
      },
      {
        name: 'umbrella',
        keywords: ['tools', 'umbrella', 'weather', 'protection', 'rain', 'shade', 'fold', 'carry', 'outdoor'],
      },
      {
        name: 'vacuum-cleaner',
        keywords: ['tools', 'vacuum', 'cleaner', 'suction', 'dust', 'floor', 'household', 'electric', 'carpet'],
      },
      {
        name: 'watering-can',
        keywords: ['tools', 'watering', 'can', 'garden', 'plants', 'irrigate', 'sprinkle', 'outdoor', 'grow'],
      },
      {
        name: 'waterpass-ruler-rulers-scale-measure-geometry-weight',
        keywords: [
          'tools',
          'waterpass',
          'ruler',
          'rulers',
          'scale',
          'measure',
          'geometry',
          'weight',
          'level',
          'straight',
          'align',
          'accuracy',
          'plumb',
        ],
      },
      {
        name: 'wrench',
        keywords: ['tools', 'wrench(1)', 'wrench', 'tighten', 'bolt', 'nut', 'adjust', 'mechanic', 'turn'],
      },
      {
        name: 'wrench-construction',
        keywords: ['tools', 'wrench', 'construction', 'twist', 'fasten', 'metal', 'bolt', 'nut', 'adjust'],
      },
      {
        name: 'wrench-tools-configuration-setting-settings-repair',
        keywords: [
          'tools',
          'wrench',
          'tools',
          'configuration',
          'setting',
          'settings',
          'repair',
          'adjust',
          'machinery',
          'fix',
          'bolt',
          'maintenance',
        ],
      },
      {
        name: 'wrench-tools-settings-configuration-setting-tool',
        keywords: [
          'tools',
          'wrench',
          'tools',
          'settings',
          'configuration',
          'setting',
          'tool',
          'adjust',
          'tweak',
          'machinery',
          'repair',
          'bolt',
        ],
      },
    ],
  },

  {
    name: 'food',
    icons: [
      {
        name: '3d-movie-glass',
        keywords: ['food', '3d', 'movie', 'glass', 'theater', 'snack', 'drink', 'treat'],
      },
      {
        name: 'acorn',
        keywords: ['food', 'acorn', 'nut', 'oak', 'forage', 'seed', 'autumn', 'snack'],
      },
      {
        name: 'anchovies',
        keywords: ['food', 'anchovies', 'fish', 'salted', 'umami', 'topping', 'seafood', 'savory'],
      },
      {
        name: 'apple-1',
        keywords: ['food', 'apple', 'fruit', 'fresh', 'juicy', 'sweet', 'red', 'snack'],
      },
      {
        name: 'apple-red',
        keywords: ['food', 'apple', 'red', 'fruit', 'juicy', 'sweet', 'fresh', 'crisp'],
      },
      {
        name: 'asparagus',
        keywords: ['food', 'asparagus', 'vegetable', 'green', 'healthy', 'fresh', 'cooking', 'spring'],
      },
      {
        name: 'avocado',
        keywords: ['food', 'avocado', 'fruit', 'green', 'guacamole', 'creamy', 'healthy'],
      },
      {
        name: 'bacon',
        keywords: ['food', 'bacon', 'pork', 'salty', 'crispy', 'breakfast', 'meat', 'savory'],
      },
      {
        name: 'bacon(2)',
        keywords: ['food', 'bacon', '2', 'pork', 'salty', 'crispy', 'breakfast', 'meat', 'savory'],
      },
      {
        name: 'banana',
        keywords: ['food', 'banana', 'fruit', 'yellow', 'sweet', 'tropical', 'fresh', 'snack'],
      },
      {
        name: 'banana-one',
        keywords: ['food', 'banana', 'one', 'fruit', 'tropical', 'yellow', 'sweet', 'snack'],
      },
      {
        name: 'banana-two',
        keywords: ['food', 'banana', 'two', 'fruit', 'yellow', 'tropical', 'sweet', 'snack'],
      },
      {
        name: 'red-wine',
        keywords: ['food', 'red', 'wine', 'glass', 'alcohol', 'drink', 'grapes'],
      },
      {
        name: 'beer',
        keywords: ['food', 'beer', 'drink', 'brew', 'barley', 'hops', 'alcohol', 'refreshing'],
      },
      {
        name: 'beet',
        keywords: ['food', 'beet', 'vegetable', 'root', 'red', 'earthy', 'healthy', 'cooking'],
      },
      {
        name: 'bread',
        keywords: ['food', 'bread', 'grain', 'baked', 'loaf', 'yeast', 'staple', 'slice'],
      },
      {
        name: 'british-breakfast',
        keywords: ['food', 'british', 'breakfast', 'meal', 'eggs', 'beans', 'hearty', 'savory'],
      },
      {
        name: 'broccoli-cabbage-health',
        keywords: ['food', 'broccoli', 'cabbage', 'health', 'vegetable', 'green', 'fresh', 'nutritious'],
      },
      {
        name: 'broccoli-health-salad',
        keywords: ['food', 'broccoli', 'health', 'salad', 'vegetable', 'green', 'fresh', 'crunchy'],
      },
      {
        name: 'cabbage',
        keywords: ['food', 'cabbage', 'vegetable', 'green', 'leafy', 'fresh', 'salad', 'crunchy'],
      },
      {
        name: 'cabbage-health-salad',
        keywords: ['food', 'cabbage', 'health', 'salad', 'leafy', 'fresh', 'raw', 'crunchy'],
      },
      {
        name: 'cake',
        keywords: ['food', 'cake', 'dessert', 'sweet', 'baked', 'celebration', 'treat', 'slice'],
      },
      {
        name: 'cake-piece',
        keywords: ['food', 'cake', 'piece', 'dessert', 'sweet', 'slice', 'frosted', 'treat'],
      },
      {
        name: 'candy-halloween-candy',
        keywords: ['food', 'candy', 'halloween', 'candy', 'sweet', 'treat', 'sugar', 'colorful', 'fun'],
      },
      {
        name: 'candy-halloween-lollipop',
        keywords: ['food', 'candy', 'halloween', 'lollipop', 'sweet', 'sugar', 'treat', 'colorful', 'fun'],
      },
      {
        name: 'carrot',
        keywords: ['food', 'carrot', 'vegetable', 'orange', 'crunchy', 'sweet', 'fresh', 'healthy'],
      },
      {
        name: 'carrot-salad-vegetables',
        keywords: ['food', 'carrot', 'salad', 'vegetables', 'fresh', 'crunchy', 'raw', 'nutritious'],
      },
      {
        name: 'celebration',
        keywords: ['food', 'celebration', 'party', 'festive', 'dessert', 'treat', 'special', 'sweet'],
      },
      {
        name: 'celery',
        keywords: ['food', 'celery', 'vegetable', 'green', 'crunchy', 'fresh', 'healthy', 'raw'],
      },
      {
        name: 'cheese',
        keywords: ['food', 'cheese', 'dairy', 'savory', 'milk', 'slice', 'topping', 'rich'],
      },
      {
        name: 'cherry',
        keywords: ['food', 'cherry', 'fruit', 'red', 'sweet', 'juicy', 'fresh', 'dessert'],
      },
      {
        name: 'cherries',
        keywords: ['food', 'cherry', 'fruit', 'red', 'sweet', 'juicy', 'fresh', 'dessert'],
      },
      {
        name: 'chili-fruit-spicy',
        keywords: ['food', 'chili', 'fruit', 'spicy', 'pepper', 'hot', 'flavor', 'heat'],
      },
      {
        name: 'chili-pepper',
        keywords: ['food', 'chili', 'pepper', 'spicy', 'hot', 'red', 'flavor', 'heat'],
      },
      {
        name: 'chocolate',
        keywords: ['food', 'chocolate', 'sweet', 'cocoa', 'dessert', 'treat', 'candy', 'rich'],
      },
      {
        name: 'chocolate-bar-candy-dairymilk-sweet-dessert',
        keywords: ['food', 'chocolate', 'bar', 'candy', 'dairymilk', 'sweet', 'dessert', 'treat', 'cocoa', 'rich'],
      },
      {
        name: 'clinking-beer-mugs-bar-glass-drink',
        keywords: ['food', 'clinking', 'beer', 'mugs', 'bar', 'glass', 'drink', 'cheers', 'alcohol', 'celebrate'],
      },
      {
        name: 'cocktail',
        keywords: ['food', 'cocktail', 'drink', 'alcohol', 'bar', 'mix', 'party', 'refreshing'],
      },
      {
        name: 'cocktail-3',
        keywords: ['food', 'cocktail', 'drink', 'alcohol', 'bar', 'mix', 'party', 'refreshing'],
      },
      {
        name: 'cocktail-2',
        keywords: ['food', 'cocktail', '2', 'drink', 'alcohol', 'bar', 'mixed', 'tropical', 'refreshing'],
      },
      {
        name: 'cocktail-3',
        keywords: ['food', 'cocktail', '2', 'drink', 'alcohol', 'bar', 'mixed', 'tropical', 'refreshing'],
      },
      {
        name: 'cocktail-coconut',
        keywords: ['food', 'cocktail', '2', 'drink', 'alcohol', 'bar', 'mixed', 'tropical', 'refreshing'],
      },
      {
        name: 'cocktail-drink-glass-bar-beverage-emoj',
        keywords: ['food', 'cocktail', 'drink', 'glass', 'bar', 'beverage', 'emoj', 'alcohol', 'party', 'tasty'],
      },
      {
        name: 'coconut',
        keywords: ['food', 'coconut', 'fruit', 'tropical', 'sweet', 'nutty', 'milk', 'exotic'],
      },
      {
        name: 'coconut-young',
        keywords: ['food', 'coconut', 'young', 'tropical', 'refreshing', 'juice', 'sweet', 'nutty'],
      },
      {
        name: 'coffee',
        keywords: ['food', 'coffee', 'beverage', 'hot', 'aroma', 'caffeine', 'brew', 'morning'],
      },
      {
        name: 'coffee-tea-hot-beverage-drink-steaming',
        keywords: ['food', 'coffee', 'tea', 'hot', 'beverage', 'drink', 'steaming', 'warm', 'aromatic', 'relaxing'],
      },
      {
        name: 'coke',
        keywords: ['food', 'coke', 'cola', 'soda', 'carbonated', 'sweet', 'drink', 'refreshing'],
      },
      {
        name: 'cooked-rice-ball-lunch-dish-food',
        keywords: ['food', 'cooked', 'rice', 'ball', 'lunch', 'dish', 'food', 'grain', 'meal', 'savory'],
      },
      {
        name: 'cookie',
        keywords: ['food', 'cookie', 'dessert', 'sweet', 'baked', 'treat', 'chocolate', 'crunchy'],
      },
      {
        name: 'cookie-dessert-sweet-food-fastfood-emoj',
        keywords: ['food', 'cookie', 'dessert', 'sweet', 'food', 'fastfood', 'emoj', 'treat', 'baked', 'snack'],
      },
      {
        name: 'cookies',
        keywords: ['food', 'cookies', 'baked', 'sweet', 'treats', 'dessert', 'snack', 'crunchy'],
      },
      {
        name: 'corn',
        keywords: ['food', 'corn', 'vegetable', 'yellow', 'sweet', 'cob', 'fresh', 'grain'],
      },
      {
        name: 'corn-1',
        keywords: ['food', 'corn', 'vegetable', 'yellow', 'sweet', 'cob', 'fresh', 'grain'],
      },
      {
        name: 'cucumber',
        keywords: ['food', 'cucumber', 'vegetable', 'green', 'fresh', 'salad', 'crisp', 'hydrating'],
      },
      {
        name: 'cupcake',
        keywords: ['food', 'cupcake', 'dessert', 'sweet', 'frosted', 'baked', 'treat', 'mini'],
      },
      {
        name: 'curry-rice-lunch-dish-food-emoj',
        keywords: ['food', 'curry', 'rice', 'lunch', 'dish', 'food', 'emoj', 'spicy', 'savory', 'meal'],
      },
      {
        name: 'custard-pudding-sweet-dessert-food-emoj',
        keywords: ['food', 'custard', 'pudding', 'sweet', 'dessert', 'food', 'emoj', 'creamy', 'treat', 'soft'],
      },
      {
        name: 'dango-japanese-dessert-skewer-stick-sweet',
        keywords: ['food', 'dango', 'japanese', 'dessert', 'skewer', 'stick', 'sweet', 'rice', 'treat', 'traditional'],
      },
      {
        name: 'doner-roll',
        keywords: ['food', 'doner', 'roll', 'wrap', 'meat', 'savory', 'streetfood', 'meal'],
      },
      {
        name: 'donut',
        keywords: ['food', 'donut', 'dessert', 'sweet', 'fried', 'dough', 'treat', 'glazed'],
      },
      {
        name: 'donut-doughnut-sweet-dessert-food-fastfood',
        keywords: ['food', 'donut', 'doughnut', 'sweet', 'dessert', 'food', 'fastfood', 'fried', 'treat', 'sugar'],
      },
      {
        name: 'doughnut',
        keywords: ['food', 'doughnut', 'donut', 'sweet', 'fried', 'dessert', 'treat', 'icing'],
      },
      {
        name: 'dragon-fruit',
        keywords: ['food', 'dragon', 'fruit', 'exotic', 'pink', 'sweet', 'tropical', 'fresh'],
      },
      {
        name: 'drink-cola-coke',
        keywords: ['food', 'drink', 'cola', 'coke', 'soda', 'carbonated', 'sweet', 'refreshing', 'bubbly'],
      },
      {
        name: 'coke',
        keywords: ['food', 'drink', 'cola', 'coke', 'soda', 'carbonated', 'sweet', 'refreshing', 'bubbly'],
      },
      {
        name: 'durian',
        keywords: ['food', 'durian', 'fruit', 'spiky', 'exotic', 'aromatic', 'sweet', 'creamy'],
      },
      {
        name: 'egg',
        keywords: ['food', 'egg', 'protein', 'breakfast', 'white', 'yolk', 'cooking', 'versatile'],
      },
      {
        name: 'egg-1',
        keywords: ['food', 'egg', '1', 'protein', 'fresh', 'breakfast', 'yolk', 'shell'],
      },
      {
        name: 'egg-2',
        keywords: ['food', 'egg', '1', 'protein', 'fresh', 'breakfast', 'yolk', 'shell'],
      },
      {
        name: 'eggplant-salad-vegetables',
        keywords: ['food', 'eggplant', 'salad', 'vegetables', 'purple', 'fresh', 'cooking', 'savory', 'healthy'],
      },
      {
        name: 'food',
        keywords: ['food', 'food', 'edible', 'meal', 'dish', 'eat', 'cuisine', 'ingredient'],
      },
      {
        name: 'pizza-slice',
        keywords: ['food', 'food', 'color', 'pizza', 'slice', 'cheese', 'tomato', 'crust', 'topping'],
      },
      {
        name: 'water-bottle',
        keywords: ['food', 'water', 'bottle', 'drink', 'hydrating', 'thirsty', 'cool'],
      },
      {
        name: 'french-fries-fastfood-food-emoj-symbol',
        keywords: ['food', 'french', 'fries', 'fastfood', 'food', 'emoj', 'symbol', 'potato', 'fried', 'salty'],
      },
      {
        name: 'fried-shrimp-prawn-tempura-non-vegetarian',
        keywords: ['food', 'fried', 'shrimp', 'prawn', 'tempura', 'non', 'vegetarian', 'seafood', 'crispy', 'savory'],
      },
      {
        name: 'fruit-paprika-salad',
        keywords: ['food', 'fruit', 'paprika', 'salad', 'fresh', 'vegetable', 'colorful', 'tangy', 'mix'],
      },
      {
        name: 'garlic',
        keywords: ['food', 'garlic', 'aromatic', 'spice', 'bulb', 'flavor', 'savory', 'cooking'],
      },
      {
        name: 'grapes-fruit-emoj-symbol-food',
        keywords: ['food', 'grapes', 'fruit', 'emoj', 'symbol', 'food', 'purple', 'sweet', 'bunch', 'juicy'],
      },
      {
        name: 'green-fruit-emoj-symbol-food',
        keywords: ['food', 'green', 'fruit', 'emoj', 'symbol', 'food', 'fresh', 'sweet', 'juice', 'plant'],
      },
      {
        name: 'hamburger',
        keywords: ['food', 'hamburger', 'beef', 'bun', 'fastfood', 'savory', 'cheese', 'lettuce'],
      },
      {
        name: 'hamburger-burger-fastfood-food-emoj-symbol',
        keywords: [
          'food',
          'hamburger',
          'burger',
          'fastfood',
          'food',
          'emoj',
          'symbol',
          'bun',
          'meat',
          'cheese',
          'lettuce',
        ],
      },
      {
        name: 'hazelnut',
        keywords: ['food', 'hazelnut', 'nut', 'brown', 'sweet', 'flavor', 'chocolate', 'snack'],
      },
      {
        name: 'honey',
        keywords: ['food', 'honey', 'sweet', 'liquid', 'bee', 'natural', 'golden', 'sticky'],
      },
      {
        name: 'honeypot-honey-pot-sweet-dessert-food',
        keywords: ['food', 'honeypot', 'honey', 'pot', 'sweet', 'dessert', 'food', 'bee', 'sticky', 'spread'],
      },
      {
        name: 'hops',
        keywords: ['food', 'hops', 'plant', 'bitter', 'beer', 'brew', 'aroma', 'ingredient'],
      },
      {
        name: 'hot-dog',
        keywords: ['food', 'hot', 'dog', 'fastfood', 'bun', 'sausage', 'savory', 'streetfood'],
      },
      {
        name: 'ice-cream',
        keywords: ['food', 'ice', 'cream', 'sweet', 'dessert', 'cold', 'frozen', 'treat'],
      },
      {
        name: 'ice-cream-icecream-sweet-dessert-emoj',
        keywords: ['food', 'ice', 'cream', 'icecream', 'sweet', 'dessert', 'emoj', 'cold', 'frozen', 'treat'],
      },
      {
        name: 'ice-cream-popsicle',
        keywords: ['food', 'ice', 'cream', 'popsicle', 'frozen', 'sweet', 'dessert', 'summer', 'treat'],
      },
      {
        name: 'ingredients',
        keywords: ['food', 'ingredients', 'cooking', 'raw', 'mix', 'recipe', 'fresh', 'kitchen'],
      },
      {
        name: 'ketchup',
        keywords: ['food', 'ketchup', 'sauce', 'tomato', 'condiment', 'sweet', 'tangy', 'dip'],
      },
      {
        name: 'kiwi',
        keywords: ['food', 'kiwi', 'fruit', 'green', 'tropical', 'tangy', 'juicy', 'fresh'],
      },
      {
        name: 'kiwi-1',
        keywords: ['food', 'kiwi', 'fruit', 'green', 'tropical', 'tangy', 'juicy', 'fresh'],
      },
      {
        name: 'kohlrabi',
        keywords: ['food', 'kohlrabi', 'vegetable', 'root', 'crunchy', 'fresh', 'mild', 'healthy'],
      },
      {
        name: 'leaf-salad-seasoning',
        keywords: ['food', 'leaf', 'salad', 'seasoning', 'green', 'fresh', 'light', 'crunchy', 'dressing'],
      },
      {
        name: 'lemon',
        keywords: ['food', 'lemon', 'fruit', 'citrus', 'sour', 'yellow', 'zesty', 'tangy'],
      },
      {
        name: 'lemon-1',
        keywords: ['food', 'lemon', 'fruit', 'citrus', 'sour', 'yellow', 'zesty', 'tangy'],
      },
      {
        name: 'lemon-fruit-emoj-symbol-food',
        keywords: ['food', 'lemon', 'fruit', 'emoj', 'symbol', 'food', 'citrus', 'yellow', 'zesty', 'tart'],
      },
      {
        name: 'lettuce',
        keywords: ['food', 'lettuce', 'vegetable', 'green', 'leafy', 'fresh', 'salad', 'crisp'],
      },
      {
        name: 'lollipop',
        keywords: ['food', 'lollipop', 'candy', 'sweet', 'sugar', 'treat', 'colorful', 'stick'],
      },
      {
        name: 'luke',
        keywords: ['food', 'luke', 'unknown', 'possibly', 'nonedible', 'name', 'abstract', 'confusing', 'misc'],
      },
      {
        name: 'macaron',
        keywords: ['food', 'macaron', 'dessert', 'sweet', 'french', 'cookie', 'almond', 'colorful'],
      },
      {
        name: 'mango',
        keywords: ['food', 'mango', 'fruit', 'tropical', 'sweet', 'juicy', 'yellow', 'fresh'],
      },
      {
        name: 'mangosteen',
        keywords: ['food', 'mangosteen', 'fruit', 'exotic', 'sweet', 'tropical', 'purple', 'fresh'],
      },
      {
        name: 'meatonbone',
        keywords: ['food', 'meatonbone', 'meat', 'bone', 'savory', 'cooked', 'protein', 'roast', 'hearty'],
      },
      {
        name: 'melon',
        keywords: ['food', 'melon', 'fruit', 'juicy', 'sweet', 'fresh', 'summer', 'green'],
      },
      {
        name: 'melon-1',
        keywords: ['food', 'melon', 'fruit', 'juicy', 'sweet', 'fresh', 'summer', 'green'],
      },
      {
        name: 'melon(2)',
        keywords: ['food', 'melon', '2', 'fruit', 'juicy', 'sweet', 'fresh', 'variant', 'summer'],
      },
      {
        name: 'milk',
        keywords: ['food', 'milk', 'dairy', 'white', 'drink', 'calcium', 'nutritious', 'liquid'],
      },
      {
        name: 'mug',
        keywords: ['food', 'mug', 'cup', 'drink', 'hot', 'coffee', 'tea', 'warm'],
      },
      {
        name: 'mushroom-1',
        keywords: ['food', 'mushroom', '1', 'fungus', 'savory', 'earthy', 'cooking', 'umami'],
      },
      {
        name: 'mushrooms',
        keywords: ['food', 'mushrooms', 'fungus', 'umami', 'savory', 'earthy', 'cooking', 'vegetable'],
      },
      {
        name: 'nachos',
        keywords: ['food', 'nachos', 'tortilla', 'cheese', 'snack', 'mexican', 'party', 'savory'],
      },
      {
        name: 'nut',
        keywords: ['food', 'nut', 'protein', 'snack', 'crunchy', 'seed', 'healthy', 'fatty'],
      },
      {
        name: 'oden',
        keywords: ['food', 'oden', 'japanese', 'broth', 'savory', 'fishcake', 'winter', 'hearty'],
      },
      {
        name: 'onion-seasoning-vegetables',
        keywords: ['food', 'onion', 'seasoning', 'vegetables', 'aromatic', 'savory', 'cooking', 'flavor', 'pungent'],
      },
      {
        name: 'orange',
        keywords: ['food', 'orange', 'fruit', 'citrus', 'juicy', 'sweet', 'vitaminC', 'fresh'],
      },
      {
        name: 'orange-grapefruit',
        keywords: ['food', 'orange', 'grapefruit', 'fruit', 'citrus', 'tangy', 'juicy', 'fresh'],
      },
      {
        name: 'orange',
        keywords: ['food', 'orange', 'mandarin', 'fruit', 'citrus', 'sweet', 'easy-peel', 'fresh'],
      },
      {
        name: 'mandarin',
        keywords: ['food', 'orange', 'mandarin', 'fruit', 'citrus', 'sweet', 'easy-peel', 'fresh'],
      },
      {
        name: 'papaya',
        keywords: ['food', 'papaya', 'fruit', 'tropical', 'sweet', 'soft', 'orange', 'fresh'],
      },
      {
        name: 'passion-fruit',
        keywords: ['food', 'passion', 'fruit', 'tangy', 'tropical', 'aromatic', 'seeds', 'refreshing'],
      },
      {
        name: 'peach',
        keywords: ['food', 'peach', 'fruit', 'fuzzy', 'sweet', 'juicy', 'fresh', 'summer'],
      },
      {
        name: 'peach-1',
        keywords: ['food', 'peach', 'fruit', 'fuzzy', 'sweet', 'juicy', 'fresh', 'summer'],
      },
      {
        name: 'peanuts',
        keywords: ['food', 'peanuts', 'nut', 'legume', 'salty', 'snack', 'buttery', 'protein'],
      },
      {
        name: 'pear',
        keywords: ['food', 'pear', 'fruit', 'juicy', 'sweet', 'fresh', 'green', 'soft'],
      },
      {
        name: 'pear-1',
        keywords: ['food', 'pear', 'fruit', 'juicy', 'sweet', 'fresh', 'green', 'soft'],
      },
      {
        name: 'pear(2)',
        keywords: ['food', 'pear', '2', 'fruit', 'juicy', 'sweet', 'fresh', 'variant', 'green'],
      },
      {
        name: 'peas',
        keywords: ['food', 'peas', 'vegetable', 'green', 'sweet', 'small', 'fresh', 'pods'],
      },
      {
        name: 'pepper',
        keywords: ['food', 'pepper', 'vegetable', 'bell', 'colorful', 'sweet', 'crunchy', 'fresh'],
      },
      {
        name: 'persimmon',
        keywords: ['food', 'persimmon', 'fruit', 'orange', 'sweet', 'autumn', 'juicy', 'soft'],
      },
      {
        name: 'pineapple',
        keywords: ['food', 'pineapple', 'fruit', 'tropical', 'sweet', 'juicy', 'yellow', 'fresh'],
      },
      {
        name: 'pineapple-1',
        keywords: ['food', 'pineapple', 'fruit', 'tropical', 'sweet', 'juicy', 'yellow', 'fresh'],
      },
      {
        name: 'pineapple-slice',
        keywords: ['food', 'pineapple', 'slice', 'tropical', 'sweet', 'juicy', 'yellow', 'fresh'],
      },
      {
        name: 'pineapple-yellow',
        keywords: ['food', 'pineapple', 'yellow', 'tropical', 'sweet', 'juicy', 'fruit', 'fresh'],
      },
      {
        name: 'pizza',
        keywords: ['food', 'pizza', 'cheese', 'tomato', 'crust', 'slice', 'savory', 'topping'],
      },
      {
        name: 'plum',
        keywords: ['food', 'plum', 'fruit', 'purple', 'sweet', 'juicy', 'fresh', 'summer'],
      },
      {
        name: 'plum-1',
        keywords: ['food', 'plum', 'fruit', 'purple', 'sweet', 'juicy', 'fresh', 'summer'],
      },
      {
        name: 'pomegranate',
        keywords: ['food', 'pomegranate', 'fruit', 'red', 'seeds', 'tart', 'juicy', 'antioxidant'],
      },
      {
        name: 'pomegranate',
        keywords: [
          'food',
          'pomegrenate',
          '(misspelling)',
          'pomegranate',
          'fruit',
          'red',
          'seeds',
          'tart',
          'juicy',
          'antioxidant',
        ],
      },
      {
        name: 'pomegranate-1',
        keywords: [
          'food',
          'pomegrenate',
          '(misspelling)',
          'pomegranate',
          'fruit',
          'red',
          'seeds',
          'tart',
          'juicy',
          'antioxidant',
        ],
      },
      {
        name: 'popcorn',
        keywords: ['food', 'popcorn', 'snack', 'corn', 'popped', 'salty', 'buttery', 'movie'],
      },
      {
        name: 'popcorn-movie-cinema',
        keywords: ['food', 'popcorn', 'movie', 'cinema', 'snack', 'buttery', 'salty', 'treat', 'entertainment'],
      },
      {
        name: 'porridge',
        keywords: ['food', 'porridge', 'grain', 'oatmeal', 'warm', 'breakfast', 'comfort', 'soft'],
      },
      {
        name: 'potato',
        keywords: ['food', 'potato', 'vegetable', 'starch', 'tuber', 'mashed', 'fried', 'hearty'],
      },
      {
        name: 'potoffood',
        keywords: ['food', 'potoffood', 'pot', 'of', 'food', 'meal', 'cooking', 'hearty', 'warm'],
      },
      {
        name: 'poultryleg',
        keywords: ['food', 'poultryleg', 'poultry', 'leg', 'meat', 'roasted', 'savory', 'protein'],
      },
      {
        name: 'pretzel',
        keywords: ['food', 'pretzel', 'bread', 'salty', 'baked', 'twist', 'snack', 'crunchy'],
      },
      {
        name: 'pumpkin-salad-vegetables',
        keywords: ['food', 'pumpkin', 'salad', 'vegetables', 'orange', 'sweet', 'fresh', 'autumn', 'cooking'],
      },
      {
        name: 'radish',
        keywords: ['food', 'radish', 'vegetable', 'red', 'crunchy', 'peppery', 'fresh', 'salad'],
      },
      {
        name: 'raspberry',
        keywords: ['food', 'raspberry', 'fruit', 'berry', 'red', 'sweet', 'tart', 'fresh'],
      },
      {
        name: 'red-onion',
        keywords: ['food', 'red', 'onion', 'vegetable', 'aromatic', 'pungent', 'salad', 'flavor'],
      },
      {
        name: 'redapple',
        keywords: ['food', 'redapple', 'red', 'apple', 'fruit', 'sweet', 'crisp', 'fresh'],
      },
      {
        name: 'roastedsweetpotato',
        keywords: ['food', 'roastedsweetpotato', 'roasted', 'sweet', 'potato', 'root', 'caramelized', 'warm'],
      },
      {
        name: 'salad-seaweed-vegetables',
        keywords: ['food', 'salad', 'seaweed', 'vegetables', 'green', 'fresh', 'umami', 'healthy', 'light'],
      },
      {
        name: 'sandwich',
        keywords: ['food', 'sandwich', 'bread', 'filling', 'savory', 'lunch', 'deli', 'meal'],
      },
      {
        name: 'sesame',
        keywords: ['food', 'sesame', 'seed', 'nutty', 'topping', 'oil', 'aromatic', 'small'],
      },
      {
        name: 'shavedice',
        keywords: ['food', 'shavedice', 'shave', 'ice', 'dessert', 'sweet', 'cold', 'summer', 'treat'],
      },
      {
        name: 'shell',
        keywords: ['food', 'shell', 'seafood', 'oyster', 'clam', 'marine', 'savory', 'fresh'],
      },
      {
        name: 'shish-kebab',
        keywords: ['food', 'shish', 'kebab', 'meat', 'grilled', 'skewer', 'savory', 'middleeastern'],
      },
      {
        name: 'shortcake',
        keywords: ['food', 'shortcake', 'dessert', 'sweet', 'berry', 'cream', 'cake', 'treat'],
      },
      {
        name: 'sliceofpizza',
        keywords: ['food', 'sliceofpizza', 'slice', 'of', 'pizza', 'cheese', 'tomato', 'savory', 'topping'],
      },
      {
        name: 'soda-coke',
        keywords: ['food', 'soda', 'coke', 'cola', 'carbonated', 'sweet', 'drink', 'refreshing', 'bubbly'],
      },
      {
        name: 'softicecream',
        keywords: ['food', 'softicecream', 'soft', 'icecream', 'dessert', 'sweet', 'cold', 'creamy', 'cone'],
      },
      {
        name: 'spaghetti',
        keywords: ['food', 'spaghetti', 'pasta', 'italian', 'noodles', 'tomato', 'savory', 'meal'],
      },
      {
        name: 'stake',
        keywords: ['food', 'stake', 'unknown', 'possibly', 'meat', 'misspelling', 'steak', 'protein', 'savory'],
      },
      {
        name: 'steak',
        keywords: ['food', 'steak', 'beef', 'meat', 'grilled', 'savory', 'protein', 'hearty'],
      },
      {
        name: 'steamingbowl',
        keywords: ['food', 'steamingbowl', 'steaming', 'bowl', 'hot', 'soup', 'aromatic', 'comfort'],
      },
      {
        name: 'strawberry',
        keywords: ['food', 'strawberry', 'fruit', 'red', 'sweet', 'juicy', 'berry', 'fresh'],
      },
      {
        name: 'strawberry-big',
        keywords: ['food', 'strawberry', 'big', 'fruit', 'red', 'sweet', 'juicy', 'berry', 'fresh'],
      },
      {
        name: 'sugar',
        keywords: ['food', 'sugar', 'sweetener', 'granulated', 'sweet', 'ingredient', 'dessert', 'crystals'],
      },
      {
        name: 'sugar-1',
        keywords: ['food', 'sugar', 'sweetener', 'granulated', 'sweet', 'ingredient', 'dessert', 'crystals'],
      },
      {
        name: 'summer-jug-juice',
        keywords: ['food', 'summer', 'jug', 'juice', 'refreshing', 'fruit', 'cool', 'drink', 'sweet'],
      },
      {
        name: 'sushi',
        keywords: ['food', 'sushi', 'japanese', 'fish', 'rice', 'seaweed', 'raw', 'fresh'],
      },
      {
        name: 'sushi-1',
        keywords: ['food', 'sushi', 'japanese', 'fish', 'rice', 'seaweed', 'raw', 'fresh'],
      },
      {
        name: 'sushi(2)',
        keywords: ['food', 'sushi', '2', 'japanese', 'fish', 'rice', 'raw', 'fresh', 'umami'],
      },
      {
        name: 'sushi-plate',
        keywords: ['food', 'sushi', 'plate', 'japanese', 'fish', 'rice', 'seaweed', 'assortment', 'fresh'],
      },
      {
        name: 'sweet-corn',
        keywords: ['food', 'sweet', 'corn', 'vegetable', 'yellow', 'fresh', 'cob', 'tasty'],
      },
      {
        name: 'taco',
        keywords: ['food', 'taco', 'mexican', 'corn', 'tortilla', 'savory', 'filling', 'spicy'],
      },
      {
        name: 'taco-big',
        keywords: ['food', 'taco', 'big', 'mexican', 'corn', 'tortilla', 'savory', 'filling', 'spicy'],
      },
      {
        name: 'tangerine',
        keywords: ['food', 'tangerine', 'citrus', 'fruit', 'sweet', 'juicy', 'orange', 'easy-peel'],
      },
      {
        name: 'tapas',
        keywords: ['food', 'tapas', 'spanish', 'small', 'plates', 'savory', 'variety', 'appetizers'],
      },
      {
        name: 'tea-cup',
        keywords: ['food', 'tea', 'cup', 'mug', 'hot', 'brew', 'aromatic', 'relaxing', 'drink'],
      },
      {
        name: 'tea-kettle',
        keywords: ['food', 'tea', 'kettle', 'hot', 'water', 'brew', 'aromatic', 'beverage', 'warm'],
      },
      {
        name: 'teacupwithouthandle',
        keywords: ['food', 'teacupwithouthandle', 'tea', 'cup', 'without', 'handle', 'hot', 'beverage', 'brew'],
      },
      {
        name: 'thanksgiving',
        keywords: ['food', 'thanksgiving', 'holiday', 'feast', 'turkey', 'meal', 'family', 'autumn'],
      },
      {
        name: 'tomato',
        keywords: ['food', 'tomato', 'fruit', 'red', 'juicy', 'savory', 'salad', 'fresh'],
      },
      {
        name: 'tomato-big',
        keywords: ['food', 'tomato', 'big', 'fruit', 'red', 'juicy', 'fresh', 'savory'],
      },
      {
        name: 'tropicaldrink',
        keywords: ['food', 'tropicaldrink', 'tropical', 'drink', 'fruit', 'sweet', 'refreshing', 'beach'],
      },
      {
        name: 'waffles',
        keywords: ['food', 'waffles', 'breakfast', 'sweet', 'batter', 'syrup', 'crisp', 'treat'],
      },
      {
        name: 'watermelon',
        keywords: ['food', 'watermelon', 'fruit', 'red', 'juicy', 'sweet', 'summer', 'refreshing'],
      },
      {
        name: 'watermelon-slice',
        keywords: ['food', 'watermelon', 'slice', 'fruit', 'red', 'juicy', 'sweet', 'summer'],
      },
      {
        name: 'wine-glass',
        keywords: ['food', 'wine', 'glass', 'alcohol', 'drink', 'grape', 'fermented', 'dinner'],
      },
      {
        name: 'wineglass',
        keywords: ['food', 'wineglass', 'wine', 'glass', 'alcohol', 'grape', 'fermented', 'dinner', 'elegant'],
      },
    ],
  },

  {
    name: 'sports',
    icons: [
      {
        name: 'americanfootball',
        keywords: ['sports', 'americanfootball', 'gridiron', 'pigskin', 'usa', 'touchdown', 'team'],
      },
      {
        name: 'anchor',
        keywords: ['sports', 'anchor', 'boat', 'stability', 'hold', 'marine', 'secure'],
      },
      {
        name: 'artistpalette',
        keywords: ['sports', 'artistpalette', 'art', 'paint', 'color', 'creativity', 'brush'],
      },
      {
        name: 'ball',
        keywords: ['sports', 'ball', 'round', 'bounce', 'play', 'game', 'sphere'],
      },
      {
        name: 'banana-ride',
        keywords: ['sports', 'banana', 'ride', 'inflatable', 'water', 'fun', 'tow', 'boat'],
      },
      {
        name: 'baseball',
        keywords: ['sports', 'baseball', 'mlb', 'pitch', 'hit', 'field', 'diamond'],
      },
      {
        name: 'baseball-1',
        keywords: ['sports', 'baseball', 'mlb', 'pitch', 'hit', 'field', 'diamond'],
      },
      {
        name: 'baseball-bat',
        keywords: ['sports', 'baseball', 'bat', 'wood', 'swing', 'hitting', 'game', 'hardball'],
      },
      {
        name: 'basketball',
        keywords: ['sports', 'basketball', 'nba', 'hoop', 'dribble', 'court', 'shoot'],
      },
      {
        name: 'basketballandhoop',
        keywords: ['sports', 'basketballandhoop', 'basketball', 'hoop', 'net', 'backboard', 'shot', 'score'],
      },
      {
        name: 'beach-ball',
        keywords: ['sports', 'beach', 'ball', 'summer', 'fun', 'play', 'colorful', 'inflatable'],
      },
      {
        name: 'beach-soccer',
        keywords: ['sports', 'beach', 'soccer', 'sand', 'goal', 'foot', 'kick', 'summer'],
      },
      {
        name: 'beach-umbrella',
        keywords: ['sports', 'beach', 'umbrella', 'shade', 'sun', 'protection', 'vacation', 'relax'],
      },
      {
        name: 'beach-volleyball',
        keywords: ['sports', 'beach', 'volleyball', 'sand', 'net', 'serve', 'dive', 'rally'],
      },
      {
        name: 'bicycle',
        keywords: ['sports', 'bicycle', 'bike', 'pedal', 'wheels', 'ride', 'outdoor', 'road'],
      },
      {
        name: 'bicycle-1',
        keywords: ['sports', 'bicycle', 'bike', 'pedal', 'wheels', 'ride', 'outdoor', 'road'],
      },
      {
        name: 'bicyclist',
        keywords: ['sports', 'bicyclist', 'bike', 'rider', 'pedal', 'helmet', 'race', 'fit'],
      },
      {
        name: 'billiards',
        keywords: ['sports', 'billiards', 'cue', 'table', 'balls', 'pockets', 'chalk', 'aim'],
      },
      {
        name: 'bodyboard',
        keywords: ['sports', 'bodyboard', 'wave', 'surf', 'ocean', 'ride', 'beach', 'water'],
      },
      {
        name: 'boomerang',
        keywords: ['sports', 'boomerang', 'throw', 'return', 'catch', 'flight', 'australia', 'outdoor'],
      },
      {
        name: 'bowling',
        keywords: ['sports', 'bowling', 'alley', 'pins', 'ball', 'strike', 'spare', 'lane'],
      },
      {
        name: 'bowling-pin',
        keywords: ['sports', 'bowling', 'pin', 'alley', 'target', 'knockdown', 'ten', 'rolling'],
      },
      {
        name: 'boxing-glove',
        keywords: ['sports', 'boxing', 'glove', 'punch', 'ring', 'fighter', 'spar', 'protection'],
      },
      {
        name: 'camping-tent',
        keywords: ['sports', 'camping', 'tent', 'outdoor', 'camp', 'sleep', 'nature', 'trip'],
      },
      {
        name: 'canoe-slalom',
        keywords: ['sports', 'canoe', 'slalom', 'water', 'rapids', 'paddle', 'gates', 'river'],
      },
      {
        name: 'catamaran',
        keywords: ['sports', 'catamaran', 'boat', 'sail', 'water', 'ocean', 'multi-hull', 'speed'],
      },
      {
        name: 'chess-game-sport',
        keywords: ['sports', 'chess', 'game', 'sport', 'board', 'strategy', 'mind', 'pieces', 'checkmate'],
      },
      {
        name: 'chess-timer-time',
        keywords: ['sports', 'chess', 'timer', 'time', 'clock', 'strategy', 'competition', 'limit', 'moves'],
      },
      {
        name: 'cliff-jumping',
        keywords: ['sports', 'cliff', 'jumping', 'dive', 'extreme', 'height', 'adrenaline', 'courage'],
      },
      {
        name: 'diving',
        keywords: ['sports', 'diving', 'water', 'jump', 'pool', 'springboard', 'platform', 'splash'],
      },
      {
        name: 'drag-boat',
        keywords: ['sports', 'drag', 'boat', 'racing', 'speed', 'water', 'engine', 'competition'],
      },
      {
        name: 'dumbbell',
        keywords: ['sports', 'dumbbell', 'weight', 'lifting', 'gym', 'strength', 'muscle', 'exercise'],
      },
      {
        name: 'energy-bottle',
        keywords: ['sports', 'energy', 'bottle', 'drink', 'hydration', 'electrolytes', 'rehydrate', 'fuel'],
      },
      {
        name: 'exercise',
        keywords: ['sports', 'exercise', 'workout', 'fitness', 'health', 'movement', 'routine', 'burn'],
      },
      {
        name: 'exercise-bike',
        keywords: ['sports', 'exercise', 'bike', 'stationary', 'cycling', 'cardio', 'fitness', 'pedal'],
      },
      {
        name: 'expander',
        keywords: ['sports', 'expander', 'fitness', 'stretch', 'resistance', 'strength', 'pull', 'muscle'],
      },
      {
        name: 'expander-handgrip',
        keywords: ['sports', 'expander', 'handgrip', 'fitness', 'forearm', 'strength', 'squeeze', 'exercise'],
      },
      {
        name: 'fishingpoleandfish',
        keywords: ['sports', 'fishingpoleandfish', 'fishing', 'pole', 'fish', 'catch', 'water', 'outdoor'],
      },
      {
        name: 'flyboard',
        keywords: ['sports', 'flyboard', 'water', 'jet', 'hover', 'extreme', 'hoverboard', 'stunt'],
      },
      {
        name: 'football',
        keywords: ['sports', 'football', 'gridiron', 'pass', 'touchdown', 'team', 'pigskin', 'helmet'],
      },
      {
        name: 'football-helmet',
        keywords: ['sports', 'football', 'helmet', 'protection', 'safety', 'gear', 'player', 'head'],
      },
      {
        name: 'gym',
        keywords: ['sports', 'gym', 'fitness', 'workout', 'weights', 'training', 'indoor', 'equipment'],
      },
      {
        name: 'horseracing',
        keywords: ['sports', 'horseracing', 'horse', 'race', 'jockey', 'track', 'betting', 'speed'],
      },
      {
        name: 'jet-ski',
        keywords: ['sports', 'jet', 'ski', 'water', 'speed', 'ride', 'waves', 'fun', 'motor'],
      },
      {
        name: 'jump-rope',
        keywords: ['sports', 'jump', 'rope', 'fitness', 'cardio', 'skip', 'endurance', 'workout'],
      },
      {
        name: 'kite',
        keywords: ['sports', 'kite', 'fly', 'wind', 'outdoor', 'sky', 'string', 'fun'],
      },
      {
        name: 'kitesurfing',
        keywords: ['sports', 'kitesurfing', 'kite', 'surf', 'wind', 'board', 'ocean', 'adventure', 'extreme'],
      },
      {
        name: 'lockers',
        keywords: ['sports', 'lockers', 'storage', 'school', 'security', 'personal', 'facility', 'gear'],
      },
      {
        name: 'pool',
        keywords: ['sports', 'pool', 'cue', 'table', 'billiards', 'balls', 'chalk', 'shot'],
      },
      {
        name: 'pool-8-ball',
        keywords: ['sports', 'pool', '8', 'ball', 'billiards', 'cue', 'game', 'rack', 'shot'],
      },
      {
        name: 'roller-skates',
        keywords: ['sports', 'roller', 'skates', 'wheels', 'rink', 'glide', 'balance', 'fun', 'indoor'],
      },
      {
        name: 'rowboat',
        keywords: ['sports', 'rowboat', 'row', 'boat', 'oar', 'water', 'lake', 'paddle', 'crew'],
      },
      {
        name: 'rowing',
        keywords: ['sports', 'rowing', 'oar', 'boat', 'water', 'crew', 'team', 'race', 'stroke'],
      },
      {
        name: 'rugbyfootball',
        keywords: ['sports', 'rugbyfootball', 'rugby', 'ball', 'tackle', 'scrum', 'try', 'field'],
      },
      {
        name: 'running',
        keywords: ['sports', 'running', 'jog', 'sprint', 'track', 'endurance', 'foot', 'race'],
      },
      {
        name: 'running-gym',
        keywords: ['sports', 'running', 'gym', 'treadmill', 'cardio', 'fitness', 'sweat', 'endurance'],
      },
      {
        name: 'running-pictogram-girl',
        keywords: ['sports', 'running', 'pictogram', 'girl', 'female', 'track', 'motion', 'exercise', 'icon'],
      },
      {
        name: 'running-pictogram-male',
        keywords: ['sports', 'running', 'pictogram', 'male', 'man', 'track', 'exercise', 'icon', 'motion'],
      },
      {
        name: 'sailing',
        keywords: ['sports', 'sailing', 'boat', 'wind', 'water', 'sea', 'mast', 'navigate', 'marine'],
      },
      {
        name: 'scales',
        keywords: ['sports', 'scales', 'weigh', 'measure', 'health', 'fitness', 'balance', 'check'],
      },
      {
        name: 'scoreboard',
        keywords: ['sports', 'scoreboard', 'score', 'points', 'game', 'match', 'display', 'result'],
      },
      {
        name: 'shoe-soccer',
        keywords: ['sports', 'shoe', 'soccer', 'cleats', 'pitch', 'kick', 'foot', 'gear'],
      },
      {
        name: 'skiandskiboot',
        keywords: ['sports', 'skiandskiboot', 'ski', 'and', 'ski', 'boot', 'snow', 'mountain', 'winter', 'slopes'],
      },
      {
        name: 'skimboarding',
        keywords: ['sports', 'skimboarding', 'board', 'beach', 'shallow', 'wave', 'glide', 'sand'],
      },
      {
        name: 'snorkeling',
        keywords: ['sports', 'snorkeling', 'mask', 'fins', 'water', 'fish', 'reef', 'underwater'],
      },
      {
        name: 'snowboarder',
        keywords: ['sports', 'snowboarder', 'snow', 'board', 'mountain', 'winter', 'tricks', 'slopes'],
      },
      {
        name: 'soccer-ball',
        keywords: ['sports', 'soccer', 'ball', 'fifa', 'kick', 'goal', 'match', 'field'],
      },
      {
        name: 'soccer-field',
        keywords: ['sports', 'soccer', 'field', 'pitch', 'goal', 'grass', 'match', 'team'],
      },
      {
        name: 'speedboat',
        keywords: ['sports', 'speedboat', 'water', 'fast', 'motor', 'racing', 'marine', 'waves'],
      },
      {
        name: 'sport-bag',
        keywords: ['sports', 'sport', 'bag', 'storage', 'equipment', 'gear', 'carry', 'portable'],
      },
      {
        name: 'sports',
        keywords: ['sports', 'sports', 'athletics', 'games', 'competition', 'recreation', 'activity', 'fitness'],
      },
      {
        name: 'stationary-bike',
        keywords: ['sports', 'stationary', 'bike', 'cycling', 'fitness', 'cardio', 'exercise', 'indoor'],
      },
      {
        name: 'stopwatch',
        keywords: ['sports', 'stopwatch', 'timer', 'clock', 'measure', 'interval', 'track', 'seconds'],
      },
      {
        name: 'sup',
        keywords: ['sports', 'sup', 'standup', 'paddleboard', 'water', 'board', 'balance', 'lake', 'ocean'],
      },
      {
        name: 'surfer',
        keywords: ['sports', 'surfer', 'wave', 'ocean', 'board', 'balance', 'ride', 'beach'],
      },
      {
        name: 'surfing',
        keywords: ['sports', 'surfing', 'wave', 'ocean', 'board', 'balance', 'beach', 'water'],
      },
      {
        name: 'swimming(2)',
        keywords: ['sports', 'swimming(2)', 'swimming', 'water', 'pool', 'laps', 'exercise', 'stroke'],
      },
      {
        name: 'swimming-pictogram',
        keywords: ['sports', 'swimming', 'pictogram', 'water', 'icon', 'pool', 'laps', 'dive', 'symbol'],
      },
      {
        name: 'synchronised-swimming',
        keywords: ['sports', 'synchronised', 'swimming', 'water', 'artistic', 'team', 'routine', 'pool'],
      },
      {
        name: 'ping-pong-racket',
        keywords: ['sports', 'table', 'tennis', 'racket', 'and', 'ball', 'ping', 'pong', 'serve', 'volley'],
      },
      {
        name: 'ping-pong',
        keywords: ['sports', 'table', 'tennis', 'table', 'ping', 'pong', 'match', 'net', 'rally'],
      },
      {
        name: 'tennis-ball',
        keywords: ['sports', 'tennis', 'ball', 'court', 'serve', 'volley', 'match', 'bounce'],
      },
      {
        name: 'tennis-racket',
        keywords: ['sports', 'tennis', 'racket', 'swing', 'serve', 'match', 'court', 'strings'],
      },
      {
        name: 'trophy',
        keywords: ['sports', 'trophy', 'win', 'champion', 'award', 'victory', 'prize', 'honor'],
      },
      {
        name: 'volleyball',
        keywords: ['sports', 'volleyball', 'serve', 'spike', 'net', 'court', 'team', 'set'],
      },
      {
        name: 'water-bottle',
        keywords: ['sports', 'water', 'bottle', 'hydration', 'drink', 'container', 'refresh', 'portable'],
      },
      {
        name: 'water-park',
        keywords: ['sports', 'water', 'park', 'slides', 'fun', 'splash', 'family', 'recreation'],
      },
      {
        name: 'water-polo',
        keywords: ['sports', 'water', 'polo', 'ball', 'pool', 'team', 'goal', 'swim'],
      },
      {
        name: 'watch',
        keywords: ['sports', 'watch', 'time', 'wrist', 'timer', 'track', 'lap', 'device'],
      },
      {
        name: 'weight',
        keywords: ['sports', 'weight', 'lift', 'heavy', 'strength', 'metal', 'gym', 'resistance'],
      },
      {
        name: 'whistle',
        keywords: ['sports', 'whistle', 'referee', 'alert', 'signal', 'game', 'foul', 'stop'],
      },
      {
        name: 'whistler',
        keywords: ['sports', 'whistler', 'person', 'whistle', 'sound', 'signal', 'referee', 'alert', 'official'],
      },
      {
        name: 'windsurfing',
        keywords: ['sports', 'windsurfing', 'wind', 'surf', 'board', 'sail', 'ocean', 'balance', 'sport'],
      },
      {
        name: 'wakeboarding',
        keywords: ['sports', 'wakeboarding', 'wawke', 'surf', 'board', 'boat', 'lake', 'balance', 'sport'],
      },
    ],
  },

  {
    name: 'kitchen',
    icons: [
      {
        name: 'apron-chef',
        keywords: ['kitchen', 'apron', 'chef', 'cooking', 'protective', 'wear', 'kitchen', 'garment'],
      },
      {
        name: 'apron-chef-2',
        keywords: ['kitchen', 'apron', 'chef', '2', 'cooking', 'protective', 'wear', 'kitchen', 'garment'],
      },
      {
        name: 'bowl',
        keywords: ['kitchen', 'bowl', 'dish', 'container', 'mixing', 'serving', 'food', 'cooking'],
      },
      {
        name: 'cauldron-halloween-pot',
        keywords: ['kitchen', 'cauldron', 'halloween', 'pot', 'cooking', 'brew', 'large', 'iron', 'stew'],
      },
      {
        name: 'casserole',
        keywords: ['kitchen', 'casserole', 'dish', 'bake', 'oven', 'cooking', 'serve', 'ceramic'],
      },
      {
        name: 'chef',
        keywords: ['kitchen', 'chef', 'cook', 'professional', 'food', 'culinary', 'restaurant', 'kitchen'],
      },
      {
        name: 'chef-knife',
        keywords: ['kitchen', 'chef', 'knife', 'cut', 'slice', 'chop', 'blade', 'cooking', 'tool'],
      },
      {
        name: 'cleaver-knife',
        keywords: ['kitchen', 'cleaver', 'knife', 'butcher', 'cut', 'meat', 'heavy', 'chop', 'blade'],
      },
      {
        name: 'coffee-shop',
        keywords: ['kitchen', 'coffee', 'shop', 'cafe', 'brew', 'beans', 'drink', 'warm', 'aroma'],
      },
      {
        name: 'cutlery',
        keywords: ['kitchen', 'cutlery', 'fork', 'knife', 'spoon', 'utensils', 'dining', 'tableware'],
      },
      {
        name: 'extractor-hood',
        keywords: ['kitchen', 'extractor', 'hood', 'ventilation', 'fan', 'smoke', 'fumes', 'stove', 'filter'],
      },
      {
        name: 'extractor-hood-1',
        keywords: ['kitchen', 'extractor', 'hood', '1', 'ventilation', 'fan', 'smoke', 'fumes', 'stove', 'filter'],
      },
      {
        name: 'faucet-tap',
        keywords: ['kitchen', 'faucet', 'tap', 'water', 'sink', 'plumbing', 'metal', 'wash', 'flow'],
      },
      {
        name: 'food-dome-cover',
        keywords: ['kitchen', 'food', 'dome', 'cover', 'serving', 'protection', 'plate', 'fresh', 'keep'],
      },
      {
        name: 'food-processor',
        keywords: ['kitchen', 'food', 'processor', 'chop', 'blend', 'mix', 'electric', 'appliance', 'prep'],
      },
      {
        name: 'grater',
        keywords: ['kitchen', 'grater', 'shred', 'cheese', 'vegetables', 'tool', 'utensil', 'cook'],
      },
      {
        name: 'grill',
        keywords: ['kitchen', 'grill', 'cook', 'barbecue', 'char', 'heat', 'outdoor', 'metal', 'rack'],
      },
      {
        name: 'grill-bbq',
        keywords: ['kitchen', 'grill', 'bbq', 'barbecue', 'outdoor', 'charcoal', 'cook', 'metal', 'heat'],
      },
      {
        name: 'grill-fish',
        keywords: ['kitchen', 'grill', 'fish', 'cook', 'seafood', 'heat', 'rack', 'flavor', 'outdoor'],
      },
      {
        name: 'grill-rectangle',
        keywords: ['kitchen', 'grill', 'rectangle', 'shape', 'barbecue', 'cook', 'metal', 'heat', 'food'],
      },
      {
        name: 'grill-round',
        keywords: ['kitchen', 'grill', 'round', 'circular', 'barbecue', 'cook', 'metal', 'heat', 'food'],
      },
      {
        name: 'juicer',
        keywords: ['kitchen', 'juicer', 'extract', 'juice', 'fruits', 'vegetables', 'press', 'appliance', 'fresh'],
      },
      {
        name: 'kitchen-board',
        keywords: ['kitchen', 'kitchen', 'board', 'cutting', 'chop', 'prep', 'wood', 'surface', 'food'],
      },
      {
        name: 'masher-garlic',
        keywords: ['kitchen', 'masher', 'garlic', 'crush', 'press', 'tool', 'flavor', 'cooking', 'utensil'],
      },
      {
        name: 'measuring-spoons',
        keywords: [
          'kitchen',
          'measuring',
          'spoons',
          'measure',
          'ingredients',
          'baking',
          'accurate',
          'cooking',
          'utensil',
        ],
      },
      {
        name: 'microwave',
        keywords: ['kitchen', 'microwave', 'heat', 'reheat', 'cook', 'appliance', 'quick', 'electric', 'oven'],
      },
      {
        name: 'microwave-1',
        keywords: ['kitchen', 'microwave', 'heat', 'reheat', 'cook', 'appliance', 'quick', 'electric', 'oven'],
      },
      {
        name: 'mixer-bowl',
        keywords: ['kitchen', 'mixer', 'bowl', 'mix', 'whisk', 'bake', 'dough', 'electric', 'appliance'],
      },
      {
        name: 'mixer-kitchenware',
        keywords: ['kitchen', 'mixer', 'kitchenware', 'blend', 'mix', 'whisk', 'bake', 'appliance', 'electric'],
      },
      {
        name: 'oven',
        keywords: ['kitchen', 'oven', 'bake', 'roast', 'heat', 'appliance', 'cooking', 'electric', 'gas'],
      },
      {
        name: 'oven-1',
        keywords: ['kitchen', 'oven', 'bake', 'roast', 'heat', 'appliance', 'cooking', 'electric', 'gas'],
      },
      {
        name: 'pepper-grinder',
        keywords: ['kitchen', 'pepper', 'grinder', 'spice', 'seasoning', 'mill', 'fresh', 'flavor', 'condiment'],
      },
      {
        name: 'pepper-spice',
        keywords: ['kitchen', 'pepper', 'spice', 'seasoning', 'flavor', 'condiment', 'food', 'cook', 'taste'],
      },
      {
        name: 'plate',
        keywords: ['kitchen', 'plate', 'dish', 'serve', 'food', 'tableware', 'flat', 'ceramic'],
      },
      {
        name: 'plate-1',
        keywords: ['kitchen', 'plate', '1', 'dish', 'serve', 'food', 'tableware', 'flat', 'ceramic'],
      },
      {
        name: 'pot-pressure-cooker',
        keywords: ['kitchen', 'pot', 'pressure', 'cooker', 'cook', 'steam', 'fast', 'appliance', 'sealed'],
      },
      {
        name: 'rice-cooker',
        keywords: ['kitchen', 'rice', 'cooker', 'steam', 'appliance', 'electric', 'grain', 'meal', 'easy'],
      },
      {
        name: 'rolling-pin',
        keywords: ['kitchen', 'rolling', 'pin', 'dough', 'baking', 'flatten', 'pastry', 'wood', 'tool'],
      },
      {
        name: 'sauce-pan',
        keywords: ['kitchen', 'sause', 'pan', 'sauce', 'boil', 'cook', 'liquid', 'stovetop', 'metal'],
      },
      {
        name: 'scissors',
        keywords: ['kitchen', 'scissors', 'cut', 'snip', 'kitchen', 'tool', 'herbs', 'open', 'packets'],
      },
      {
        name: 'soup-ladle',
        keywords: ['kitchen', 'soup', 'ladle', 'serve', 'spoon', 'broth', 'deep', 'bowl', 'liquid'],
      },
      {
        name: 'soup-pot',
        keywords: ['kitchen', 'soup', 'pot', 'cook', 'boil', 'stew', 'broth', 'metal', 'large'],
      },
      {
        name: 'spatula',
        keywords: ['kitchen', 'spatula', 'flip', 'turn', 'tool', 'cook', 'fry', 'bake', 'flat'],
      },
      {
        name: 'steam',
        keywords: ['kitchen', 'steam', 'vapor', 'cooking', 'boil', 'hot', 'moist', 'heat', 'food'],
      },
      {
        name: 'stock-pot',
        keywords: ['kitchen', 'stock', 'pot', 'cook', 'soup', 'broth', 'large', 'metal', 'boil'],
      },
      {
        name: 'stove',
        keywords: ['kitchen', 'stove', 'cooktop', 'heat', 'burner', 'gas', 'electric', 'appliance', 'cooking'],
      },
      {
        name: 'strainer',
        keywords: ['kitchen', 'strainer', 'drain', 'water', 'rinse', 'pasta', 'sieve', 'colander', 'filter'],
      },
      {
        name: 'tap-faucet',
        keywords: ['kitchen', 'tap', 'faucet', 'water', 'sink', 'flow', 'metal', 'plumbing', 'clean'],
      },
      {
        name: 'teapot',
        keywords: ['kitchen', 'teapot', 'brew', 'tea', 'pour', 'ceramic', 'handle', 'spout', 'warm'],
      },
      {
        name: 'toaster',
        keywords: ['kitchen', 'toaster', 'bread', 'toast', 'appliance', 'brown', 'heat', 'electric', 'breakfast'],
      },
      {
        name: 'washing-machine',
        keywords: ['kitchen', 'washing', 'machine', 'laundry', 'clothes', 'appliance', 'spin', 'clean', 'drum'],
      },
      {
        name: 'blender',
        keywords: ['kitchen', 'blender', 'mix', 'puree', 'smoothie', 'appliance', 'electric', 'blade', 'chop'],
      },
    ],
  },

  {
    name: 'nature',
    icons: [
      {
        name: 'basil',
        keywords: ['nature', 'basil', 'herb', 'green', 'aromatic', 'leafy', 'cuisine'],
      },
      {
        name: 'beach',
        keywords: ['nature', 'beach', 'sand', 'coast', 'ocean', 'sun', 'relax'],
      },
      {
        name: 'beach',
        keywords: ['nature', 'beach', 'sand', 'coast', 'ocean', 'sun', 'relax'],
      },
      {
        name: 'beach-umbrella',
        keywords: ['nature', 'beach', 'umbrella', 'shade', 'protection', 'vacation', 'tropical'],
      },
      {
        name: 'bonsai-garden-gardening',
        keywords: ['nature', 'bonsai', 'garden', 'gardening', 'miniature', 'trees', 'artistry', 'zen'],
      },
      {
        name: 'bush',
        keywords: ['nature', 'bush', 'shrub', 'green', 'foliage', 'landscape', 'plant'],
      },
      {
        name: 'cactus-1',
        keywords: ['nature', 'cactus', 'desert', 'succulent', 'spines', 'arid', 'resilient'],
      },
      {
        name: 'cactus',
        keywords: ['nature', 'cactus', 'desert', 'succulent', 'spines', 'arid', 'resilient'],
      },
      {
        name: 'christmas-cold-snowflake',
        keywords: ['nature', 'christmas', 'cold', 'snowflake', 'winter', 'festive', 'holiday', 'icy'],
      },
      {
        name: 'christmas-decoration-pine',
        keywords: ['nature', 'christmas', 'decoration', 'pine', 'evergreen', 'festive', 'holiday', 'ornament'],
      },
      {
        name: 'drop-thunder',
        keywords: ['nature', 'drop', 'thunder', 'storm', 'rain', 'electric', 'weather', 'dramatic'],
      },
      {
        name: 'earth',
        keywords: ['nature', 'earth', 'planet', 'world', 'global', 'environment', 'ecosystem', 'blue'],
      },
      {
        name: 'earth-drop',
        keywords: ['nature', 'earth', 'drop', 'water', 'environment', 'life', 'resource', 'precious'],
      },
      {
        name: 'earth-thunder',
        keywords: ['nature', 'earth', 'thunder', 'storm', 'energy', 'atmosphere', 'electric', 'weather'],
      },
      {
        name: 'flower',
        keywords: ['nature', 'flower', 'bloom', 'petals', 'floral', 'garden', 'colorful', 'beauty'],
      },
      {
        name: 'flower-colors',
        keywords: ['nature', 'flower', 'colors', 'vibrant', 'variety', 'floral', 'petals', 'garden'],
      },
      {
        name: 'flowers',
        keywords: ['nature', 'flowers', 'blooms', 'floral', 'colorful', 'petals', 'garden', 'spring'],
      },
      {
        name: 'four-leaf-clover',
        keywords: ['nature', 'four', 'leaf', 'clover', 'lucky', 'green', 'rare', 'symbol', 'fortune'],
      },
      {
        name: 'gallows-halloween-horror',
        keywords: ['nature', 'gallows', 'halloween', 'horror', 'spooky', 'tree', 'eerie', 'autumn'],
      },
      {
        name: 'garden',
        keywords: ['nature', 'garden', 'plants', 'green', 'outdoors', 'cultivate', 'relax', 'flowers'],
      },
      {
        name: 'garden-1',
        keywords: ['nature', 'garden', '1', 'plants', 'green', 'outdoors', 'cultivate', 'growth'],
      },
      {
        name: 'garden-scenery',
        keywords: ['nature', 'garden', 'scenery', 'landscape', 'serene', 'green', 'floral', 'relaxation'],
      },
      {
        name: 'gardening-plant',
        keywords: ['nature', 'gardening', 'plant', 'grow', 'horticulture', 'care', 'outdoors', 'soil'],
      },
      {
        name: 'hibiscus',
        keywords: ['nature', 'hibiscus', 'flower', 'tropical', 'colorful', 'exotic', 'petals', 'ornamental'],
      },
      {
        name: 'lastquartermoon',
        keywords: ['nature', 'lastquartermoon', 'moon', 'phase', 'night', 'sky', 'astronomy', 'lunar'],
      },
      {
        name: 'leaffluttering',
        keywords: ['nature', 'leaffluttering', 'leaf', 'fluttering', 'breeze', 'gentle', 'green', 'motion'],
      },
      {
        name: 'leaves',
        keywords: ['nature', 'leaves', 'foliage', 'green', 'trees', 'fall', 'deciduous', 'rustle'],
      },
      {
        name: 'mapleleaf',
        keywords: ['nature', 'mapleleaf', 'maple', 'leaf', 'autumn', 'red', 'symbol', 'canada'],
      },
      {
        name: 'milkyway',
        keywords: ['nature', 'milkyway', 'galaxy', 'stars', 'night', 'cosmos', 'astronomy', 'space'],
      },
      {
        name: 'mountain-with-flag',
        keywords: ['nature', 'mountain', 'with', 'flag', 'peak', 'summit', 'achievement', 'landscape', 'tall'],
      },
      {
        name: 'mountfuji',
        keywords: ['nature', 'mountfuji', 'mountain', 'japan', 'iconic', 'scenic', 'volcano', 'summit'],
      },
      {
        name: 'mountains-hills',
        keywords: ['nature', 'mountains', 'hills', 'elevation', 'landscape', 'scenery', 'green', 'outdoors'],
      },
      {
        name: 'mushrooms',
        keywords: ['nature', 'mushroom', 'fungus', 'forest', 'edible', 'cap', 'woodland', 'organic'],
      },
      {
        name: 'mushroom-2',
        keywords: ['nature', 'mushroom', '2', 'fungi', 'foraging', 'woodland', 'edible', 'natural', 'cap'],
      },
      {
        name: 'nationalpark',
        keywords: ['nature', 'nationalpark', 'protected', 'area', 'wildlife', 'scenery', 'recreation', 'forest'],
      },
      {
        name: 'newmoon',
        keywords: ['nature', 'newmoon', 'moon', 'phase', 'night', 'dark', 'astronomy', 'cycle'],
      },
      {
        name: 'palmtree',
        keywords: ['nature', 'palmtree', 'tropical', 'beach', 'fronds', 'coconut', 'warm', 'exotic'],
      },
      {
        name: 'pinedecoration',
        keywords: ['nature', 'pinedecoration', 'pine', 'decoration', 'evergreen', 'ornament', 'festive', 'forest'],
      },
      {
        name: 'plant-garden',
        keywords: ['nature', 'plant', 'garden', 'botanical', 'greenery', 'grow', 'flora', 'cultivate'],
      },
      {
        name: 'plant-garden-1',
        keywords: ['nature', 'plant', 'garden', '1', 'horticulture', 'greenery', 'grow', 'flora', 'outdoors'],
      },
      {
        name: 'potted',
        keywords: ['nature', 'potted', 'plant', 'container', 'indoor', 'decorative', 'green', 'home'],
      },
      {
        name: 'raincloud',
        keywords: ['nature', 'raincloud', 'rain', 'cloud', 'water', 'precipitation', 'grey', 'weather'],
      },
      {
        name: 'rose-1',
        keywords: ['nature', 'rose', 'flower', 'petals', 'fragrant', 'romantic', 'red', 'thorny'],
      },
      {
        name: 'rose',
        keywords: ['nature', 'rose', 'flower', 'petals', 'fragrant', 'romantic', 'red', 'thorny'],
      },
      {
        name: 'roses',
        keywords: ['nature', 'roses', 'flowers', 'petals', 'bouquet', 'love', 'fragrance', 'ornamental'],
      },
      {
        name: 'rosette',
        keywords: ['nature', 'rosette', 'flower', 'design', 'circular', 'petals', 'ornament', 'floral'],
      },
      {
        name: 'seed',
        keywords: ['nature', 'seed', 'plant', 'growth', 'germinate', 'sow', 'beginning', 'sprout'],
      },
      {
        name: 'seedling-1',
        keywords: ['nature', 'seedling', 'young', 'plant', 'sprout', 'growth', 'green', 'new'],
      },
      {
        name: 'seedling',
        keywords: ['nature', 'seedling', 'young', 'plant', 'sprout', 'growth', 'green', 'new'],
      },
      {
        name: 'silhouetteohjapan',
        keywords: ['nature', 'silhouetteohjapan', 'silhouette', 'japan', 'scenic', 'cultural', 'skyline', 'mountain'],
      },
      {
        name: 'snow-showers',
        keywords: ['nature', 'snow', 'showers', 'winter', 'flakes', 'cold', 'weather', 'precipitation'],
      },
      {
        name: 'snow-showers-at-night',
        keywords: ['nature', 'snow', 'showers', 'at', 'night', 'winter', 'cold', 'weather', 'dark'],
      },
      {
        name: 'snowcloud',
        keywords: ['nature', 'snowcloud', 'snow', 'cloud', 'winter', 'white', 'cold', 'sky'],
      },
      {
        name: 'snowflake',
        keywords: ['nature', 'snowflake', 'winter', 'crystal', 'unique', 'cold', 'delicate', 'weather'],
      },
      {
        name: 'snowmountains',
        keywords: ['nature', 'snowmountains', 'snow', 'mountains', 'peaks', 'cold', 'scenic', 'alpine'],
      },
      {
        name: 'stormcloud',
        keywords: ['nature', 'stormcloud', 'storm', 'cloud', 'dark', 'rain', 'thunder', 'weather'],
      },
      {
        name: 'succulents',
        keywords: ['nature', 'succulents', 'plants', 'fleshy', 'dry', 'decorative', 'adapted', 'green'],
      },
      {
        name: 'summer-palm-tree',
        keywords: ['nature', 'summer', 'palm', 'tree', 'tropical', 'beach', 'vacation', 'fronds', 'warm'],
      },
      {
        name: 'sun',
        keywords: ['nature', 'sun', 'star', 'bright', 'light', 'warmth', 'day', 'solar'],
      },
      {
        name: 'sun-behind-cloud',
        keywords: ['nature', 'sun', 'behind', 'cloud', 'partly', 'sunny', 'sky', 'weather', 'mild'],
      },
      {
        name: 'sun-behind-large-cloud',
        keywords: ['nature', 'sun', 'behind', 'large', 'cloud', 'mostly', 'cloudy', 'weather', 'sky', 'shade'],
      },
      {
        name: 'sun-behind-rain-cloud',
        keywords: ['nature', 'sun', 'behind', 'rain', 'cloud', 'showers', 'weather', 'mixed', 'sky'],
      },
      {
        name: 'sun-behind-small-cloud',
        keywords: ['nature', 'sun', 'behind', 'small', 'cloud', 'partly', 'cloudy', 'weather', 'mild', 'sky'],
      },
      {
        name: 'sunflower',
        keywords: ['nature', 'sunflower', 'flower', 'yellow', 'tall', 'field', 'summer', 'bright'],
      },
      {
        name: 'sunraincloud',
        keywords: ['nature', 'sunraincloud', 'sun', 'rain', 'cloud', 'weather', 'mixed', 'sky', 'showers'],
      },
      {
        name: 'sunrise',
        keywords: ['nature', 'sunrise', 'dawn', 'morning', 'horizon', 'light', 'beautiful', 'daybreak'],
      },
      {
        name: 'sunriseovermountains',
        keywords: [
          'nature',
          'sunriseovermountains',
          'sunrise',
          'over',
          'mountains',
          'scenic',
          'dawn',
          'majestic',
          'view',
        ],
      },
      {
        name: 'sweatsplash',
        keywords: ['nature', 'sweatsplash', 'sweat', 'splash', 'water', 'exertion', 'hot', 'drops'],
      },
      {
        name: 'tanabatatree',
        keywords: ['nature', 'tanabatatree', 'tanabata', 'tree', 'festival', 'japan', 'decorations', 'wish', 'bamboo'],
      },
      {
        name: 'tent',
        keywords: ['nature', 'tent', 'camp', 'outdoors', 'shelter', 'fabric', 'travel', 'recreation', 'adventure'],
      },
      {
        name: 'thermometer-cold',
        keywords: ['nature', 'thermometer', 'cold', 'temperature', 'chilly', 'measure', 'weather', 'frost'],
      },
      {
        name: 'thermometer-hot',
        keywords: ['nature', 'thermometer', 'hot', 'temperature', 'warm', 'measure', 'weather', 'heat'],
      },
      {
        name: 'tornado',
        keywords: ['nature', 'tornado', 'twister', 'wind', 'storm', 'funnel', 'destructive', 'weather'],
      },
      {
        name: 'treant-tree',
        keywords: ['nature', 'treant', 'tree', 'mythical', 'living', 'forest', 'fantasy', 'woodland', 'green'],
      },
      {
        name: 'tree',
        keywords: ['nature', 'tree', 'plant', 'trunk', 'leaves', 'forest', 'growth', 'oxygen', 'shade'],
      },
      {
        name: 'tree-3',
        keywords: ['nature', 'tree', 'plant', 'trunk', 'leaves', 'forest', 'growth', 'oxygen', 'shade'],
      },
      {
        name: 'tree-1',
        keywords: ['nature', 'tree', '1', 'wood', 'leaves', 'shade', 'ecology', 'green', 'forest'],
      },
      {
        name: 'tree-2',
        keywords: ['nature', 'tree', '2', 'forest', 'green', 'biodiversity', 'leaves', 'ecology', 'nature'],
      },
      {
        name: 'tulip',
        keywords: ['nature', 'tulip', 'flower', 'spring', 'colorful', 'bulb', 'garden', 'bloom'],
      },
      {
        name: 'vase',
        keywords: ['nature', 'vase', 'container', 'flowers', 'decor', 'porcelain', 'arrangement', 'display'],
      },
      {
        name: 'volcano',
        keywords: ['nature', 'volcano', 'eruption', 'lava', 'magma', 'mountain', 'geology', 'dramatic'],
      },
      {
        name: 'waningbibbousmoon',
        keywords: ['nature', 'waningbibbousmoon', 'waning', 'gibbous', 'moon', 'phase', 'astronomy', 'night', 'lunar'],
      },
      {
        name: 'waningcrescentmoon',
        keywords: ['nature', 'waningcrescentmoon', 'waning', 'crescent', 'moon', 'phase', 'night', 'sky', 'lunar'],
      },
      {
        name: 'waterfall',
        keywords: ['nature', 'waterfall', 'cascading', 'water', 'scenic', 'force', 'rocks', 'majestic', 'river'],
      },
      {
        name: 'wave-1',
        keywords: ['nature', 'wave', 'water', 'ocean', 'sea', 'tide', 'surf', 'motion', 'fluid'],
      },
      {
        name: 'wave',
        keywords: ['nature', 'wave', 'water', 'ocean', 'sea', 'tide', 'surf', 'motion', 'fluid'],
      },
      {
        name: 'watering-can-water',
        keywords: ['nature', 'watering', 'can', 'water', 'irrigate', 'plants', 'garden', 'pour', 'nurture'],
      },
      {
        name: 'waypoint-map',
        keywords: ['nature', 'waypoint', 'map', 'navigation', 'location', 'direction', 'travel', 'terrain', 'guide'],
      },
      {
        name: 'waxingcrescentmoon',
        keywords: ['nature', 'waxingcrescentmoon', 'waxing', 'crescent', 'moon', 'phase', 'night', 'sky', 'lunar'],
      },
      {
        name: 'waxinggibbousmoon',
        keywords: ['nature', 'waxinggibbousmoon', 'waxing', 'gibbous', 'moon', 'phase', 'night', 'sky', 'lunar'],
      },
      {
        name: 'whitesun',
        keywords: ['nature', 'whitesun', 'white', 'sun', 'bright', 'day', 'light', 'sky', 'celestial'],
      },
      {
        name: 'whitesunbehindcloud',
        keywords: ['nature', 'whitesunbehindcloud', 'white', 'sun', 'behind', 'cloud', 'mild', 'sky', 'weather'],
      },
      {
        name: 'whitesunsmallcloud',
        keywords: ['nature', 'whitesunsmallcloud', 'white', 'sun', 'small', 'cloud', 'partial', 'sky', 'weather'],
      },
      {
        name: 'wind-rose',
        keywords: ['nature', 'wind', 'rose', 'compass', 'direction', 'breeze', 'navigation', 'symbol', 'map'],
      },
      {
        name: 'world-map-yellow',
        keywords: ['nature', 'world', 'map', 'yellow', 'continents', 'global', 'geography', 'atlas', 'planet'],
      },
      {
        name: 'worldmap',
        keywords: ['nature', 'worldmap', 'world', 'map', 'globe', 'geography', 'atlas', 'countries', 'planet'],
      },
    ],
  },

  {
    name: 'vehicles',
    icons: [
      {
        name: '4-star-hotel',
        keywords: ['vehicles', '4', 'star', 'hotel', 'travel', 'accommodation', 'luxury', 'transport', 'stay'],
      },
      {
        name: 'airplane-1',
        keywords: ['vehicles', 'airplane', '1', 'flight', 'air', 'travel', 'jet', 'sky', 'international'],
      },
      {
        name: 'airplane_landing-1',
        keywords: ['vehicles', 'airplane', 'landing', '1', 'approach', 'runway', 'touchdown', 'aeroplane', 'airport'],
      },
      {
        name: 'airplane_take_off-1',
        keywords: ['vehicles', 'airplane', 'take', 'off', '1', 'depart', 'runway', 'ascent', 'aeroplane', 'airport'],
      },
      {
        name: 'ambulance-1',
        keywords: ['vehicles', 'ambulance', '1', 'emergency', 'medical', 'sirens', 'paramedics', 'hospital', 'help'],
      },
      {
        name: 'articulatedlorry-1',
        keywords: ['vehicles', 'articulatedlorry', '1', 'truck', 'cargo', 'long', 'haul', 'freight', 'highway'],
      },
      {
        name: 'automobile_speedometer-1',
        keywords: [
          'vehicles',
          'automobile',
          'speedometer',
          '1',
          'car',
          'dashboard',
          'instrument',
          'measure',
          'velocity',
        ],
      },
      {
        name: 'batteries_battery-1',
        keywords: ['vehicles', 'batteries', 'battery', '1', 'power', 'electric', 'energy', 'charge', 'supply'],
      },
      {
        name: 'bicycle',
        keywords: ['vehicles', 'bicycle', 'bike', 'pedal', 'eco', 'two-wheeled', 'road', 'urban', 'commute'],
      },
      {
        name: 'bumper_boat-1',
        keywords: ['vehicles', 'bumper', 'boat', '1', 'water', 'amusement', 'ride', 'fun', 'floating', 'collision'],
      },
      {
        name: 'bumper_bumper-1',
        keywords: ['vehicles', 'bumper', 'bumper', '1', 'car', 'protection', 'impact', 'front', 'rear'],
      },
      {
        name: 'bumping_car_vehicle-1',
        keywords: ['vehicles', 'bumping', 'car', 'vehicle', '1', 'amusement', 'ride', 'collision', 'fun', 'bumper'],
      },
      {
        name: 'bus-1',
        keywords: ['vehicles', 'bus', '1', 'public', 'transport', 'passengers', 'urban', 'commute', 'route'],
      },
      {
        name: 'bus-whiteblue-1',
        keywords: [
          'vehicles',
          'bus',
          'whiteblue',
          '1',
          'public',
          'transport',
          'passengers',
          'urban',
          'route',
          'colored',
        ],
      },
      {
        name: 'bus-whitegreen-1',
        keywords: [
          'vehicles',
          'bus',
          'whitegreen',
          '1',
          'public',
          'transport',
          'passengers',
          'urban',
          'route',
          'colored',
        ],
      },
      {
        name: 'cable_car_cabin-1',
        keywords: ['vehicles', 'cable', 'car', 'cabin', '1', 'mountain', 'aerial', 'ropeway', 'scenic', 'transport'],
      },
      {
        name: 'cable_car_cabin_blue-1',
        keywords: ['vehicles', 'cable', 'car', 'cabin', 'blue', '1', 'aerial', 'ropeway', 'scenic', 'ski', 'transport'],
      },
      {
        name: 'car-1',
        keywords: ['vehicles', 'car', '1', 'automobile', 'road', 'drive', 'wheels', 'engine', 'commute'],
      },
      {
        name: 'car2-1',
        keywords: ['vehicles', 'car2', '1', 'automobile', 'variant', 'wheels', 'road', 'drive', 'commute'],
      },
      {
        name: 'car3-1',
        keywords: ['vehicles', 'car3', '1', 'automobile', 'variant', 'wheels', 'road', 'drive', 'commute'],
      },
      {
        name: 'car4-1',
        keywords: ['vehicles', 'car4', '1', 'automobile', 'variant', 'wheels', 'road', 'drive', 'commute'],
      },
      {
        name: 'car5-1',
        keywords: ['vehicles', 'car5', '1', 'automobile', 'variant', 'wheels', 'road', 'drive', 'commute'],
      },
      {
        name: 'car_breakdown-1',
        keywords: ['vehicles', 'car', 'breakdown', '1', 'broken', 'repair', 'tow', 'engine', 'trouble'],
      },
      {
        name: 'car_christmas_transport-1',
        keywords: [
          'vehicles',
          'car',
          'christmas',
          'transport',
          '1',
          'holiday',
          'tree',
          'drive',
          'festive',
          'decoration',
        ],
      },
      {
        name: 'car_key-1',
        keywords: ['vehicles', 'car', 'key', '1', 'unlock', 'ignition', 'security', 'metal', 'fob'],
      },
      {
        name: 'car_wash-1',
        keywords: ['vehicles', 'car', 'wash', '1', 'clean', 'water', 'service', 'soap', 'shine'],
      },
      {
        name: 'christmas-santa-sleigh',
        keywords: [
          'vehicles',
          'christmas',
          'santa',
          'sledge',
          'reindeer',
          'holiday',
          'gifts',
          'snow',
          'magic',
          'festive',
        ],
      },
      {
        name: 'electric_car-1',
        keywords: ['vehicles', 'electric', 'car', '1', 'battery', 'green', 'charge', 'quiet', 'eco'],
      },
      {
        name: 'fire_truck-1',
        keywords: ['vehicles', 'fire', 'truck', '1', 'emergency', 'red', 'ladder', 'water', 'rescue'],
      },
      {
        name: 'helicopter-1',
        keywords: ['vehicles', 'helicopter', '1', 'rotor', 'air', 'landingpad', 'flight', 'transport', 'rescue'],
      },
      {
        name: 'minibus-1',
        keywords: ['vehicles', 'minibus', '1', 'small', 'van', 'passengers', 'group', 'transport', 'tour'],
      },
      {
        name: 'monorail-1',
        keywords: ['vehicles', 'monorail', '1', 'rail', 'singletrack', 'urban', 'commute', 'electric', 'smooth'],
      },
      {
        name: 'motorcycle-1',
        keywords: ['vehicles', 'motorcycle', '1', 'bike', 'engine', 'fast', 'two-wheeled', 'ride', 'road'],
      },
      {
        name: 'motorcycle',
        keywords: ['vehicles', 'motorcycle', 'bike', 'ride', 'engine', 'two-wheeled', 'road', 'speed', 'freedom'],
      },
      {
        name: 'mountainrailway-1',
        keywords: ['vehicles', 'mountainrailway', '1', 'rail', 'mountain', 'scenic', 'steep', 'train', 'tourist'],
      },
      {
        name: 'oncomingautomobile-1',
        keywords: ['vehicles', 'oncomingautomobile', '1', 'car', 'approach', 'headlights', 'drive', 'road', 'front'],
      },
      {
        name: 'oncomingbus-1',
        keywords: ['vehicles', 'oncomingbus', '1', 'bus', 'approach', 'public', 'transport', 'passengers', 'urban'],
      },
      {
        name: 'oncomingpolicecar-1',
        keywords: ['vehicles', 'oncomingpolicecar', '1', 'police', 'car', 'approach', 'emergency', 'sirens', 'law'],
      },
      {
        name: 'oncomingtaxi-1',
        keywords: ['vehicles', 'oncomingtaxi', '1', 'taxi', 'approach', 'yellow', 'cab', 'fare', 'city'],
      },
      {
        name: 'petrol_can_gas-1',
        keywords: ['vehicles', 'petrol', 'can', 'gas', '1', 'fuel', 'storage', 'flammable', 'engine', 'spare'],
      },
      {
        name: 'police_car_transport-1',
        keywords: ['vehicles', 'police', 'car', 'transport', '1', 'law', 'enforcement', 'emergency', 'sirens', 'blue'],
      },
      {
        name: 'policecar-1',
        keywords: ['vehicles', 'policecar', '1', 'law', 'enforcement', 'vehicle', 'sirens', 'patrol', 'safety'],
      },
      {
        name: 'racecar-1',
        keywords: ['vehicles', 'racecar', '1', 'racing', 'fast', 'speed', 'competition', 'track', 'engine'],
      },
      {
        name: 'railwaycar-1',
        keywords: ['vehicles', 'railwaycar', '1', 'rail', 'wagon', 'train', 'cargo', 'passenger', 'tracks'],
      },
      {
        name: 'real_estate_car-1',
        keywords: ['vehicles', 'real', 'estate', 'car', '1', 'property', 'agent', 'house', 'mobile', 'promo'],
      },
      {
        name: 'recreationalvehicle-1',
        keywords: [
          'vehicles',
          'recreationalvehicle',
          '1',
          'rv',
          'motorhome',
          'camping',
          'travel',
          'leisure',
          'roadtrip',
        ],
      },
      {
        name: 'rocket-1',
        keywords: ['vehicles', 'rocket', '1', 'space', 'launch', 'aerospace', 'explore', 'orbit', 'thrust'],
      },
      {
        name: 'sailboat-1',
        keywords: ['vehicles', 'sailboat', '1', 'boat', 'sail', 'wind', 'water', 'yacht', 'marine'],
      },
      {
        name: 'sailboat',
        keywords: ['vehicles', 'sailboat', 'boat', 'water', 'wind', 'marine', 'sail', 'leisure', 'sea'],
      },
      {
        name: 'school_bus-1',
        keywords: ['vehicles', 'school', 'bus', '1', 'children', 'yellow', 'route', 'education', 'transport'],
      },
      {
        name: 'scooter-1',
        keywords: ['vehicles', 'scooter', '1', 'moped', 'urban', 'ride', 'engine', 'two-wheeled', 'commute'],
      },
      {
        name: 'ship-1',
        keywords: ['vehicles', 'ship', '1', 'ocean', 'voyage', 'large', 'cargo', 'passenger', 'marine'],
      },
      {
        name: 'small_train-1',
        keywords: ['vehicles', 'small', 'train', '1', 'rail', 'miniature', 'short', 'tracks', 'commute'],
      },
      {
        name: 'station-1',
        keywords: ['vehicles', 'station', '1', 'transport', 'trains', 'platform', 'waiting', 'hub', 'tickets'],
      },
      {
        name: 'steamlocomotive-1',
        keywords: ['vehicles', 'steamlocomotive', '1', 'train', 'old', 'coal', 'smoke', 'historic', 'tracks'],
      },
      {
        name: 'steering_wheel_car-1',
        keywords: ['vehicles', 'steering', 'wheel', 'car', '1', 'control', 'drive', 'turn', 'interior', 'direction'],
      },
      {
        name: 'suspensionrailway-1',
        keywords: ['vehicles', 'suspensionrailway', '1', 'hanging', 'train', 'tracks', 'unique', 'urban', 'elevated'],
      },
      {
        name: 'taxi-1',
        keywords: ['vehicles', 'taxi', '1', 'cab', 'fare', 'city', 'transport', 'yellow', 'passenger'],
      },
      {
        name: 'taxi-yellow-1',
        keywords: ['vehicles', 'taxi', 'yellow', '1', 'cab', 'city', 'transport', 'fare', 'passenger'],
      },
      {
        name: 'tire-1',
        keywords: ['vehicles', 'tire', '1', 'wheel', 'rubber', 'traction', 'car', 'road', 'circular'],
      },
      {
        name: 'tractor-1',
        keywords: ['vehicles', 'tractor', '1', 'farm', 'agriculture', 'pull', 'machinery', 'fields', 'rural'],
      },
      {
        name: 'train-1',
        keywords: ['vehicles', 'train', '1', 'rail', 'commute', 'locomotive', 'tracks', 'public', 'travel'],
      },
      {
        name: 'train-squared-1',
        keywords: ['vehicles', 'train', 'squared', '1', 'rail', 'boxy', 'commute', 'urban', 'public', 'transport'],
      },
      {
        name: 'train',
        keywords: ['vehicles', 'train', 'rail', 'locomotive', 'tracks', 'commute', 'journey', 'public', 'speed'],
      },
      {
        name: 'tram-1',
        keywords: ['vehicles', 'tram', '1', 'streetcar', 'urban', 'electric', 'rails', 'public', 'commute'],
      },
      {
        name: 'tram-blue-1',
        keywords: ['vehicles', 'tram', 'blue', '1', 'streetcar', 'urban', 'electric', 'rails', 'public', 'color'],
      },
      {
        name: 'tram',
        keywords: ['vehicles', 'tram', 'streetcar', 'urban', 'rails', 'electric', 'public', 'commute', 'city'],
      },
      {
        name: 'transportation-1',
        keywords: [
          'vehicles',
          'transportation',
          '1',
          'travel',
          'system',
          'movement',
          'infrastructure',
          'network',
          'commute',
        ],
      },
      {
        name: 'tricycle-1',
        keywords: ['vehicles', 'tricycle', '1', 'three-wheels', 'pedal', 'child', 'balance', 'cycle', 'ride'],
      },
      {
        name: 'trolleybus-1',
        keywords: ['vehicles', 'trolleybus', '1', 'electric', 'wires', 'urban', 'public', 'bus', 'rails'],
      },
      {
        name: 'trolleybus-whiteblue-1',
        keywords: ['vehicles', 'trolleybus', 'whiteblue', '1', 'electric', 'wires', 'urban', 'public', 'bus', 'color'],
      },
      {
        name: 'truck-1',
        keywords: ['vehicles', 'truck', '1', 'cargo', 'freight', 'haul', 'wheels', 'diesel', 'transport'],
      },
      {
        name: 'truck1-1',
        keywords: ['vehicles', 'truck1', '1', 'cargo', 'haul', 'freight', 'wheels', 'diesel', 'road'],
      },
      {
        name: 'truck2-1',
        keywords: ['vehicles', 'truck2', '1', 'cargo', 'haul', 'freight', 'wheels', 'diesel', 'road'],
      },
      {
        name: 'truck3-1',
        keywords: ['vehicles', 'truck3', '1', 'cargo', 'haul', 'freight', 'wheels', 'diesel', 'road'],
      },
      {
        name: 'van-1',
        keywords: ['vehicles', 'van', '1', 'cargo', 'delivery', 'family', 'wheels', 'road', 'commercial'],
      },
      {
        name: 'vanpool-1',
        keywords: ['vehicles', 'vanpool', '1', 'carpool', 'group', 'commute', 'ride-share', 'work', 'reduce-traffic'],
      },
      {
        name: 'water_transportation-1',
        keywords: ['vehicles', 'water', 'transportation', '1', 'boats', 'marine', 'travel', 'ferry', 'river', 'lake'],
      },
      {
        name: 'wharf-1',
        keywords: ['vehicles', 'wharf', '1', 'dock', 'harbor', 'ships', 'cargo', 'fishing', 'coast'],
      },
      {
        name: 'yacht-1',
        keywords: ['vehicles', 'yacht', '1', 'luxury', 'boat', 'marine', 'leisure', 'sea', 'cruise'],
      },
    ],
  },
  {
    name: 'instruments',
    icons: [
      {
        name: 'accordion',
        keywords: ['instruments', 'accordion', 'folding', 'keys', 'bellows', 'folk', 'music'],
      },
      {
        name: 'accoustic_guitar_rock',
        keywords: ['instruments', 'accoustic', 'guitar', 'rock', 'string', 'music', 'melody', 'band', 'strum'],
      },
      {
        name: 'acoustic-guitar',
        keywords: ['instruments', 'acoustic', 'guitar', 'string', 'music', 'folk', 'melody', 'wood', 'strum'],
      },
      {
        name: 'amplifier',
        keywords: ['instruments', 'amplifier', 'amp', 'sound', 'electric', 'volume', 'speaker', 'music', 'guitar'],
      },
      {
        name: 'amplifier-blue',
        keywords: ['instruments', 'amplifier', 'blue', 'amp', 'sound', 'electric', 'volume', 'speaker', 'music'],
      },
      {
        name: 'audio_book_music',
        keywords: ['instruments', 'audio', 'book', 'music', 'sound', 'recording', 'listen', 'media', 'track'],
      },
      {
        name: 'banana_spade_tool',
        keywords: ['instruments', 'banana', 'spade', 'tool', 'unrelated', 'object', 'quirky', 'fun', 'novelty'],
      },
      {
        name: 'banjo',
        keywords: ['instruments', 'banjo', 'string', 'bluegrass', 'plucked', 'folk', 'twang', 'music'],
      },
      {
        name: 'bongos',
        keywords: ['instruments', 'bongos', 'drums', 'hand', 'rhythm', 'percussion', 'latin', 'beat'],
      },
      {
        name: 'cable_plug_connector',
        keywords: ['instruments', 'cable', 'plug', 'connector', 'wire', 'audio', 'signal', 'equipment', 'link'],
      },
      {
        name: 'cassette_player_audio',
        keywords: ['instruments', 'cassette', 'player', 'audio', 'tape', 'retro', 'music', 'analog', 'playback'],
      },
      {
        name: 'cassette_player_music',
        keywords: ['instruments', 'cassette', 'player', 'music', 'tape', 'retro', 'audio', 'analog', 'playback'],
      },
      {
        name: 'cassette_player_music_yellow',
        keywords: [
          'instruments',
          'cassette',
          'player',
          'music',
          'yellow',
          'tape',
          'retro',
          'audio',
          'analog',
          'playback',
        ],
      },
      {
        name: 'cassette_record_audio',
        keywords: ['instruments', 'cassette', 'record', 'audio', 'tape', 'retro', 'media', 'analog', 'capture'],
      },
      {
        name: 'cd_case_dvd',
        keywords: ['instruments', 'cd', 'case', 'dvd', 'disc', 'media', 'storage', 'cover', 'holder'],
      },
      {
        name: 'cd_dvd_disk',
        keywords: ['instruments', 'cd', 'dvd', 'disk', 'disc', 'optical', 'media', 'digital', 'storage'],
      },
      {
        name: 'cello',
        keywords: ['instruments', 'cello', 'string', 'bow', 'classical', 'orchestra', 'deep', 'melodic'],
      },
      {
        name: 'clarinet',
        keywords: ['instruments', 'clarinet', 'woodwind', 'reed', 'orchestra', 'jazz', 'mellow', 'keys'],
      },
      {
        name: 'computer_speaker_device',
        keywords: ['instruments', 'computer', 'speaker', 'device', 'sound', 'audio', 'output', 'music', 'volume'],
      },
      {
        name: 'cymbal',
        keywords: ['instruments', 'cymbal', 'percussion', 'metal', 'crash', 'drums', 'rhythm', 'beat'],
      },
      {
        name: 'cymbals',
        keywords: ['instruments', 'cymbals', 'percussion', 'metal', 'crash', 'drums', 'rhythm', 'beat'],
      },
      {
        name: 'djembe',
        keywords: ['instruments', 'djembe', 'african', 'drum', 'hand', 'rhythm', 'percussion', 'beat'],
      },
      {
        name: 'djembe-2',
        keywords: ['instruments', 'djembe', 'african', 'drum', 'hand', 'rhythm', 'percussion', 'beat'],
      },
      {
        name: 'dj_mixer_music',
        keywords: ['instruments', 'dj', 'mixer', 'music', 'turntable', 'mix', 'party', 'tracks', 'sound'],
      },
      {
        name: 'distort_pedal',
        keywords: ['instruments', 'distort', 'pedal', 'guitar', 'effect', 'sound', 'fuzz', 'electric', 'rock'],
      },
      {
        name: 'drum_kit',
        keywords: ['instruments', 'drum', 'kit', 'percussion', 'rhythm', 'drummer', 'beat', 'sticks', 'set'],
      },
      {
        name: 'drum-set',
        keywords: ['instruments', 'drum', 'set', 'drum', 'percussion', 'rhythm', 'sticks', 'music', 'kit'],
      },
      {
        name: 'drum_set_instrument',
        keywords: ['instruments', 'drum', 'set', 'instrument', 'percussion', 'rhythm', 'sticks', 'music', 'kit'],
      },
      {
        name: 'drums',
        keywords: ['instruments', 'drums', 'percussion', 'rhythm', 'beat', 'sticks', 'music', 'set'],
      },
      {
        name: 'ear_plugs_beats',
        keywords: ['instruments', 'ear', 'plugs', 'beats', 'hearing', 'protection', 'sound', 'noise', 'concert'],
      },
      {
        name: 'electric_guitar_power',
        keywords: ['instruments', 'electric', 'guitar', 'power', 'rock', 'amplify', 'strings', 'band', 'solo'],
      },
      {
        name: 'equalizer_music_multimedia',
        keywords: [
          'instruments',
          'equalizer',
          'music',
          'multimedia',
          'sound',
          'levels',
          'balance',
          'audio',
          'frequency',
        ],
      },
      {
        name: 'flute',
        keywords: ['instruments', 'flute', 'woodwind', 'breath', 'orchestra', 'melody', 'holes', 'notes'],
      },
      {
        name: 'guitar',
        keywords: ['instruments', 'guitar', 'strings', 'music', 'strum', 'melody', 'chords', 'instrument'],
      },
      {
        name: 'guitar-electric',
        keywords: ['instruments', 'guitar', 'electric', 'strings', 'rock', 'amplified', 'band', 'solo', 'loud'],
      },
      {
        name: 'harmonica',
        keywords: ['instruments', 'harmonica', 'mouth', 'reed', 'blues', 'breath', 'melody', 'pocket'],
      },
      {
        name: 'harmonica-2',
        keywords: ['instruments', 'harmonica', 'mouth', 'reed', 'blues', 'breath', 'melody', 'keyboard'],
      },
      {
        name: 'harmonica-blue',
        keywords: ['instruments', 'harmonica', 'blue', 'mouth', 'reed', 'blues', 'breath', 'melody', 'pocket'],
      },
      {
        name: 'headphones_hipster_music',
        keywords: ['instruments', 'headphones', 'hipster', 'music', 'audio', 'listen', 'sound', 'wire', 'beat'],
      },
      {
        name: 'headphones_music_sound',
        keywords: ['instruments', 'headphones', 'music', 'sound', 'audio', 'listen', 'ear', 'stereo', 'track'],
      },
      {
        name: 'hipster_music_on_trend',
        keywords: ['instruments', 'hipster', 'music', 'on', 'trend', 'cool', 'audio', 'modern', 'fashion', 'vibe'],
      },
      {
        name: 'horn',
        keywords: ['instruments', 'horn', 'brass', 'loud', 'orchestra', 'signal', 'music', 'blow'],
      },
      {
        name: 'keyboard-synth',
        keywords: ['instruments', 'keyboard', 'piano', 'keys', 'electric', 'music', 'synth', 'melody', 'band'],
      },
      {
        name: 'maracas',
        keywords: ['instruments', 'maracas', 'shakers', 'latin', 'rhythm', 'hand', 'percussion', 'beat'],
      },
      {
        name: 'metronome',
        keywords: ['instruments', 'metronome', 'tempo', 'tick', 'timing', 'practice', 'music', 'steady', 'beat'],
      },
      {
        name: 'metronome-1',
        keywords: ['instruments', 'metronome', 'tempo', 'tick', 'timing', 'practice', 'music', 'steady', 'beat'],
      },
      {
        name: 'microphone',
        keywords: ['instruments', 'microphone', 'mic', 'sound', 'voice', 'record', 'sing', 'amplify', 'audio'],
      },
      {
        name: 'microphone-1',
        keywords: ['instruments', 'microphone', 'mic', 'sound', 'voice', 'record', 'sing', 'amplify', 'audio'],
      },
      {
        name: 'microphone-grey',
        keywords: ['instruments', 'microphone', 'grey', 'mic', 'sound', 'voice', 'record', 'sing', 'audio'],
      },
      {
        name: 'multiplemusicalnotes',
        keywords: [
          'instruments',
          'multiplemusicalnotes',
          'multiple',
          'musical',
          'notes',
          'melody',
          'sound',
          'music',
          'symbol',
        ],
      },
      {
        name: 'music',
        keywords: ['instruments', 'music', 'sound', 'melody', 'notes', 'tune', 'song', 'art'],
      },
      {
        name: 'music-desk-dj',
        keywords: ['instruments', 'music', 'desk', 'dj', 'turntable', 'mix', 'party', 'sound', 'tracks'],
      },
      {
        name: 'music-player',
        keywords: ['instruments', 'music', 'player', 'device', 'audio', 'playback', 'songs', 'portable', 'tracks'],
      },
      {
        name: 'record-player',
        keywords: ['instruments', 'music', 'player', 'retro', 'device', 'audio', 'vintage', 'songs', 'analog', 'vinyl'],
      },
      {
        name: 'musical_score',
        keywords: [
          'instruments',
          'musical',
          'score',
          'notation',
          'sheet',
          'notes',
          'composition',
          'reading',
          'classical',
        ],
      },
      {
        name: 'musicalkeyboard',
        keywords: [
          'instruments',
          'musicalkeyboard',
          'musical',
          'keyboard',
          'piano',
          'keys',
          'electric',
          'music',
          'synth',
        ],
      },
      {
        name: 'musicalnote',
        keywords: ['instruments', 'musicalnote', 'musical', 'note', 'symbol', 'sound', 'pitch', 'melody', 'music'],
      },
      {
        name: 'musicalscore',
        keywords: [
          'instruments',
          'musicalscore',
          'musical',
          'score',
          'notation',
          'sheet',
          'composition',
          'notes',
          'classical',
        ],
      },
      {
        name: 'musicascend',
        keywords: ['instruments', 'musicascend', 'music', 'ascend', 'rising', 'scale', 'notes', 'pitch', 'up'],
      },
      {
        name: 'musicdescend',
        keywords: ['instruments', 'musicdescend', 'music', 'descend', 'falling', 'scale', 'notes', 'pitch', 'down'],
      },
      {
        name: 'piano',
        keywords: ['instruments', 'piano', 'keys', 'classical', 'melody', 'grand', 'strings', 'hammers', 'music'],
      },
      {
        name: 'piano-keyboard',
        keywords: ['instruments', 'piano', 'keyboard', 'keys', 'electric', 'music', 'melody', 'instrument', 'synth'],
      },
      {
        name: 'postalhorn',
        keywords: ['instruments', 'postalhorn', 'postal', 'horn', 'signal', 'brass', 'old', 'mail', 'sound'],
      },
      {
        name: 'saxophone',
        keywords: ['instruments', 'saxophone', 'reed', 'brass', 'jazz', 'smooth', 'melody', 'keys', 'solo'],
      },
      {
        name: 'saxophone-narrow',
        keywords: ['instruments', 'saxophone', 'narrow', 'reed', 'brass', 'jazz', 'smooth', 'melody', 'keys', 'solo'],
      },
      {
        name: 'sheet_music',
        keywords: ['instruments', 'sheet', 'music', 'notation', 'notes', 'paper', 'reading', 'composition', 'song'],
      },
      {
        name: 'small',
        keywords: ['instruments', 'small', 'tiny', 'mini', 'object', 'limited', 'scale', 'sound', 'tool'],
      },
      {
        name: 'snare_drum',
        keywords: ['instruments', 'snare', 'drum', 'percussion', 'rhythm', 'marching', 'sticks', 'beat', 'sharp'],
      },
      {
        name: 'trumpet',
        keywords: ['instruments', 'trumpet', 'brass', 'loud', 'orchestra', 'jazz', 'blow', 'valves', 'music'],
      },
      {
        name: 'trumphet',
        keywords: [
          'instruments',
          'trumphet',
          'trumpet',
          'brass',
          'loud',
          'orchestra',
          'jazz',
          'blow',
          'valves',
          'music',
        ],
      },
      {
        name: 'violin',
        keywords: ['instruments', 'violin', 'string', 'bow', 'classical', 'orchestra', 'melody', 'high', 'notes'],
      },
      {
        name: 'violin-1',
        keywords: ['instruments', 'violin', '1', 'string', 'bow', 'classical', 'orchestra', 'melody', 'notes'],
      },
      {
        name: 'wooden_guitar',
        keywords: ['instruments', 'wooden', 'guitar', 'acoustic', 'string', 'folk', 'natural', 'music', 'strum'],
      },
      {
        name: 'xylophone',
        keywords: ['instruments', 'xylophone', 'percussion', 'wooden', 'bars', 'mallet', 'notes', 'colorful', 'child'],
      },
    ],
  },
  {
    name: 'clothes',
    icons: [
      {
        name: '3d-movie-glass',
        keywords: ['clothes', '3d', 'movie', 'glasses', 'cinema', 'stereoscopic', 'fashion', 'accessory'],
      },
      {
        name: 'baseball-cap',
        keywords: ['clothes', 'baseball', 'cap', 'hat', 'sporty', 'casual', 'headwear', 'visor'],
      },
      {
        name: 'baseball-cap',
        keywords: ['clothes', 'baseball', 'cap', 'hat', 'sporty', 'casual', 'headwear', 'visor'],
      },
      {
        name: 'beanie',
        keywords: ['clothes', 'beanie', 'hat', 'knit', 'warm', 'casual', 'winter', 'headwear'],
      },
      {
        name: 'black-turtleneck',
        keywords: ['clothes', 'black', 'turtleneck', 'shirt', 'warm', 'stylish', 'casual', 'fashion'],
      },
      {
        name: 'blue-dress',
        keywords: ['clothes', 'blue', 'dress', 'feminine', 'elegant', 'fashion', 'outfit', 'garment'],
      },
      {
        name: 'blue-jacket',
        keywords: ['clothes', 'blue', 'jacket', 'outerwear', 'cool', 'casual', 'warm', 'fashion'],
      },
      {
        name: 'blue-rubber-boot',
        keywords: ['clothes', 'blue', 'rubber', 'boot', 'waterproof', 'rain', 'footwear', 'outdoor'],
      },
      {
        name: 'blue-sweatpants',
        keywords: ['clothes', 'blue', 'sweatpants', 'pants', 'casual', 'comfortable', 'athletic', 'relaxed'],
      },
      {
        name: 'blue-tank-top',
        keywords: ['clothes', 'blue', 'tank', 'top', 'sleeveless', 'casual', 'summer', 'light'],
      },
      {
        name: 'bow-ribbon',
        keywords: ['clothes', 'bow', 'ribbon', 'decoration', 'accessory', 'cute', 'feminine', 'adornment'],
      },
      {
        name: 'bowtie',
        keywords: ['clothes', 'bowtie', 'tie', 'formal', 'elegant', 'accessory', 'menswear', 'neckwear'],
      },
      {
        name: 'cap-education-hat',
        keywords: ['clothes', 'cap', 'education', 'hat', 'graduation', 'academic', 'ceremony', 'scholar'],
      },
      {
        name: 'cap-hat-witch',
        keywords: ['clothes', 'cap', 'hat', 'witch', 'costume', 'magic', 'halloween', 'pointed'],
      },
      {
        name: 'dress',
        keywords: ['clothes', 'dress', 'feminine', 'garment', 'fashion', 'formal', 'outfit', 'stylish'],
      },
      {
        name: 'dress-1',
        keywords: ['clothes', 'dress', '1', 'feminine', 'garment', 'fashion', 'stylish', 'outfit'],
      },
      {
        name: 'dress-2',
        keywords: ['clothes', 'dress', '2', 'feminine', 'elegant', 'outfit', 'fabric', 'apparel'],
      },
      {
        name: 'dress-3',
        keywords: ['clothes', 'dress', '3', 'feminine', 'stylish', 'garment', 'fashion', 'chic'],
      },
      {
        name: 'eye-glasses-office',
        keywords: ['clothes', 'eye', 'glasses', 'office', 'vision', 'accessory', 'professional', 'frames'],
      },
      {
        name: 'flip-flops',
        keywords: ['clothes', 'flip', 'flops', 'sandals', 'casual', 'beach', 'summer', 'footwear'],
      },
      {
        name: 'graduation-cap',
        keywords: ['clothes', 'graduation', 'cap', 'academic', 'ceremony', 'scholar', 'achievement', 'headwear'],
      },
      {
        name: 'gray-tshirt',
        keywords: ['clothes', 'gray', 'tshirt', 'casual', 'top', 'cotton', 'everyday', 'basic'],
      },
      {
        name: 'green-boot',
        keywords: ['clothes', 'green', 'boot', 'footwear', 'outdoor', 'warm', 'leather', 'stylish'],
      },
      {
        name: 'green-bra',
        keywords: ['clothes', 'green', 'bra', 'undergarment', 'intimate', 'feminine', 'support', 'lingerie'],
      },
      {
        name: 'green-dress',
        keywords: ['clothes', 'green', 'dress', 'feminine', 'garment', 'fashion', 'stylish', 'colorful'],
      },
      {
        name: 'green-pants',
        keywords: ['clothes', 'green', 'pants', 'bottoms', 'casual', 'fashion', 'stylish', 'trousers'],
      },
      {
        name: 'green-stripped-tshir',
        keywords: ['clothes', 'green', 'stripped', 'tshir', 'striped', 'casual', 'shirt', 'pattern', 'trendy'],
      },
      {
        name: 'handbag',
        keywords: ['clothes', 'handbag', 'bag', 'purse', 'accessory', 'fashion', 'carry', 'storage'],
      },
      {
        name: 'hat',
        keywords: ['clothes', 'hat', 'headwear', 'accessory', 'fashion', 'cap', 'style', 'shade'],
      },
      {
        name: 'hat-1',
        keywords: ['clothes', 'hat', 'headwear', 'accessory', 'fashion', 'cap', 'style', 'shade'],
      },
      {
        name: 'hat-birthday-1',
        keywords: ['clothes', 'hat', 'birthday', '1', 'party', 'celebration', 'festive', 'colorful'],
      },
      {
        name: 'hearts-tshirt',
        keywords: ['clothes', 'hearts', 'tshirt', 'pattern', 'cute', 'love', 'casual', 'graphic'],
      },
      {
        name: 'jeans',
        keywords: ['clothes', 'jeans', 'denim', 'pants', 'casual', 'trendy', 'everyday', 'versatile'],
      },
      {
        name: 'kimono',
        keywords: ['clothes', 'kimono', 'traditional', 'japanese', 'robe', 'silk', 'culture', 'flowy'],
      },
      {
        name: 'mansshirt',
        keywords: ['clothes', 'mansshirt', 'mans', 'shirt', 'men', 'top', 'casual', 'formal', 'collar'],
      },
      {
        name: 'mens-underwear',
        keywords: ['clothes', 'mens', 'underwear', 'undergarment', 'intimate', 'boxers', 'briefs', 'comfortable'],
      },
      {
        name: 'mensrunner',
        keywords: ['clothes', 'mensrunner', 'mens', 'runner', 'shoes', 'athletic', 'footwear', 'sports'],
      },
      {
        name: 'mensshoe',
        keywords: ['clothes', 'mensshoe', 'mens', 'shoe', 'footwear', 'leather', 'formal', 'fashion'],
      },
      {
        name: 'mexican-hat-mariachi',
        keywords: ['clothes', 'mexican', 'hat', 'mariachi', 'sombrero', 'traditional', 'festive', 'cultural'],
      },
      {
        name: 'neck-tie',
        keywords: ['clothes', 'neck', 'tie', 'formal', 'accessory', 'business', 'menswear', 'dressy'],
      },
      {
        name: 'necklace',
        keywords: ['clothes', 'necklace', 'jewelry', 'accessory', 'neck', 'fashion', 'elegant', 'ornament'],
      },
      {
        name: 'orange-fitted-pants',
        keywords: ['clothes', 'orange', 'fitted', 'pants', 'trousers', 'fashion', 'colorful', 'slim'],
      },
      {
        name: 'orange-tshirt',
        keywords: ['clothes', 'orange', 'tshirt', 'casual', 'bright', 'colorful', 'cotton', 'basic'],
      },
      {
        name: 'pamela-hat',
        keywords: ['clothes', 'pamela', 'hat', 'wide', 'brim', 'fashion', 'summer', 'stylish'],
      },
      {
        name: 'pink-boot',
        keywords: ['clothes', 'pink', 'boot', 'footwear', 'colorful', 'stylish', 'womens', 'fashion'],
      },
      {
        name: 'pink-tshirt',
        keywords: ['clothes', 'pink', 'tshirt', 'casual', 'bright', 'cotton', 'cute', 'simple'],
      },
      {
        name: 'pinkwallet',
        keywords: ['clothes', 'pinkwallet', 'pink', 'wallet', 'accessory', 'hold', 'cards', 'stylish', 'purse'],
      },
      {
        name: 'purple-fitted-pants',
        keywords: ['clothes', 'purple', 'fitted', 'pants', 'trousers', 'stylish', 'colorful', 'fashion'],
      },
      {
        name: 'purple-pants',
        keywords: ['clothes', 'purple', 'pants', 'trousers', 'colorful', 'fashion', 'stylish', 'bold'],
      },
      {
        name: 'purple-running-shoe',
        keywords: ['clothes', 'purple', 'running', 'shoe', 'footwear', 'athletic', 'sporty', 'comfortable'],
      },
      {
        name: 'purple-shorts',
        keywords: ['clothes', 'purple', 'shorts', 'bottoms', 'summer', 'casual', 'colorful', 'light'],
      },
      {
        name: 'red-dress',
        keywords: ['clothes', 'red', 'dress', 'feminine', 'elegant', 'bold', 'fashion', 'evening'],
      },
      {
        name: 'red-high-heel',
        keywords: ['clothes', 'red', 'high', 'heel', 'footwear', 'feminine', 'dressy', 'elegant'],
      },
      {
        name: 'red-shoe',
        keywords: ['clothes', 'red', 'shoe', 'footwear', 'bold', 'fashion', 'stylish', 'statement'],
      },
      {
        name: 'red-snazzy-shorts',
        keywords: ['clothes', 'red', 'snazzy', 'shorts', 'fashion', 'stylish', 'bold', 'summer'],
      },
      {
        name: 'red-sports-jersey',
        keywords: ['clothes', 'red', 'sports', 'jersey', 'athletic', 'team', 'uniform', 'casual', 'fan'],
      },
      {
        name: 'red-sweater',
        keywords: ['clothes', 'red', 'sweater', 'warm', 'knit', 'cozy', 'stylish', 'casual'],
      },
      {
        name: 'ring-3',
        keywords: ['clothes', 'ring', 'jewelry', 'accessory', 'finger', 'fashion', 'metal', 'ornament', 'heart'],
      },
      {
        name: 'ring',
        keywords: ['clothes', 'ring', 'jewelry', 'accessory', 'finger', 'fashion', 'metal', 'ornament'],
      },
      {
        name: 'ring(2)',
        keywords: ['clothes', 'ring(2)', 'ring', '2', 'jewelry', 'accessory', 'finger', 'metal', 'ornament'],
      },
      {
        name: 'runningshirt',
        keywords: ['clothes', 'runningshirt', 'running', 'shirt', 'athletic', 'sporty', 'breathable', 'fitness'],
      },
      {
        name: 'safety-boots-engineer-engineering-protection-security-secure-2',
        keywords: [
          'clothes',
          'safety',
          'boots',
          'engineer',
          'engineering',
          'protection',
          'security',
          'secure',
          '2',
          'workwear',
          'durable',
          'sturdy',
        ],
      },
      {
        name: 'safety-helmet-engineer-engineering-protection-security-secure',
        keywords: [
          'clothes',
          'safety',
          'helmet',
          'engineer',
          'engineering',
          'protection',
          'security',
          'secure',
          'hardhat',
          'construction',
          'work',
        ],
      },
      {
        name: 'sandals',
        keywords: ['clothes', 'sandals', 'footwear', 'open', 'summer', 'casual', 'beach', 'comfortable'],
      },
      {
        name: 'scarf',
        keywords: ['clothes', 'scarf', 'neckwear', 'warm', 'accessory', 'winter', 'fashion', 'wrap'],
      },
      {
        name: 'scarf-1',
        keywords: ['clothes', 'scarf', 'neckwear', 'warm', 'accessory', 'winter', 'fashion', 'wrap'],
      },
      {
        name: 'scarf-2',
        keywords: ['clothes', 'scarf', 'neckwear', 'warm', 'accessory', 'winter', 'fashion', 'wrap'],
      },
      {
        name: 'shirt',
        keywords: ['clothes', 'shirt', 'top', 'fabric', 'casual', 'dress', 'collar', 'sleeves'],
      },
      {
        name: 'shirt-with-bow',
        keywords: ['clothes', 'shirt', 'with', 'bow', 'decorative', 'feminine', 'stylish', 'fashion', 'cute'],
      },
      {
        name: 'shoe',
        keywords: ['clothes', 'shoe', 'footwear', 'style', 'fashion', 'leather', 'comfortable', 'pair'],
      },
      {
        name: 'shoe-1',
        keywords: ['clothes', 'shoe', '1', 'footwear', 'fashion', 'single', 'style', 'leather'],
      },
      {
        name: 'shoe-2',
        keywords: ['clothes', 'shoe', '2', 'footwear', 'pair', 'fashion', 'style', 'comfortable'],
      },
      {
        name: 'shoe-3',
        keywords: ['clothes', 'shoe', '3', 'footwear', 'fashion', 'stylish', 'leather', 'unique'],
      },
      {
        name: 'shoe-4',
        keywords: ['clothes', 'shoe', '4', 'footwear', 'design', 'fashion', 'comfortable', 'pair'],
      },
      {
        name: 'shoe-shoes',
        keywords: ['clothes', 'shoe', 'shoes', 'footwear', 'pair', 'fashion', 'versatile', 'comfortable'],
      },
      {
        name: 'shoe-shoes-1',
        keywords: ['clothes', 'shoe', 'shoes', '1', 'footwear', 'multiple', 'fashion', 'pairs', 'collection'],
      },
      {
        name: 'skirt',
        keywords: ['clothes', 'skirt', 'feminine', 'bottom', 'fashion', 'flowy', 'summer', 'casual'],
      },
      {
        name: 'sneakers',
        keywords: ['clothes', 'sneakers', 'shoes', 'athletic', 'casual', 'comfortable', 'sporty', 'footwear'],
      },
      {
        name: 'sock',
        keywords: ['clothes', 'sock', 'footwear', 'fabric', 'warm', 'pair', 'comfortable', 'basic'],
      },
      {
        name: 'suitcase-portfolio',
        keywords: ['clothes', 'suitcase', 'portfolio', 'bag', 'travel', 'carry', 'business', 'storage', 'case'],
      },
      {
        name: 'sun-hat',
        keywords: ['clothes', 'sun', 'hat', 'brim', 'shade', 'summer', 'protection', 'beach'],
      },
      {
        name: 'sunglasses',
        keywords: ['clothes', 'sunglasses', 'shades', 'protective', 'eyes', 'summer', 'stylish', 'accessory'],
      },
      {
        name: 'swimming-shorts',
        keywords: ['clothes', 'swimming', 'shorts', 'water', 'beach', 'swimwear', 'summer', 'casual'],
      },
      {
        name: 'tie',
        keywords: ['clothes', 'tie', 'neckwear', 'formal', 'business', 'accessory', 'suit', 'dressy'],
      },
      {
        name: 'tie-dye-tshirt',
        keywords: ['clothes', 'tie', 'dye', 'tshirt', 'colorful', 'pattern', 'casual', 'bohemian', 'trendy'],
      },
      {
        name: 'tophat',
        keywords: ['clothes', 'tophat', 'top', 'hat', 'formal', 'elegant', 'gentleman', 'classic'],
      },
      {
        name: 'towel',
        keywords: ['clothes', 'towel', 'fabric', 'dry', 'bath', 'beach', 'absorbent', 'accessory'],
      },
      {
        name: 'trilby',
        keywords: ['clothes', 'trilby', 'hat', 'fashion', 'stylish', 'brim', 'classic', 'dapper'],
      },
      {
        name: 'watch',
        keywords: ['clothes', 'watch', 'timepiece', 'wrist', 'accessory', 'fashion', 'clock', 'metal'],
      },
      {
        name: 'watch-black',
        keywords: ['clothes', 'watch', 'black', 'timepiece', 'wrist', 'stylish', 'modern', 'accessory', 'classic'],
      },
      {
        name: 'winter-hat',
        keywords: ['clothes', 'winter', 'hat', 'warm', 'knit', 'cozy', 'cold', 'headwear'],
      },
      {
        name: 'winter-hat-1',
        keywords: ['clothes', 'winter', 'hat', '1', 'warm', 'knit', 'cozy', 'seasonal'],
      },
      {
        name: 'witch-magic',
        keywords: ['clothes', 'witch', 'magic', 'costume', 'hat', 'halloween', 'fantasy', 'theatrical'],
      },
      {
        name: 'womans-underwear',
        keywords: ['clothes', 'womans', 'underwear', 'lingerie', 'intimate', 'feminine', 'undergarment', 'soft'],
      },
      {
        name: 'womanshat',
        keywords: ['clothes', 'womanshat', 'womans', 'hat', 'feminine', 'stylish', 'fashion', 'headwear'],
      },
      {
        name: 'womansshirt',
        keywords: ['clothes', 'womansshirt', 'womans', 'shirt', 'top', 'feminine', 'blouse', 'fashion'],
      },
      {
        name: 'yellow-dress',
        keywords: ['clothes', 'yellow', 'dress', 'bright', 'feminine', 'cheerful', 'stylish', 'summer'],
      },
      {
        name: 'yellow-skirt',
        keywords: ['clothes', 'yellow', 'skirt', 'feminine', 'bright', 'summer', 'cheerful', 'fashion'],
      },
    ],
  },
];
