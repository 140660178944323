@if (
  {
    trackers: trackers$ | async,
    selectedTracker: selectedTracker$ | async,
    selectedMetric: selectedMetric$ | async,
    selectedFilteredTrackerMetrics: selectedFilteredTrackerMetrics$ | async
  };
  as state
) {
  @if (fromTokens && !triggerFormGroup.get('trackerId').value) {
    <oph-autocomplete
      placeholder="Connect a tracker"
      [options]="autocompleteOptions$ | async"
      [style]="{height: '52px'}"
      [style]="ophAutocompleteStyle"
      [newButtonLoading]="ophAutocompleteNewButtonLoading"
      showNewButton="true"
      (optionChange)="onTracker($event)"
      (newClick)="ophAutocompleteNewClick.emit()"
    ></oph-autocomplete>
  } @else {
    <div class="main-container" [class.complete]="triggerComplete$ | async">
      @if (!triggerFormGroup.get('trackerId').value) {
        <oph-autocomplete
          [placeholder]="'Add Tracker'"
          [options]="autocompleteOptions$ | async"
          (optionChange)="onTracker($event)"
        ></oph-autocomplete>
      } @else {
        <div class="metric-buttons-container">
          <!-- --------------------METRIC--------------------------- -->
          <button class="select-button" [matMenuTriggerFor]="metricMenu">
            <span>{{ state.selectedMetric?.name || 'Select metric' }}</span>
            <img src="assets/img/icons/chevron-down-brown.svg" />
          </button>
          <mat-menu #metricMenu="matMenu" class="oph-select-menu">
            @for (metric of state.selectedFilteredTrackerMetrics; track metric) {
              @if (metric.name) {
                <button (click)="onMetric(metric)" class="oph-select-menu-item">
                  <span>{{ metric.name || '' }}</span>
                </button>
              }
            }
            @if (!state.selectedFilteredTrackerMetrics?.length) {
              <div class="empty">This tracker has no metrics.</div>
            }
          </mat-menu>

          <!-- --------------------OPERATOR--------------------------- -->
          <button
            class="select-button operator-button"
            [matMenuTriggerFor]="operatorMenu"
            [disabled]="!state.selectedMetric || state.selectedMetric?.type !== TrackerMetricTypes.Numeric"
            [class.disabled]="!state.selectedMetric || state.selectedMetric?.type !== TrackerMetricTypes.Numeric"
          >
            {{ triggerFormGroup.get('operator').value | operatorDisplay }}
            <img src="assets/img/icons/chevron-down-brown.svg" />
          </button>
          <mat-menu #operatorMenu="matMenu" class="oph-select-menu">
            @for (operator of operatorOptions; track operator) {
              <button (click)="onOperator(operator)" class="oph-select-menu-item">
                <div class="operator-container">
                  <div class="operator">{{ operator.displayValue }}</div>
                  <span class="operator-text">{{ operator.display }}</span>
                </div>
              </button>
            }
          </mat-menu>

          <!-- --------------------VALUE--------------------------- -->
          @if (!state.selectedMetric || state.selectedMetric?.type === TrackerMetricTypes.Numeric) {
            <input
              class="value-input"
              type="number"
              [min]="state.selectedMetric?.min || null"
              [max]="state.selectedMetric?.max || null"
              [value]="triggerFormGroup.get('value')?.value || ''"
              [disabled]="!state.selectedMetric"
              [class.disabled]="!state.selectedMetric"
              (input)="onValue($event.target.value)"
            />
          }

          @if (state.selectedMetric?.type === TrackerMetricTypes.Select) {
            <button class="select-button" [matMenuTriggerFor]="selectValueMenu" [disabled]="!state.selectedMetric">
              <span>{{ triggerFormGroup.get('value').value || 'Select option' }}</span>
              <img src="assets/img/icons/chevron-down-brown.svg" />
            </button>
            <mat-menu #selectValueMenu="matMenu" class="oph-select-menu">
              @for (option of state.selectedMetric?.options; track option) {
                <button (click)="onValue(option)" class="oph-select-menu-item">
                  <span>{{ option || '' }}</span>
                </button>
              }
            </mat-menu>
          }

          @if (state.selectedMetric?.type === TrackerMetricTypes.Boolean) {
            <button class="select-button" [matMenuTriggerFor]="selectBooleanMenu" [disabled]="!state.selectedMetric">
              @if (triggerFormGroup.get('value').value) {
                <span>{{ triggerFormGroup.get('value').value | numberToBoolean }}</span>
              } @else {
                <span>Select an option</span>
              }
              <img src="assets/img/icons/chevron-down-brown.svg" />
            </button>
            <mat-menu #selectBooleanMenu="matMenu" class="oph-select-menu">
              @for (option of booleanOptions; track option) {
                <button (click)="onValue(option.value)" class="oph-select-menu-item">
                  <span>{{ option.display || '' }}</span>
                </button>
              }
            </mat-menu>
          }
        </div>
      }
      @if (hideXButton && triggerFormGroup.get('trackerId').value) {
        <oph-x-button (buttonClick)="onRemoveItem()"></oph-x-button>
      }
      @if (!hideXButton) {
        <oph-x-button (buttonClick)="onRemoveItem()"></oph-x-button>
      }
    </div>
  }
}
