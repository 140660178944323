import {Pipe, PipeTransform} from '@angular/core';
import {ProjectStep} from 'src/app/core/model/project';

@Pipe({
  name: 'isTrackerComplete',
  standalone: true,
})
export class IsTrackerCompletePipe implements PipeTransform {
  transform(step: ProjectStep): boolean {
    return (
      !!step?.tracker?.trackerId &&
      !!step?.tracker?.metricId &&
      !!step?.tracker?.operator &&
      !!step?.tracker?.value.toString()
    );
  }
}
