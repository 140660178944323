import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, take, timer} from 'rxjs';
import {selectRouterQueryParam} from 'src/app/core/store/router/router.selector';
import {ListViewsModule} from '../../list-views/list-views.module';
import {ListViewsProjectDisplayComponent} from '../../list-views/shared/project-display/list-views-project-display.component';
import {PipesModule} from '../../pipes/pipes.module';
import {OphLoadingModule} from '../oph-loading/oph-loading.module';
import {OphIconModule} from '../oph-icon/oph-icon.module';

export interface OphTableColumn {
  name: string;
  displayName?: string;
  width?: string;
  type?: 'projects' | 'date' | 'menu' | 'name' | 'tokenType' | 'shortDate';
  sortName?: string;
  bold?: boolean;
}

@Component({
  selector: 'oph-table',
  standalone: true,
  imports: [PipesModule, AsyncPipe, ListViewsModule, ListViewsProjectDisplayComponent, OphLoadingModule, OphIconModule],
  templateUrl: './oph-table.component.html',
  styleUrl: './oph-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphTableComponent implements OnInit, OnChanges {
  // Name is the field name in the data object, displayName is the displayed column header
  // Column names must match up with data item names
  @Input() columns: OphTableColumn[] = [];
  @Input() data: unknown[] = [];
  @Input() menuOptions: string[];
  @Input() defaultSortField: string;
  @Input() iconArray: string[];
  @Input() disableRows: boolean;

  @Output() rowClick = new EventEmitter<number>();
  @Output() menuAction = new EventEmitter<{action: string; rowIndex: number}>();

  sortField$: Observable<string>;

  loadingIndex$ = new BehaviorSubject<number>(-1);

  resetLoadingTime = 10; //seconds

  constructor(
    private store$: Store,
    private router: Router
  ) {}

  ngOnInit() {
    this.sortField$ = this.store$.pipe(select(selectRouterQueryParam('sortField')));

    this.sortField$.pipe(take(1)).subscribe(sortField => {
      if (!sortField && this.defaultSortField) {
        this.router.navigate([], {queryParams: {sortField: this.defaultSortField}});
      }
    });
  }

  gridTemplateColumns: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columns && this.columns) {
      this.gridTemplateColumns = this.columns.map(col => (col.width ? col.width : '1fr')).join(' ');
    }
    if (changes.data && this.data) {
      this.loadingIndex$.next(-1);
    }
  }

  onSort(sortField: string, index: number) {
    this.sortField$.pipe(take(1)).subscribe(existingSortField => {
      this.loadingIndex$.next(index);
      // Removes sort loading spinner if time over 10 seconds
      timer(this.resetLoadingTime * 1000).subscribe(() => this.loadingIndex$.next(-1));
      const newSortField = existingSortField === sortField ? null : sortField;
      this.router.navigate([], {queryParams: {sortField: newSortField}, queryParamsHandling: 'merge'});
    });
  }

  onRow(index: number) {
    if (this.disableRows) {
      return;
    }
    this.rowClick.emit(index);
  }
}
