<h2>{{ data.step ? 'Edit' : 'Create' }} Step</h2>

<div class="main-container">
  @if (!confirmView) {
    <oph-input-orange
      [control]="form.get('name')"
      [inputStyle]="{height: '50px', fontSize: '16px'}"
      placeholder="Step title"
    ></oph-input-orange>

    <div class="row">
      <span class="text">Step Completion</span>
      <span class="sub-text">Select the value that indicates when the step is complete:</span>
    </div>

    <!-- ----TRACKER--- -->
    <task-trigger-selection
      class="mb-10"
      [triggerFormGroup]="form.get('tracker')"
      hideXButton="true"
      fromTokens="true"
      [ophAutocompleteStyle]="{height: '44px'}"
      [ophAutocompleteNewButtonLoading]="addTrackerLoading"
      (removeItem)="onClearTracker()"
      (ophAutocompleteNewClick)="onNewTracker()"
    ></task-trigger-selection>

    <div class="row" [class.disabled]="data.stepIndex === 0">
      <span class="text">Make Sequential</span>
      <span class="sub-text">Previous step must be complete before this step is available.</span>
      <oph-button-group
        class="boolean-button-group"
        [options]="booleanOptions"
        [selectedOptionName]="form.get('previousStepRequired').value ? 'yes' : 'no'"
        [buttonStyle]="{width: '10px', padding: '0px'}"
        (optionClick)="onBooleanOptionChange($event, 'previousStepRequired')"
      ></oph-button-group>
    </div>
  } @else {
    <!-- --------------CONFIRM VIEW---------------- -->
    <div class="confirm-view">
      <oph-icon name="warning-yellow" [size]="48"></oph-icon>
      This step will not autocomplete unless a tracker is selected.
    </div>
  }
</div>

<div class="button-container">
  @if (confirmView) {
    <oph-button
      color="gray"
      appearance="outline"
      [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
      (click)="confirmView = false"
      >Back</oph-button
    >
  } @else {
    @if (data.stepIndex !== null) {
      @if (data.project.steps.length > 1) {
        <div class="delete-button-container" [class.disabled-delete-container]="stepHasTasks">
          <oph-button
            color="red"
            appearance="outline"
            [style]="{
              'border-width': '0.5px',
              'border-radius': '8px',
              color: 'var(--brown-med)',
              'border-color': stepHasTasks ? 'var(--scrollbar)' : '',
              color: stepHasTasks ? 'var(--scrollbar)' : ''
            }"
            (click)="onDelete()"
            >Delete</oph-button
          >
          @if (stepHasTasks) {
            <mat-icon svgIcon="info"></mat-icon>
            <span>Step must be empty</span>
          }
        </div>
      }
    }
    <oph-button
      color="gray"
      appearance="outline"
      [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
      (click)="onCancel()"
      >Cancel</oph-button
    >
  }
  <oph-button
    color="green"
    [style]="{'border-radius': '8px'}"
    [loading]="loadingSave"
    [disabled]="loadingSave || !form.valid"
    (click)="onSave()"
    >Finish</oph-button
  >
</div>
