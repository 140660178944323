<div class="input-container">
  <oph-icon [name]="'list-views/search'" [size]="22"></oph-icon>
  <input
    type="text"
    class="example-input"
    [placeholder]="placeholder || ''"
    [matAutocomplete]="auto"
    [ngStyle]="style"
    [disabled]="inputDisabled"
    [value]="value"
    [class.input-new-button]="showNewButton"
    (input)="onInput($event.target.value)"
    (blur)="onBlur()"
    #input
  />
</div>
@if (showNewButton) {
  <div class="new-button-container" matTooltip="Coming soon">
    <button class="new-button" (click)="newClick.emit()">
      @if (newButtonLoading) {
        <div class="oph-orange-spinner">
          <mat-spinner [diameter]="23" [strokeWidth]="2"></mat-spinner>
        </div>
      } @else {
        <oph-icon [name]="'plus-orange'" [size]="20"></oph-icon>
        New
      }
    </button>
  </div>
}

<mat-autocomplete #auto="matAutocomplete" (opened)="menuOpen = true" (closed)="menuOpen = false">
  @if (filteredOptions?.length) {
    @for (option of filteredOptions; track option; let i = $index) {
      <div class="oph-autocomplete-option">
        <mat-option
          [value]="preventSelectedOption ? null : option"
          (click)="onOption(option, i)"
          [disabled]="option.disabled"
        >
          {{ option.name }}
        </mat-option>
        @if (optionIcon) {
          <oph-icon [name]="optionIcon" [size]="18"></oph-icon>
        }
      </div>
    }
  } @else {
    <mat-option [disabled]="true">{{ noOptionsText || 'No options found' }}</mat-option>
  }
</mat-autocomplete>
