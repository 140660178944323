import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'oph-label-content',
  standalone: true,
  imports: [],
  templateUrl: './oph-label-content.component.html',
  styleUrl: './oph-label-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphLabelContentComponent {
  @Input() label: string;
}
