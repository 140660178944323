<header>
  <span class="dialog-title">{{ reward ? 'Edit' : 'Create' }} Reward</span>
  <oph-x-button (buttonClick)="onClose()"></oph-x-button>
</header>

<div class="content-container">
  @if (loading$ | async) {
    <div class="oph-loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    @if (view === 'date') {
      <div class="content-header">
        <oph-x-button (buttonClick)="view = 'form'"></oph-x-button>
      </div>
      <div class="calendar-container">
        <task-calendar
          [control]="form.get('expirationDate')"
          (dateChange)="onDateChange()"
          hideBorder="true"
        ></task-calendar>
      </div>
    }

    @if (view === 'user') {
      <div class="item-container-wrapper">
        <div class="item-container user-container oph-scroll-bar">
          @for (user of users; track user) {
            <task-dialog-requirement-item
              [display]="user.username"
              [selected]="user._id | isAssignedUserSelected: assignedToFormArray.value"
              [user]="user"
              [buttonWidth]="'250px'"
              type="people"
              (buttonClick)="onUser(user._id)"
            ></task-dialog-requirement-item>
          }
        </div>
        <oph-button class="ok-button" color="green" (buttonClick)="view = 'form'">OK</oph-button>
      </div>
    }
    @if (view === 'form') {
      <div class="row">
        <div class="form-item w-100">
          <label>reward title</label>
          <oph-input-orange
            [control]="nameControl"
            [inputStyle]="inputStyle"
            [showError]="nameControl.touched && nameControl.invalid"
            placeholder="Ex. The Cookie Monster"
          ></oph-input-orange>
        </div>
      </div>

      <div class="row">
        <div class="form-item w-100">
          <label>reward description</label>
          <oph-input-orange
            [control]="descriptionControl"
            [inputStyle]="inputStyle"
            [showError]="descriptionControl.touched && descriptionControl.invalid"
            placeholder="Ex. A Cookie"
          ></oph-input-orange>
        </div>
      </div>

      <div class="row flex-wrap">
        <div class="form-item w-100">
          <label>coin type</label>
          <button
            class="coin-type-button"
            [matMenuTriggerFor]="menu"
            [class.blue-border]="coinTypeMenuOpen"
            [class.error]="coinTypeControl.touched && coinTypeControl.invalid"
            (menuOpened)="coinTypeMenuOpen = true"
            (menuClosed)="onCoinTypeMenuClosed()"
          >
            <div class="coin-type-button-lc">
              <mat-icon
                [svgIcon]="coinTypesMap[coinTypeControl.value]?.name || '' | rewardCoinIcon"
                class="mr-10"
              ></mat-icon>
              <span [class.placeholder]="!coinTypesMap[coinTypeControl.value]?.name">{{
                coinTypesMap[coinTypeControl.value]?.name || 'Choose a Coin Type'
              }}</span>
            </div>
            <mat-icon svgIcon="chevron-down" class="orange"></mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="oph-select-menu">
            @for (coin of coinTypes; track coin) {
              <button class="oph-select-menu-item" (click)="onCoinType(coin)">
                <mat-icon [svgIcon]="coin.name | rewardCoinIcon" class="mr-10"></mat-icon>
                {{ coin.name }}
              </button>
            }
          </mat-menu>
        </div>
        <div class="form-item">
          <label>reward cost</label>
          <oph-input-orange
            [control]="rewardCostControl"
            [inputStyle]="inputStyle"
            [showError]="rewardCostControl.touched && rewardCostControl.invalid"
            [errorMessage]="rewardCostControl.touched && rewardCostControl.invalid && rewardCostError"
            [min]="rewardCostMin"
            [max]="rewardCostMax"
            (valueChange)="onRemoveDecimals($event, rewardCostControl)"
            type="number"
            placeholder="Ex. 100"
          ></oph-input-orange>
        </div>
      </div>

      <div class="row flex-wrap">
        <div class="form-item mw-115">
          <label>expiration date</label>
          <button
            class="date-button"
            (click)="view = 'date'"
            [class.error]="expirationDateControl.touched && expirationDateControl.invalid"
          >
            <img src="assets/img/icons/calendar-brown-2.svg" />
            {{ expirationDateControl.value ? (expirationDateControl.value | moment: 'MM/DD/YYYY') : 'Select Date' }}
          </button>
        </div>
        <div class="form-item w-138">
          <label>reward limit</label>
          <oph-input-orange
            [control]="claimLimitControl"
            [inputStyle]="inputStyle"
            [disabled]="form.get('unlimited').value"
            [showError]="claimLimitControl.touched && claimLimitControl.invalid"
            [errorMessage]="claimLimitControl.touched && claimLimitControl.invalid && rewardLimitError"
            [min]="rewardLimitMin"
            [max]="rewardLimitMax"
            (valueChange)="onRemoveDecimals($event, claimLimitControl)"
            type="number"
          ></oph-input-orange>
        </div>
        <oph-checkbox color="green" [textStyle]="{fontSize: '16px'}" [control]="form.get('unlimited')"
          >Unlimited</oph-checkbox
        >
      </div>

      <div class="row">
        <div class="form-item w-100">
          <label>assigned to</label>
          <div class="assigned-to-container">
            @if (!assignedToFormArray?.length) {
              <button class="add-user-button chip" (click)="view = 'user'">
                Add user
                <div class="icon-container">
                  <img src="assets/img/icons/plus.svg" />
                </div>
              </button>
            } @else {
              @for (user of assignedToFormArray?.value; track user) {
                @if (!user.inactive) {
                  <div class="chip user-chip">
                    <button class="remove-user-button" (click)="onUser(user.userId)">
                      <img class="x-white" src="assets/img/icons/x-white.svg" />
                    </button>
                    <oph-avatar [user]="usersMap[user.userId]" [size]="24" background="#0085ff"></oph-avatar>
                    <span>{{ usersMap[user.userId]?.username }}</span>
                  </div>
                }
              }
              <div class="add-user-plus-button-container">
                <button class="add-user-plus-button" (click)="view = 'user'">
                  <img src="assets/img/icons/plus.svg" />
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    }
  }
</div>

<div class="button-container">
  <oph-button [class.hidden]="view === 'user'" appearance="text" color="brown" (buttonClick)="onClose()"
    >Cancel</oph-button
  >
  <oph-button [class.hidden]="view === 'user'" color="green" (buttonClick)="onSave()" [loading]="loadingSave"
    >Save</oph-button
  >
</div>
