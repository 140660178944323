@for (project of projects || [] | slice: 0 : 3; track project) {
  <div
    class="project"
    [style.background]="project?.color || 'gray'"
    [matTooltip]="projects?.length > 1 ? project?.label : ''"
  ></div>
  @if (projects?.length === 1) {
    <span>{{ project?.label || '(Name unavailable)' }}</span>
  }
}

@if (projects?.length > 3) {
  <div class="project project-count" [matTooltip]="projects | remainingProjectNames: 3">
    {{ projects.length - 3 }}
  </div>
}
