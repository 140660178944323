@if (
  {
    timeLeft: timeLeft$ | async,
    showMoreButtonMap: showMoreButtonMap$ | async
  };
  as state
) {
  @if (taskId$ | async) {
    <current-sked-task-detail notInSked="true" (notInSkedBack)="onTaskBack()"></current-sked-task-detail>
  } @else if (selectedRoutine) {
    <project-view-routine
      [routine]="selectedRoutine"
      [usersMap]="usersMap"
      (back)="onRoutineBack()"
    ></project-view-routine>
  } @else {
    <div class="content-container">
      <div class="content-container-row">
        <!-- -------------------------------------PROGRESS------------------------------------ -->
        <div class="content-item progress">
          <project-view-item-header text="Progress" class="mb-10"></project-view-item-header>
          <project-view-progress [percentComplete]="project?.progress?.percentComplete || 0"></project-view-progress>
          <span class="progress-text">{{ progressText$ | async }} </span>
        </div>

        <!-- -------------------------------------TIME LEFT------------------------------------ -->
        <div class="content-item progress">
          <project-view-item-header text="Time left" class="mb-10"></project-view-item-header>
          <project-view-progress [percentComplete]="state.timeLeft?.percentage"></project-view-progress>
          <span class="progress-text"> {{ state.timeLeft?.display }}</span>
        </div>

        <!-- -------------------------------------MILESTONES------------------------------------ -->
        <div class="content-item wrap-item flex-column milestones">
          <project-view-item-header
            text="Milestones"
            color="gold"
            class="mb-15"
            (buttonClick)="onAddMilestone()"
          ></project-view-item-header>
          <div class="milestones-container oph-scroll-bar">
            @if (project?.milestones?.length) {
              @for (milestone of project.milestones || []; track milestone) {
                <project-view-line-item icon="flag-gold" padding="8px 0">
                  <project-view-milestone [milestone]="milestone"></project-view-milestone>
                </project-view-line-item>
              }
            } @else {
              <div class="empty empty-milestones">No milestones</div>
            }
          </div>
        </div>
      </div>

      <!-- -------------------------------------TOKENS------------------------------------ -->
      <div class="content-item full-width">
        <project-view-item-header
          text="Tokens"
          color="orange-2"
          class="mb-15"
          [tokenFilters]="true"
          (tokenTimeFilterChange)="onTokenTimeFilterChange($event)"
          (tokenTypeFilterChange)="onTokenTypeFilterChange($event)"
        ></project-view-item-header>
        <div class="token-container oph-scroll-bar">
          @if ((displayTokens$ | async)?.length) {
            @for (t of project.tokens; track $index) {
              @if (t?.token?.name && t?.token?.icon) {
                <oph-icon
                  [name]="'tokens/' + t?.token?.icon"
                  size="35"
                  [matTooltip]="
                    t?.token?.name +
                    '\n' +
                    (usersMap?.[t.earnedBy]?.username || '(Name not found)') +
                    '\n' +
                    (t.earnedAt | convertUtcToTimezone: 'MMM D, y h:mm a')
                  "
                  matTooltipClass="white-space-pre-line"
                ></oph-icon>
              }
            }
          } @else {
            <div class="empty">No tokens</div>
          }
        </div>
      </div>

      <!-- -------------------------------------ACTIVITY------------------------------------ -->
      <div class="content-container-row">
        <div class="content-item activity">
          <project-view-item-header text="Activity" color="yellow" class="mb-15"></project-view-item-header>
          <div class="activity-container oph-scroll-bar">
            @if (project.activity?.length) {
              @for (item of project.activity; track item) {
                <project-view-line-item [icon]="item.action | projectViewActivityIcon" padding="7px 0">
                  <project-view-activity-item
                    [activityItem]="item"
                    [user]="usersMap[item.userId]"
                  ></project-view-activity-item>
                </project-view-line-item>
              }
            } @else {
              <div class="empty">No activity</div>
            }
          </div>
        </div>

        <!-- -------------------------------------TRACKERS------------------------------------ -->
        <div class="content-item wrap-item trackers-item">
          <project-view-item-header
            text="Trackers"
            color="orange"
            class="mb-15"
            addNewButton="true"
            [addNewButtonLoading]="addNewButtonLoading$ | async"
            (addNewButtonClick)="onAddTracker()"
          ></project-view-item-header>
          <div class="trackers-container oph-scroll-bar">
            @if (project?.trackers?.length) {
              @for (formattedTracker of (formattedTrackers$ | async) || []; track tracker) {
                <project-view-tracker
                  [tracker]="formattedTracker.tracker"
                  [mostRecentlyUpdatedMetricIndex]="formattedTracker.mostRecentlyUpdatedMetricIndex"
                ></project-view-tracker>
              }
            } @else {
              <div class="empty">No trackers</div>
            }
          </div>
        </div>
      </div>

      <!-- -------------------------------------TASKS------------------------------------ -->
      @for (taskType of taskTypesArray; track taskType) {
        <div class="content-item full-width">
          <project-view-item-header
            [text]="taskType.label"
            [color]="taskType.color"
            [taskCount]="taskType.name === 'routines' ? 0 : project[taskType.name]?.length || 0"
            class="mb-15"
          ></project-view-item-header>
          @if (project[taskType.name]?.length) {
            <!-- If the show more button hasn't been clicked, slice the list to the number of tasks shown (variable) -->
            @for (
              task of project[taskType.name]
                | slice
                  : 0
                  : (state.showMoreButtonMap[taskType.name] ? numberOfTasksShown : project[taskType.name].length);
              track task
            ) {
              @if (taskType.name === 'routines') {
                <project-view-task-routine
                  [routine]="task"
                  (routineClick)="onRoutineClick(task)"
                ></project-view-task-routine>
              } @else {
                <project-view-task
                  [task]="task"
                  [type]="taskType.name"
                  [user]="usersMap[task.ownerId]"
                  (taskClick)="onTaskClick(task, taskType.name)"
                ></project-view-task>
              }
            }
            @if (project[taskType.name]?.length > numberOfTasksShown) {
              <oph-new-button
                (buttonClick)="onToggleShowMore(taskType.name)"
                [showMinus]="!state.showMoreButtonMap[taskType.name]"
                [buttonStyle]="{height: '36px'}"
                >{{ state.showMoreButtonMap[taskType.name] ? 'Show more' : 'Show less' }}</oph-new-button
              >
            }
          } @else {
            <div class="empty">{{ taskType.label | projectViewEmptyMessage }}</div>
          }
        </div>
      }
    </div>
  }
}
