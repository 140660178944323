import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'projects-view-token-filter-dialog',
  standalone: true,
  imports: [],
  templateUrl: './projects-view-token-filter-dialog.component.html',
  styleUrl: './projects-view-token-filter-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsViewTokenFilterDialogComponent {}
