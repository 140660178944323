import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {selectRouterQueryParam} from '../router/router.selector';

export const selectTokensState = (state: AppState) => state.tokens;

export const selectTokensListParams = createSelector(
  selectRouterQueryParam('search'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('sortField'),
  (search, page, sortField) => ({
    search,
    page: page ? Number(page) : 1,
    sortField: sortField ? String(sortField) : '',
  })
);

export const selectTokenData = createSelector(selectTokensState, state => state.tokenData);

export const selectTokens = createSelector(selectTokenData, tokenData => tokenData?.tokens);

export const selectTokensMap = createSelector(
  selectTokens,
  tokens =>
    tokens?.reduce((acc, token) => {
      acc[token._id] = token;
      return acc;
    }, {})
);

export const selectTokenAutocompleteOptions = createSelector(selectTokens, tokens => {
  return tokens?.map(token => ({
    name: token.name,
    _id: token._id,
  }));
});

export const selectSingleToken = createSelector(selectTokensState, state => state.token);
