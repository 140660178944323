import {TOKEN_COLORS, TOKENS} from '../shared/token-constants';

export function getTokenIcons(): string[] {
  const tokens = [];
  TOKEN_COLORS.forEach(color => {
    const arr = [];
    TOKENS.forEach(token => arr.push(`${token}-${color}`));
    tokens.push(arr);
  });
  return tokens;
}
