import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from 'src/app/core/model/project';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {User} from 'src/app/core/model/user';

@Component({
  selector: 'list-view-warning-dialog',
  templateUrl: './list-view-warning-dialog.component.html',
  styleUrls: ['./list-view-warning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewWarningDialogComponent {
  type: 'tracker' | 'metric' | 'user' | 'project';
  tracker?: Tracker;
  metric?: TrackerMetric;
  attributeTypeDisplay?: string;
  user?: User;
  project?: Project;
  customClose?: boolean;
  loading?: boolean;
  callback?: () => void;
  taskCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: 'tracker' | 'metric' | 'user';
      tracker?: Tracker;
      metric?: TrackerMetric;
      user: User;
      project?: Project;
      customClose?: boolean;
      callback?: () => void;
    },
    private dialog: MatDialogRef<ListViewWarningDialogComponent, boolean>
  ) {
    this.type = data.type;
    this.customClose = data.customClose;
    this.callback = data.callback;

    if (data.tracker) {
      this.tracker = data.tracker || ({} as Tracker);
    }

    if (data.metric) {
      this.metric = data.metric || ({} as TrackerMetric);
    }
    if (data.user) {
      this.user = data.user || ({} as User);
    }
    if (data.project) {
      this.project = data.project || ({} as Project);
      this.taskCount = data.project.steps.reduce((acc, step) => acc + step.tasks.length, 0);
    }
  }

  onClose(confirm?: boolean) {
    if (this.customClose && confirm) {
      this.loading = true;
      this.callback?.();
      return;
    }
    this.dialog.close(confirm);
  }
}
