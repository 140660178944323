export const TOKENS = [
  'trophy',
  'burger',
  'star',
  'medal',
  'light-bulb',
  'crown',
  'bolt',
  'heart',
  'thumbs-up',
  'rocket',
];

export const TOKEN_COLORS = ['orange', 'burgundy', 'green', 'purple', 'lime', 'blue', 'gold'];
