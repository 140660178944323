import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Project} from 'src/app/core/model/project';
import {Token} from 'src/app/core/model/token';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {ListViewsProjectDisplayComponent} from 'src/app/shared/list-views/shared/project-display/list-views-project-display.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';

@Component({
  selector: 'tokens-grid-item',
  standalone: true,
  imports: [PipesModule, ListViewsModule, ListViewsProjectDisplayComponent],
  templateUrl: './tokens-grid-item.component.html',
  styleUrl: './tokens-grid-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokensGridItemComponent {
  @Input() token: Token;
  @Input() project: Project;

  @Output() tokenClick = new EventEmitter();
}
