import {AsyncPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, HostListener, inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, take, timer} from 'rxjs';
import {Project} from 'src/app/core/model/project';
import {Token} from 'src/app/core/model/token';
import {GetProjectsAction} from 'src/app/core/store/projects/projects.action';
import {selectAllProjects, selectProjectsMap} from 'src/app/core/store/projects/projects.selector';
import {
  CreateNewTokenAction,
  DeleteTokenAction,
  DuplicateTokenAction,
  UpdateTokenAction,
} from 'src/app/core/store/tokens/tokens.action';
import {GetAllTrackersAction} from 'src/app/core/store/trackers/trackers.action';
import {ProjectSelectorMenuProjectButtonComponent} from 'src/app/projects/project-selector/project-button/project-selector-menu-project-button.component';
import {ProjectSelectorComponent} from 'src/app/projects/project-selector/project-selector.component';
import {MessageService} from 'src/app/services/message.service';
import {
  OphAutocompleteComponent,
  OphAutocompleteOption,
} from 'src/app/shared/design/oph-autocomplete/oph-autocomplete.component';
import {
  OphButtonGroupComponent,
  OphButtonGroupOption,
} from 'src/app/shared/design/oph-button-group/oph-button-group.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphInputOrangeComponent} from 'src/app/shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {OphInputsModule} from 'src/app/shared/design/oph-inputs/oph-inputs.module';
import {OphLabelContentComponent} from 'src/app/shared/design/oph-label-content/oph-label-content.component';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {SharedTasksModule} from 'src/app/shared/tasks/shared-tasks.module';
import {TaskDialogModule} from 'src/app/shared/tasks/task-dialog/task-dialog.module';
import {TaskTriggerSelectionComponent} from 'src/app/shared/tasks/trigger-selection/task-trigger-selection.component';
import * as uuid from 'uuid';
import {getTokenIcons} from '../utils/get-token-icons';
import {completionCountValidator, trackerRequiredIfNotRoutineTask} from './tokens-dialog-form-validators';
import {ProjectsService} from 'src/app/shared/services/projects.service';
import {ProjectTask} from 'src/app/core/model/task';

interface DialogData {
  token: Token;
}

@Component({
  selector: 'tokens-dialog',
  standalone: true,
  imports: [
    OphIconModule,
    OphInputsModule,
    ReactiveFormsModule,
    OphXButtonComponent,
    OphLabelContentComponent,
    ProjectSelectorComponent,
    ProjectSelectorMenuProjectButtonComponent,
    OphButtonGroupComponent,
    AsyncPipe,
    ReactiveFormsModule,
    TaskDialogModule,
    PipesModule,
    SharedTasksModule,
    OphAutocompleteComponent,
    OphInputOrangeComponent,
    OphButtonModule,
    TaskTriggerSelectionComponent,
    ListViewsModule,
  ],
  templateUrl: './tokens-dialog.component.html',
  styleUrl: './tokens-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokensDialogComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.updateColumns();
  }

  projects$: Observable<Project[]> = this.store$.pipe(select(selectAllProjects));
  projectsMap$: Observable<Record<string, Project>> = this.store$.pipe(select(selectProjectsMap));

  manualFormValid$ = new BehaviorSubject<boolean>(false);

  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<TokensDialogComponent>);
  screenWidth: number;
  token: Token;
  view = 'form';
  tokenIconsArray = getTokenIcons();
  iconSize = 35;
  form: FormGroup;
  viewOptions: OphButtonGroupOption[] = [{name: 'routineTask', display: 'routine task'}, {name: 'tracker'}];
  projectTaskOptions: OphAutocompleteOption[] = [];
  selectedTaskName: string;
  addTrackerLoading: boolean;
  loadingSave: boolean;
  // This prevents name input focus from occurring when the icon selector or date picker is opened
  preventNameInputFocus: boolean;
  formSub: Subscription;
  projectTasks: ProjectTask[];

  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private messageService: MessageService,
    private projectsService: ProjectsService
  ) {
    this.screenWidth = window.innerWidth;
    this.updateColumns();
  }

  ngOnInit() {
    this.store$.dispatch(new GetProjectsAction({}));

    this.form = this.getForm(this.data?.token || null);
    if (this.data?.token?.projectId) {
      this.store$.dispatch(
        new GetAllTrackersAction({params: {projectId: this.data?.token?.projectId, pageSize: 1000}})
      );
    }
    // Find selected task name
    if (this.data?.token?.taskId) {
      this.projectsMap$.pipe(take(1)).subscribe(projectsMap => {
        const project = projectsMap[this.data?.token?.projectId];

        this.projectTasks = this.projectsService.getAllTasksInProject(project) || [];
        if (this.projectTasks) {
          this.selectedTaskName =
            this.projectTasks.find(task => task.taskInstanceId === this.data?.token?.taskId)?.label ||
            '(Task name not found)';
          this.projectTaskOptions = this.getProjectTaskOptions(project);
        }
      });
    }

    timer(1000).subscribe(() => (this.preventNameInputFocus = true));

    this.formSub = this.form.valueChanges.subscribe(form => {
      this.checkFormValidation();
    });
    this.checkFormValidation();
  }

  ngOnDestroy() {
    this.formSub.unsubscribe();
  }

  checkFormValidation() {
    let manualValidation: boolean;
    if (this.typeControl?.value === 'routineTask') {
      manualValidation = !!this.taskIdControl.value;
    } else {
      manualValidation = this.getTrackerValid();
    }
    this.manualFormValid$.next(manualValidation && this.form.valid);
  }

  getTrackerValid(): boolean {
    const formTracker = this.form.get('tracker');
    return (
      !!formTracker.get('trackerId')?.value &&
      !!formTracker.get('metricId')?.value &&
      !!formTracker.get('operator')?.value &&
      (!!formTracker.get('value')?.value || formTracker.get('value')?.value === '0')
    );
  }

  getForm(token: Token | null): FormGroup {
    return this.fb.group({
      name: [token?.name || '', Validators.required],
      icon: [token?.icon || 'trophy-gold'],
      projectId: [token?.projectId || '', Validators.required],
      type: [token?.type || 'routineTask'],
      taskId: [token?.taskId || null],
      expirationDate: [token?.expirationDate || '', Validators.required],
      completionCount: [token?.completionCount || 1, completionCountValidator()],
      tracker: this.fb.group(
        {
          trackerId: [token?.tracker?.trackerId || null],
          metricId: [token?.tracker?.metricId || null],
          operator: [token?.tracker?.operator || 'eq'],
          value: [token?.tracker?.value || null],
        },
        {validators: trackerRequiredIfNotRoutineTask()}
      ),
    });
  }

  updateColumns() {
    if (this.screenWidth >= 640) {
      this.iconSize = 35;
    } else if (this.screenWidth >= 470) {
      this.iconSize = 30;
    } else if (this.screenWidth >= 200) {
      this.iconSize = 25;
    } else {
      this.iconSize = 25;
    }
  }

  onTokenNameChange(name: string) {
    this.form.patchValue({name});
  }

  onIcon(icon: string) {
    this.form.patchValue({icon});
    // timer(100).subscribe(() => (this.view = 'form'));
    this.view = 'form';
  }

  onSelectProject(project: Project) {
    this.projectTasks = this.projectsService.getAllTasksInProject(project) || [];
    this.store$.dispatch(new GetAllTrackersAction({params: {projectId: project._id, pageSize: 1000}}));
    this.selectedTaskName = '';
    this.form.patchValue({
      projectId: project._id,
      taskId: '',
      tracker: {
        trackerId: null,
        metricId: null,
        operator: 'eq',
        value: null,
      },
    });
    this.projectTaskOptions = this.getProjectTaskOptions(project);
  }

  getProjectTaskOptions(project: Project) {
    return (
      this.projectsService
        .getAllTasksInProject(project)
        .filter(task => task.schedule.scheduleType === 'repeats')
        .map(task => ({
          name: task.label,
          _id: task.taskInstanceId,
        })) || []
    );
  }

  onTypeChange(type: string) {
    this.form.patchValue({type});
    this.projectsMap$.pipe(take(1)).subscribe(projectsMap => {
      const project = projectsMap[this.form.get('projectId')?.value];
      this.projectTaskOptions = this.getProjectTaskOptions(project);
    });

    this.form.get('taskId')?.updateValueAndValidity();
    this.form.get('completionCount')?.updateValueAndValidity();
    this.form.get('tracker')?.updateValueAndValidity();
  }

  onDateChange() {
    this.form.get('expirationDate').markAsTouched();
    this.view = 'form';
  }

  onTask(option: OphAutocompleteOption) {
    this.selectedTaskName = option.name;
    this.form.patchValue({taskId: option._id});
  }

  onRemoveTask() {
    this.selectedTaskName = '';
    this.form.patchValue({taskId: ''});
  }

  onMenuAction(action: string) {
    if (action === 'duplicate') {
      this.store$.dispatch(
        new DuplicateTokenAction({
          tokenId: this.data.token._id,
          onSuccess: () => this.onSuccess(),
          onFailure: err => this.onFailure(err),
        })
      );
    }

    if (action === 'delete') {
      this.store$.dispatch(
        new DeleteTokenAction({
          tokenId: this.data.token._id,
          onSuccess: () => this.onSuccess(),
          onFailure: err => this.onFailure(err),
        })
      );
    }
  }

  onClearTracker() {
    this.form.patchValue({tracker: {_id: uuid.v4(), trackerId: '', metricId: '', operator: 'eq', value: ''}});
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.loadingSave = true;

    if (this.data?.token) {
      this.updateToken();
    } else {
      this.createToken();
    }
  }

  createToken() {
    const token = this.getTokenFromForm();

    this.store$.dispatch(
      new CreateNewTokenAction({
        token,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onSuccess() {
    this.dialogRef.close(true);
  }

  onFailure(err: HttpErrorResponse) {
    this.loadingSave = false;
    this.messageService.add(err.error || 'There was a problem with the token');
  }

  getTokenFromForm(): Token {
    const token = {...this.data.token, ...this.form.value};

    if (token.type === 'routineTask') {
      // Create a new object excluding the tracker field
      const {tracker, projects, projectsArr, ...remainingTokenValue} = token;
      return remainingTokenValue;
    } else {
      // Create a new object excluding the taskId field
      const {taskId, projects, projectsArr, ...remainingTokenValue} = token;
      return remainingTokenValue;
    }
  }

  updateToken() {
    this.loadingSave = true;
    const token = this.getTokenFromForm();

    this.store$.dispatch(
      new UpdateTokenAction({
        token,
        onSuccess: () => this.onUpdateTokenSuccess(),
        onFailure: err => this.onUpdateTokenFailure(err),
      })
    );
  }

  onUpdateTokenSuccess() {
    this.dialogRef.close(true);
    this.loadingSave = false;
  }

  onUpdateTokenFailure(err: Error) {
    this.loadingSave = false;
    this.messageService.add(err.message || 'There was a problem updating the token');
  }

  get nameControl(): AbstractControl {
    return this.form.get('name');
  }

  get typeControl(): AbstractControl {
    return this.form.get('type');
  }

  get taskIdControl(): AbstractControl {
    return this.form.get('taskId');
  }

  get completionCountContol(): AbstractControl {
    return this.form.get('completionCount');
  }
}
